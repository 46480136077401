






































/* eslint-disable */

import { Component, Vue } from "vue-property-decorator";
import TableComponent from "@/components/Hotel/RoomType/Table.vue"; // @ is an alias to /src
import DialogComponent from "@/components/Hotel/RoomType/Dialog.vue"; // @ is an alias to /src
import PermissionType from "@/constants/PermissionType.ts";
import FilterComponent from "@/components/Hotel/RoomType/Filter.vue";

@Component({
  components: {
    TableComponent,
    DialogComponent,
    FilterComponent
  }
})
export default class RoomType extends Vue {
  private loading: boolean = false;
  private isAddEdit: boolean = false;
  private isDelete: boolean = false;
  private editedRoomType: any = {};
  private optionsTable: any = {
    page: 1,
    itemsPerPage: 5
  };
  private permissions: Array<string> = [];
  private permission: boolean = true;
  private totalRoomType: number = 0;
  private roomTypes: any = [];
  private filter: any = "";
  private headers: Array<object> = [
    { text: "Name", value: "name", align: "start", sortable: false },
    { text: "Size", value: "size", align: "start", sortable: false },
    {
      text: "Max Occupancy",
      value: "maxOccupancy",
      align: "start",
      sortable: false
    },
    { text: "Max Adults", value: "maxAdults", align: "start", sortable: false },
    { text: "Max Child", value: "maxChild", align: "start", sortable: false },
    { text: "Vendor Id", value: "vendorId", align: "start", sortable: false },
    { text: "Source", value: "source", align: "start", sortable: false },
    {
      text: "Smoking Allowed",
      value: "isSmokingAllowed",
      align: "start",
      sortable: false
    },
    { text: "Action", value: "action", sortable: false }
  ];
  private mockRoomType: any = [
    {
      id: "akjsgdajhsgd",
      name: "Room Type 1",
      size: 20,
      maxOccupancy: 3,
      maxAdults: 2,
      maxChild: 2,
      source: "antavaya",
      isSmokingAllowed: true
    },
    {
      id: "dfgdfgdfg",
      name: "Room Type 2",
      size: 15,
      maxOccupancy: 3,
      maxAdults: 2,
      maxChild: 2,
      source: "antavaya",
      isSmokingAllowed: false
    },
    {
      id: "asxzczxcxz",
      name: "Room Type 3",
      size: 10,
      maxOccupancy: 3,
      maxAdults: 2,
      maxChild: 2,
      source: "antavaya",
      isSmokingAllowed: false
    }
  ];

  created() {
    // this.roomTypes = this.mockRoomType
    // this.totalRoomType = 4
    const permissions = this.$store.state["modules/auth"].user["permissions"];
    console.log("permissions: ", permissions);
    this.permissions = [...permissions];
    this.checkCanCreateUpdateDeleteRoomType();
    if (this.canViewRoomType) {
      this.fetchRoomTypeList(this.optionsTable, this.filter);
    }
  }

  public get canViewRoomType() {
    return this.permissions.includes(PermissionType.VIEW_ROOM_TYPE);
  }

  public fetchRoomTypeList(options: any, search: string) {
    this.optionsTable = options;
    this.roomTypes = [];
    this.loading = true;
    console.log("Option => ", options);
    const { sortBy, sortDesc, page, itemsPerPage } = options;

    this.$api.roomType
      .getRoomTypeList(page - 1, itemsPerPage == -1 ? null : itemsPerPage, search)
      .then((res: any) => {
        this.totalRoomType = res.totalItems;
        this.roomTypes = res.data;
        this.loading = false;
        console.log("Success get room type list => ", res);
      })
      .catch((err: any) => {
        console.error("error: ", err);
        this.$toast.error(err.statusMessage.toString());
      });
  }

  public getPagination(page: any) {
    this.optionsTable.page = page;
    this.fetchRoomTypeList(this.optionsTable, this.filter);
  }

  public getItemPerPage(item: any) {
    this.optionsTable.itemsPerPage = item;
    this.fetchRoomTypeList(this.optionsTable, this.filter);
  }

  public fetchCreateRoomType(roomType: any): void {
    this.$api.roomType
      .createRoomType(roomType)
      .then((res: any) => {
        this.$toast.success(res.statusMessage);
        this.isAddEdit = false;
        this.fetchRoomTypeList(this.optionsTable, this.filter);
        console.log("Success Create Room Type: ", res);
      })
      .catch((err: any) => {
        console.error("error: ", err);
        this.$toast.error(err.statusMessage.toString());
      });
  }

  public fetchUpdateRoomType(id: string, roomType: any): void {
    console.log("id", id)
    this.$api.roomType.updateRoomType(id, roomType)
      .then((res: any) => {
        this.$toast.success(res.statusMessage)
        this.isAddEdit = false
        this.fetchRoomTypeList(this.optionsTable, this.filter)
        console.log('Success updated Room Type: ', res)
      })
      .catch((err: any) => {
        console.error('error: ', err)
        this.$toast.error(err.statusMessage.toString())
      })
  }

  public fetchDeleteRoomType(id: string): void {
    console.log("id", id)
    this.$api.roomType.deleteRoomType(id)
      .then((res: any) => {
        this.$toast.success(res.statusMessage)
        this.isAddEdit = false
        this.filter = '';
        this.fetchRoomTypeList(this.optionsTable, this.filter);
        console.log('Success Delete Room Type: ', res)
      })
      .catch((err: any) => {
        console.error('error: ', err)
        this.$toast.error(err.statusMessage.toString())
      })
  }

  public deleteRoomType(item: any) {
    setTimeout(() => {
      this.fetchDeleteRoomType(item.id)
      this.isDelete = false;
    }, 500);
  }

  public detailRoomType(item: any) {
    this.isAddEdit = true;
    this.editedRoomType = item;
  }

  public closeAddEditDialog() {
    this.editedRoomType = {};
    this.isAddEdit = false;
  }

  public saveAddedRoomType(item: any) {
    if (item.id) {
      console.log("update item", item);
      this.fetchUpdateRoomType(item.id, item);
    } else {
      console.log("create item", item);
      this.fetchCreateRoomType(item);
    }
    // setTimeout(() => {
    //   this.closeAddEditDialog();
    // }, 500);
  }

  public checkCanCreateUpdateDeleteRoomType(): void {
    this.permission =
      this.permissions.includes(PermissionType.CREATE_ROOM_TYPE) &&
      this.permissions.includes(PermissionType.UPDATE_ROOM_TYPE) &&
      this.permissions.includes(PermissionType.DELETE_ROOM_TYPE);
  }

  public applyFilter(filter: object): void {
    // call api from backend to apply filter
    this.filter = filter;
    console.log(this.filter);
    this.fetchRoomTypeList(this.optionsTable, this.filter);
  }
}
