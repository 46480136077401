import axios from "axios"
import url from './Url'

/**
 * Get Promo Banner / Landing Page Promo List
 * @param pageNumber number
 * @param pageSize number
 * @param productType string
 * @param title string
 */
export const getPromoBannerList = async (pageNumber: number, pageSize: number, productType: string, title?: string) => {
  const qs = {
    pageNumber,
    pageSize,
    productType,
    title
  }

  try {
    const result = await axios.get(url.promoBanner.GET_PROMO_BANNER_LIST, { params: qs})
    return result.data
  } catch (error) {
    return Promise.reject(error)
  }
}

/**
 * Get Promo Banner / Landing Page Promo Detail
 * @param promoBannerId string
 */
export const getPromoBannerDetail = async (promoBannerId: string) => {
  const qs = {
    id: promoBannerId
  }

  try {
    const result = await axios.get(url.promoBanner.GET_PROMO_BANNER_DETAIL, { params: qs })
    return result.data
  } catch (error) {
    return Promise.reject(error)
  } 
}

/**
 * Create Promo Banner / Landing Page Promo
 * @param request object
 */
export const createPromoBanner = async (request: object) => {

  try {
    const result = await axios.post(url.promoBanner.POST_PROMO_BANNER_CREATE, request)
    return result.data
  } catch (error) {
    return Promise.reject(error)
  }
}

/**
 * Update Promo Banner / Landing Page Promo
 * @param promoBannerId string
 * @param request object
 */
export const updatePromoBanner = async (promoBannerId: string, request: object) => {
  const qs = {
    id: promoBannerId
  }

  try {
    const result = await axios.put(url.promoBanner.PUT_PROMO_BANNER_UPDATE, request, { params: qs })
    return result.data
  } catch (error) {
    return Promise.reject(error)
  }
}

/**
 * Delete Promo Banner / Landing Page Promo
 * @param promoBannerId string
 */
export const deletePromoBanner = async (promoBannerId: string) => {
  const qs = {
    id: promoBannerId
  }
  
  try {
    const result = await axios.put(url.promoBanner.DELETE_PROMO_BANNER, {}, { params: qs })
    return result.data
  } catch (error) {
    return Promise.reject(error)
  }
}