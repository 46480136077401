



























































































/* eslint-disable */

import { Component, Vue, Watch } from "vue-property-decorator";
import FilterComponent from "@/components/Promo/Filter.vue"; // @ is an alias to /src
import TableComponent from "@/components/Promo/Table.vue"; // @ is an alias to /src
import PermissionType from "@/constants/PermissionType.ts";

@Component({
  components: {
    FilterComponent,
    TableComponent
  }
})

export default class Promo extends Vue {
  private itemsBreadcrumbs: Array<object> = [
    {
      text: "Home",
      disabled: false,
      href: "/",
    },
    {
      text: "Voucher Management",
      disabled: true,
    },
  ];
  private filter = {
    hotel: "",
    tour: "",
    flight: ""
  };
  private tab = 0;
  private optionsTable: any = {
    page: 1,
    itemsPerPage: 10,
  };
  private fixOptionsTable: any = {
    page: 1,
    itemsPerPage: 10,
  };
  private isDetail: any = {
    flight: false,
    hotel: false,
    tour: false,
  }
  private isLoading = false
  private totalPromos = 0
  private promos: Array<object> = []
  private permissions: Array<string> = []
  private permission: boolean = true
  private dataFlight = [
    {
      "promoId": 1,
      "promoCode": "PKTLBRNF1",
      "promoName": "Paket Lebaran",
      "promoDescription": "Lebaran seru .....",
      "startDate": "2020-10-10",
      "endDate": "2020-11-10",
      "quota": 100,
      "usageCount": 1,
      "productType": "flight"
    },
    {
      "promoId": 2,
      "promoCode": "PKTLBRNF2",
      "promoName": "Paket Lebaran",
      "promoDescription": "Lebaran seru .....",
      "startDate": "2020-10-10",
      "endDate": "2020-11-10",
      "quota": 100,
      "usageCount": 1,
      "productType": "flight"
    },
    {
      "promoId": 3,
      "promoCode": "PKTLBRNF3",
      "promoName": "Paket Lebaran",
      "promoDescription": "Lebaran seru .....",
      "startDate": "2020-10-10",
      "endDate": "2020-11-10",
      "quota": 100,
      "usageCount": 1,
      "productType": "flight"
    }
  ]

  private dataHotel = [
    {
      "promoId": 4,
      "promoCode": "PKTLBRNH1",
      "promoName": "Paket Lebaran",
      "promoDescription": "Lebaran seru .....",
      "startDate": "2020-10-10",
      "endDate": "2020-11-10",
      "quota": 100,
      "usageCount": 1,
      "productType": "hotel"
    },
    {
      "promoId": 5,
      "promoCode": "PKTLBRNH2",
      "promoName": "Paket Lebaran",
      "promoDescription": "Lebaran seru .....",
      "startDate": "2020-10-10",
      "endDate": "2020-11-10",
      "quota": 100,
      "usageCount": 1,
      "productType": "hotel"
    },
    {
      "promoId": 6,
      "promoCode": "PKTLBRNH3",
      "promoName": "Paket Lebaran",
      "promoDescription": "Lebaran seru .....",
      "startDate": "2020-10-10",
      "endDate": "2020-11-10",
      "quota": 100,
      "usageCount": 1,
      "productType": "hotel" 
    }
  ]

  private dataTour = [
    {
      "promoId": 7,
      "promoCode": "PKTLBRNT1",
      "promoName": "Paket Lebaran",
      "promoDescription": "Lebaran seru .....",
      "startDate": "2020-10-10",
      "endDate": "2020-11-10",
      "quota": 100,
      "usageCount": 1,
      "productType": "tour"
    },
    {
      "promoId": 8,
      "promoCode": "PKTLBRNT2",
      "promoName": "Paket Lebaran",
      "promoDescription": "Lebaran seru .....",
      "startDate": "2020-10-10",
      "endDate": "2020-11-10",
      "quota": 100,
      "usageCount": 1,
      "productType": "tour"
    },
    {
      "promoId": 9,
      "promoCode": "PKTLBRNT3",
      "promoName": "Paket Lebaran",
      "promoDescription": "Lebaran seru .....",
      "startDate": "2020-10-10",
      "endDate": "2020-11-10",
      "quota": 100,
      "usageCount": 1,
      "productType": "tour"
    }
  ]

  created() {
    // this.fetchPromoList(this.optionsTable, this.tab)
    const permissions = this.$store.state['modules/auth'].user['permissions']
    // console.log('permissions: ', permissions)
    this.permissions = [...permissions]
    this.checkCanCreateUpdateDeletePromo()
  }

  public get canViewPromo() {
    return this.permissions.includes(PermissionType.VIEW_PROMOTION_CODE)
  }

  public fetchPromoList(options: any, tab: number) {
    this.optionsTable = options
    this.promos = []
    this.isLoading = true
    const { sortBy, sortDesc, page, itemsPerPage } = options;
    let items: Array<object> = [];
    let filter= ""
    console.log('tab fetch', tab)

    if (tab === 0) {
      filter = this.filter.flight
      items = this.dataFlight
    } else if (tab === 1) {
      filter = this.filter.hotel
      items = this.dataHotel
    } else {
      filter = this.filter.tour
      items = this.dataTour
    }

    this.$api.promo.getPromoList(page - 1, itemsPerPage == -1 ? null : itemsPerPage, filter, this.$strings.common.getTextProductType(this.tab).toLowerCase())
      .then((res: any) => {
        this.promos = res.data
        this.totalPromos = res.totalItems
        this.isLoading = false
        this.isDetail[this.$strings.common.getTextProductType(this.tab).toLowerCase()] = false
        console.log('Success Get Promo List: ', res)
      })
      .catch((err: any) => {
        console.error('error: ', err)
        this.$toast.error(err.statusMessage.toString())
      })

    // this.$toast.success('Get Promo List Success!')
  }

  public fetchCreatePromo(item: any): void {
    //fetch
    console.log('create', item)
    item.productType = this.$strings.common.getTextProductType(this.tab).toLowerCase()
    item.type = item.type.toLowerCase()

    if(item.type === 'percentage') {
      item.amount = null
    }
    else {
      item.maximumDiscount = null
      item.percentageDiscount = null
    }

    this.$api.promo.createPromo(item)
      .then((res: any) => {
        this.$toast.success('Voucher Discount Created Successfully')
        console.log('Success Create Promo: ', res)
        this.fetchPromoList(this.optionsTable, this.tab)
      })
      .catch((err: any) => {
        console.error('error: ', err)
        this.$toast.error(err.statusMessage.toString())
      })
  }

  public fetchUpdatePromo(item: any): void {
    //fetch
    const promoId = item.id
    delete item.id
    delete item.promoCode
    delete item.usageCount

    this.$api.promo.updatePromo(promoId, item)
      .then((res: any) => {
        this.$toast.success(res.statusMessage)
        console.log('Success Update Promo: ', res)
        this.fetchPromoList(this.optionsTable, this.tab)
      })
      .catch((err: any) => {
        console.error('error: ', err)
        this.$toast.error(err.statusMessage.toString())
      })
  }

  public fetchDeletePromo(item: string): void {
    this.$api.promo.deletePromo(item)
      .then((res: any) => {
        this.$toast.success(res.statusMessage)
        console.log('Success Delete Promo: ', res)
        this.fetchPromoList(this.optionsTable, this.tab)
      })
      .catch((err: any) => {
        console.error('error: ', err)
        this.$toast.error(err.statusMessage.toString())
      })
  }

  public applyFilter(filter: any): void {
    if (this.tab === 0) {
      this.filter.flight = filter
    } else if (this.tab === 1) {
      this.filter.hotel = filter
    } else {
      this.filter.tour = filter
    }
    this.fetchPromoList(this.fixOptionsTable, this.tab)
    console.log('parent', this.filter)
  }

  public changeTable(options: any): void {
    this.fetchPromoList(options, this.tab)
  }

  public createUpdatePromo(item: any): void {
    if (item.id) {
      this.fetchUpdatePromo(item)
    } else {
      this.fetchCreatePromo(item)
    }
  }

  public checkCanCreateUpdateDeletePromo(): void {
    this.permission = this.permissions.includes(PermissionType.CREATE_PROMOTION_CODE) && this.permissions.includes(PermissionType.UPDATE_PROMOTION_CODE) && this.permissions.includes(PermissionType.DELETE_PROMOTION_CODE)
  }

  @Watch('tab')
  tabChanged() {
    this.fetchPromoList(this.fixOptionsTable, this.tab)
  }
}
