





























































/* eslint-disable */
import { Component, Vue, Watch } from "vue-property-decorator";
import FilterComponent from "@/components/LandingPagePromo/Filter.vue"; // @ is an alias to /src
import TableComponent from "@/components/LandingPagePromo/Table.vue";
import DialogComponent from "@/components/LandingPagePromo/Dialog.vue";
import PermissionType from "@/constants/PermissionType.ts";

@Component({
  components: {
    FilterComponent,
    TableComponent,
    DialogComponent,
  },
})

// @Component
export default class Promo extends Vue {
  private promos: Array<object> = [];
  private totalPromo = 0;
  private optionsTable: any = {
    page: 1,
    itemsPerPage: 5,
  };
  private filter: any = {
    flight: null,
    hotel: null,
    tour: null,
  }
  private loadingTable = false;
  private isCreate = false;
  private isCreatePwa = false;
  private typeCreate = '';
  private isEdit = false;
  private editPromo = {};
  private promoCode: Array<object> = [];
  private fixOptionsTable: any = {
    page: 1,
    itemsPerPage: 5,
  };
  private tab = 0;
  private itemsBreadcrumbs: Array<object> = [
    {
      text: "Home",
      disabled: false,
      href: "/",
    },
    {
      text: "Banner",
      disabled: true,
    },
  ];
  private permissions: Array<string> = [];
  private permission: boolean = true

  created() {
    // this.fetchAllPromoList(this.optionsTable, this.tab);
    const permissions = this.$store.state['modules/auth'].user['permissions']
    // console.log('permissions: ', permissions)
    this.permissions = [...permissions]
    this.checkCanCreateUpdateDeletePromo()
    this.fetchAllPromoCode()
  }

  public get canViewPromoBanner() {
    return this.permissions.includes(PermissionType.VIEW_LANDING_PAGE_PROMO)
  }

  public checkCanCreateUpdateDeletePromo(): void {
    // this.permission = true
    this.permission = this.permissions.includes(PermissionType.CREATE_LANDING_PAGE_PROMO) && this.permissions.includes(PermissionType.UPDATE_LANDING_PAGE_PROMO) && this.permissions.includes(PermissionType.DELETE_LANDING_PAGE_PROMO)
  }

  public getData(value: string): Array<object> {
    if (value === "flight") { 
      return [
        {
          id: "1",
          title: "Promo 1",
          content: "promo content",
          imageUrl: "www.google.com",
          startDate: "2020-11-10",
          endDate: "2020-11-10",
          productType: "Flight",
          promoCode: "PKTLBRNF1",
        },
        {
          id: "2",
          title: "Promo 2",
          content: "promo content",
          imageUrl: "www.facebook.com",
          startDate: "2020-11-10",
          endDate: "2020-11-10",
          productType: "Flight",
          promoCode: "PKTLBRNF2",
        },
        {
          id: "3",
          title: "Promo 3",
          content: "promo content",
          imageUrl: "www.google.com",
          startDate: "2020-11-10",
          endDate: "2020-11-10",
          productType: "Flight",
          promoCode: "PKTLBRNF3",
        },
      ];
    } else if (value === "hotel") { 
      return [
        {
          id: "4",
          title: "Promo 1",
          content: "promo content",
          imageUrl: "www.google.com",
          startDate: "2020-11-10",
          endDate: "2020-11-10",
          productType: "Hotel",
          promoCode: "PKTLBRNH1",
        },
        {
          id: "5",
          title: "Promo 2",
          content: "promo content",
          imageUrl: "www.facebook.com",
          startDate: "2020-11-10",
          endDate: "2020-11-10",
          productType: "Hotel",
          promoCode: "PKTLBRNH2",
        },
        {
          id: "6",
          title: "Promo 3",
          content: "promo content",
          imageUrl: "www.google.com",
          startDate: "2020-11-10",
          endDate: "2020-11-10",
          productType: "Hotel",
          promoCode: "PKTLBRNH3",
        },
      ];
    } else {
            return [
        {
          id: "7",
          title: "Promo 1",
          content: "promo content",
          imageUrl: "www.google.com",
          startDate: "2020-11-10",
          endDate: "2020-11-10",
          productType: "Tour",
          promoCode: "PKTLBRNT1",
        },
        {
          id: "8",
          title: "Promo 2",
          content: "promo content",
          imageUrl: "www.facebook.com",
          startDate: "2020-11-10",
          endDate: "2020-11-10",
          productType: "Tour",
          promoCode: "PKTLBRNT2",
        },
        {
          id: "9",
          title: "Promo 3",
          content: "promo content",
          imageUrl: "www.google.com",
          startDate: "2020-11-10",
          endDate: "2020-11-10",
          productType: "Tour",
          promoCode: "PKTLBRNT3",
        },
      ];
    }
  }

  public getPromoCode(value: string): Array<object> {
    if (value === "flight") {
      return [
        {
          "promoId": 1,
          "promoCode": "PKTLBRNF1",
          "promoName": "Paket Lebaran",
          "promoDescription": "Lebaran seru .....",
          "startDate": "2020-10-10",
          "endDate": "2020-11-10",
          "quota": 100,
          "usageCount": 1,
          "productType": "flight"
        },
        {
          "promoId": 2,
          "promoCode": "PKTLBRNF2",
          "promoName": "Paket Lebaran",
          "promoDescription": "Lebaran seru .....",
          "startDate": "2020-10-10",
          "endDate": "2020-11-10",
          "quota": 100,
          "usageCount": 1,
          "productType": "flight"
        },
        {
          "promoId": 3,
          "promoCode": "PKTLBRNF3",
          "promoName": "Paket Lebaran",
          "promoDescription": "Lebaran seru .....",
          "startDate": "2020-10-10",
          "endDate": "2020-11-10",
          "quota": 100,
          "usageCount": 1,
          "productType": "flight"
        }
      ]
    } else if (value === "hotel") {
      return [
        {
          "promoId": 4,
          "promoCode": "PKTLBRNH1",
          "promoName": "Paket Lebaran",
          "promoDescription": "Lebaran seru .....",
          "startDate": "2020-10-10",
          "endDate": "2020-11-10",
          "quota": 100,
          "usageCount": 1,
          "productType": "hotel"
        },
        {
          "promoId": 5,
          "promoCode": "PKTLBRNH2",
          "promoName": "Paket Lebaran",
          "promoDescription": "Lebaran seru .....",
          "startDate": "2020-10-10",
          "endDate": "2020-11-10",
          "quota": 100,
          "usageCount": 1,
          "productType": "hotel"
        },
        {
          "promoId": 6,
          "promoCode": "PKTLBRNH3",
          "promoName": "Paket Lebaran",
          "promoDescription": "Lebaran seru .....",
          "startDate": "2020-10-10",
          "endDate": "2020-11-10",
          "quota": 100,
          "usageCount": 1,
          "productType": "hotel" 
        }
      ]
    } else {
      return [
        {
          "promoId": 7,
          "promoCode": "PKTLBRNT1",
          "promoName": "Paket Lebaran",
          "promoDescription": "Lebaran seru .....",
          "startDate": "2020-10-10",
          "endDate": "2020-11-10",
          "quota": 100,
          "usageCount": 1,
          "productType": "tour"
        },
        {
          "promoId": 8,
          "promoCode": "PKTLBRNT2",
          "promoName": "Paket Lebaran",
          "promoDescription": "Lebaran seru .....",
          "startDate": "2020-10-10",
          "endDate": "2020-11-10",
          "quota": 100,
          "usageCount": 1,
          "productType": "tour"
        },
        {
          "promoId": 9,
          "promoCode": "PKTLBRNT3",
          "promoName": "Paket Lebaran",
          "promoDescription": "Lebaran seru .....",
          "startDate": "2020-10-10",
          "endDate": "2020-11-10",
          "quota": 100,
          "usageCount": 1,
          "productType": "tour"
        }
      ]
    }
  }

  public fetchAllPromoCode(): void {

    // later change with productType
    this.$api.promo.getPromoListAsc(0, 1000, '', this.$strings.common.getTextProductType(this.tab).toLowerCase())
      .then((res: any) => {
        this.promoCode = res.data
        console.log('Success Get Promo List => ', res)
      })
      .catch((err: any) => {
        console.error('error: ', err)
        this.$toast.error(err.statusMessage.toString())
      })
  }

  public fetchAllPromoList(options: any): void {
    this.optionsTable = options
    this.loadingTable = true
    this.promos = []
    const { sortBy, sortDesc, page, itemsPerPage } = options;
    let items: Array<object> = [];
    let filter= ""

    console.log('tab fetch', this.tab)
    if (this.tab === 0) {
      filter = this.filter.flight ? this.filter.flight : null
    } else if (this.tab === 1) {
      filter = this.filter.hotel ? this.filter.hotel : null
    } else {
      filter = this.filter.tour ? this.filter.tour : null
    }

    this.$api.promoBanner.getPromoBannerList(page - 1, itemsPerPage, this.$strings.common.getTextProductType(this.tab).toLowerCase(), filter)
      .then((res: any) => {
        this.promos = res.data
        this.totalPromo = res.totalItems
        this.loadingTable = false
        console.log('Success Get Promo Banner => ', res)
      })
      .catch((err: any) => {
        console.error('error: ', err)
        this.$toast.error(err.statusMessage.toString())
      })

    // this.$toast.success('Get Promo Banner List Success!')
  }

  public fetchUpdatePromo(promo: any): void {
    console.log('promo', promo)
    promo.imgUrl = promo.imageUrl

    this.$api.promoBanner.updatePromoBanner(promo.id, promo)
      .then((res: any) => {
        this.$toast.success(res.statusMessage)
        this.isEdit = false
        this.fetchAllPromoList(this.optionsTable)
        console.log('Success Change Promo Banner => ', res)
      })
      .catch((err: any) => {
        console.error('error: ', err)
        this.$toast.error(err.statusMessage.toString())
      })
  }

  public fetchCreatePromo(promo: any, type: any): void {
    if (type === 'pwa') {
      promo['isPwa'] = true;
      promo.productType = this.$strings.common.getTextProductType(this.tab).toLowerCase()

      this.$api.promoBanner.createPromoBanner(promo)
        .then((res: any) => {
          this.$toast.success(res.statusMessage)
          this.isCreatePwa = false
          this.fetchAllPromoList(this.optionsTable)
          console.log('Success Create Promo: ', res)
        })
        .catch((err: any) => {
          console.error('error: ', err)
          this.$toast.error(err.statusMessage.toString())
        })
    } else {
      promo.productType = this.$strings.common.getTextProductType(this.tab).toLowerCase()

      this.$api.promoBanner.createPromoBanner(promo)
        .then((res: any) => {
          this.$toast.success(res.statusMessage)
          this.isCreate = false
          this.fetchAllPromoList(this.optionsTable)
          console.log('Success Create Promo: ', res)
        })
        .catch((err: any) => {
          console.error('error: ', err)
          this.$toast.error(err.statusMessage.toString())
        })
    }
  }

  public fetchDeletePromo(item: string): void {
    this.$api.promoBanner.deletePromoBanner(item)
      .then((res: any) => {
        this.$toast.success(res.statusMessage)
        console.log('Success Delete Promo: ', res)
        this.fetchAllPromoList(this.optionsTable)
      })
      .catch((err: any) => {
        console.error('error: ', err)
        this.$toast.error(err.statusMessage.toString())
      })
  }

  public cancelAction(action: any): void {
    if (action.isCancelCreate) {
      this.isCreate = false;
    } else if (action.isCancelCreatePwa) {
      this.isCreatePwa = false;
    } else {
      this.isEdit = false;
    }
  }

  public actionPromo(value: string): void {
    if (value === "create") {
      this.isCreate = true;
      this.typeCreate = 'mobile';
    } else if (value === "createForPwa") {
      this.isCreatePwa = true;
      this.typeCreate = 'pwa';
    } else {
      this.editPromo = value;
      this.isEdit = true;
    }
  }

  public applyFilter(filter: object): void {
    if (this.tab === 0) {
      this.filter.flight = filter
    } else if (this.tab === 1) {
      this.filter.hotel = filter
    } else {
      this.filter.tour = filter
    }
    // call api from backend to apply filter
    this.fetchAllPromoList(this.optionsTable)
  }

  public changeTable(options: any): void {
    if (this.loadingTable === false) {
      this.fetchAllPromoList(options)
    }
  }

  public savePromo(action: string, promo: any): void {
    if (action === "create") {
      //call api create Promo
      this.fetchCreatePromo(promo, 'mobile')
    } else if (action === "createPwa") {
      //call api create Promo
      this.fetchCreatePromo(promo, 'pwa')
    } else {
      //call api edit promo
      //call fetch list api with filter
      this.fetchUpdatePromo(promo)
    }
  }

  @Watch('tab')
  tabChanged() {
    this.fetchAllPromoCode()
    this.fetchAllPromoList(this.fixOptionsTable)
  }
}
