export const toCurrency = (value: number) => {
  if (typeof value !== "number") {
    return value;
  }
  const formatter = new Intl.NumberFormat("id-ID", {
    style: "currency",
    currency: "IDR",
    minimumFractionDigits: 0
  });
  return formatter.format(value);
};