import axios from "axios"
import url from './Url'

/**
 * Get Customer List
 * @param pageNumber number
 * @param pageSize number
 * @param search string
 */
export const getCustomerList = async (pageNumber: number, pageSize: number, name?: string, email?: string) => {
    const qs = {
        pageNumber,
        pageSize, 
        name,
        email       
    }
    
    try {
        const result = await axios.get(url.customer.GET_CUSTOMER_LIST, { params: qs })
        return result.data
    } catch(err) {
        return err
    }

}

/**
 * Update Customer
 * @param id string
 * @param request object
 */
export const updateCustomer = async (id: string, request: object) => {
    const qs = {
        id
    }
    
    try {
        const result = await axios.put(url.customer.UPDATE_CUSTOMER, request, { params: qs })
        return result.data
    } catch(err) {
        return err
    }
}