




















/* eslint-disable */
import { Component, Emit, Prop, Vue, Watch } from "vue-property-decorator";

@Component
export default class AutoCompleteRoomType extends Vue {
  @Prop({ required: true }) readonly hotelProp!: any;
  @Prop({ required: true }) readonly isEdit!: any;

  private roomType: any = {};
  private roomTypeOptions: any = [];
  private hotel: any = {};

  private pageSizeRoomType: number = 5;
  private pageNumberRoomType = 0;
  private pageTotalRoomType = 0;
  private searchRoomType: string = "";

  created() {
    this.hotel = { ...this.hotelProp };
    // this.roomType = this.hotelRoom.roomType.name
    // if(this.hotelProp.roomTypeName)
    // {
    //   this.roomTypeOptions = [this.hotel.roomTypeName];
    // }
    // else
    // {
      // this.fetchRoomType();
    // }
  }

  @Watch("hotelProp")
  roomTypePropChanged(newVal: any) {
    this.hotel = { ...this.hotelProp };
    this.fetchRoomType();
  }

  @Watch("hotel.roomTypeName")
  chooseFromAutoComplete(newVal: any) {
    this.hotel.roomTypeId = newVal.id;
    this.emitData();
  }

  @Watch("searchRoomType")
  hotelSearchChanged(newVal: string) {
    if (newVal) {
      this.pageNumberRoomType = 0;
      this.fetchRoomType();
    }
  }

  @Emit("get-room-type-data")
  private emitData() {
    return this.hotel;
  }

  private inputRoomType ()
  {
    if(this.hotel.roomTypeName !== undefined) {
      this.hotel.roomTypeId = this.hotel.roomTypeId.id;
    }
    this.emitData();
  }

  fetchRoomType() {
    this.$api.discount
      .getRoomType(this.hotel.hotelId)
      .then((res: any) => {
        this.roomTypeOptions = res.data;
      })
      .catch((err: any) => {
        console.error("error: ", err);
        this.$toast.error(err.statusMessage.toString());
      });
  }

  hasPrevPageRoomType() {
    if (this.pageNumberRoomType - 1 >= 0) {
      this.pageNumberRoomType -= 1;
      this.fetchRoomType();
    }
  }

  hasNextPageRoomType() {
    if (this.pageNumberRoomType + 1 < this.pageNumberRoomType) {
      this.pageNumberRoomType += 1;
      this.fetchRoomType();
    }
  }

}
