






























































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































/* eslint-disable */

import { Component, Emit, Prop, Vue, Watch } from "vue-property-decorator";
import AreaAroundHotelFormComponent from "./Hotel/AreaAroundHotel.vue";
import ChildPolicyFormComponent from "./Hotel/ChildPolicy.vue";

@Component({
  components: {
    // TagHotelImageFormComponent
    AreaAroundHotelFormComponent,
    ChildPolicyFormComponent,
  },
})
export default class FormComponent extends Vue {
  @Prop({ required: true }) readonly isCreate!: boolean;
  // @Prop({ required: true }) readonly editedItem!: any;
  @Prop({ required: true }) readonly featureList!: Array<any>;
  @Prop({ required: true }) readonly tagHotelList!: Array<any>;
  @Prop({ required: true }) readonly tagImageList!: Array<any>;
  @Prop({ required: true }) readonly featureListRoom!: Array<any>;
  @Prop({ required: true }) roomTypeList!: Array<any>;
  @Prop({ required: true }) mealPlanList!: Array<any>;

  private step: string = "1";
  private imageData: string = "";
  private addAroundHotel: boolean = false;
  private fixHotel: any = {};
  private overlay: boolean = false;
  private loading: boolean = false;
  private roomTypeSearch: string = "";
  private mealPlanSearch: string = "";
  private debounce: any = null;
  private debounceMealPlan: any = null;
  private page: any = 0;
  private hotel: any = {
    name: "",
    address: "",
    city: "",
    headline: "",
    description: "",
    star: "",
    phone: "",
    longitude: "",
    latitude: "",
    sourceHotel: "antavaya",
    isRecommended: false,
    isBestBuy: false,
    isSuperDeal: false,
    country: "",
    tagHotelImage: [
      {
        tagId: "",
        tagName: "",
        hotelImage: [
          {
            url: "",
            urlImageShow: "",
            urlImage: null,
            description: "",
            isMain: false,
          },
        ],
      },
    ],
    tagHotel: [],
    tagHotelName: [],
    feature: [],
    featureName: [],
    areaAroundHotel: [
      {
        placeName: "",
        distanceInMeters: "",
        description: "",
      },
    ],
    childPolicy: [
      {
        minAge: "",
        maxAge: "",
      },
    ],
    notes: "",
    roomTypes: [
      {
        roomTypeId: "",
        roomTypeName: "",
        typeName: "",
        feature: [],
        featureName: [],
        hotelAvabilityRoom: [
          {
            startDate: "",
            endDate: "",
            totalRoomAvailable: "",
          },
        ],
        roomPrice: [
          {
            price: "",
            mealPlanListId: "",
            mealPlanName: "",
            packageRate: false,
            availFlag: false,
            canAmend: false,
            cancelationPolicyType: "",
            canHold: false,
            cancelationPolicy: [
              {
                fromDate: "",
                toDate: "",
                amount: "",
                b2bMarkup: "",
                grossAmount: "",
                night: "",
                percent: "",
                noShow: false,
              },
            ],
          },
        ],
        // rooms: [
        //   {
        //     beds: "",
        //     extrabeds: "",
        //     notes: "",
        //     isSuperDeal: false,
        //     isBestBuy: false,
        //     meals: [
        //       {
        //         price: "",
        //         name: "",
        //         priceNotes: ""
        //       }
        //     ],
        //     cancelationPolicies: [
        //       {
        //         deadlineInHours: "",
        //         percentage: "",
        //         description: ""
        //       }
        //     ],
        //     paymentMethod: {
        //       name: ""
        //     }
        //   }
        // ]
      },
    ],
  };
  private mockTag = [{ tagName: "Clean" }, { tagName: "Super" }];
  private mockRoomTypes = [
    { typeName: "Single Suit" },
    { typeName: "Double Suit" },
  ];
  private activePanel: any = null;
  private activeSubPanel: any = null;

  @Watch("isCreate")
  isCreateChanged(newVal: any) {
    console.log("New Val => ", newVal);
    if (!newVal) {
      this.hotel = { ...this.fixHotel };
      this.step = "1";
    } else {
      // if (this.editedItem) {
      //   console.log("nnn");
      //   this.hotel = { ...this.editedItem };
      // }
    }
  }

  created() {
    // this.fixHotel = {...this.hotel}
    Object.assign(this.fixHotel, this.hotel);
  }

  hasPrevPageLocation () {
    if (this.page >= 1) {
      this.page -= 1;
      this.fetchRoomTypes(this.roomTypeSearch);
    }
  }

  hasNextPageLocation () {
    this.page += 1;
    this.fetchRoomTypes(this.roomTypeSearch);
  }

  private nextStep() {
    if (
      (this.$refs.formHotel as Vue & { validate: () => boolean }).validate()
    ) {
      this.step = "2";
    }
  }

  private nextStep2() {
    if (this.activePanel === null && this.activeSubPanel === null) {
      this.activePanel = 0;
      this.activeSubPanel = 0;
    } else {
      if ((this.$refs.formRoom as Vue & { validate: () => boolean }).validate()) {
        this.step = "3";
      }
    }
  }

  private setTagHotel(value: any[]) {
    console.log("tagHotel", value);
    this.hotel.tagHotelName = [];
    value.forEach((item) => {
      let name = this.tagHotelList.find((x) => x.value === item);
      this.hotel.tagHotelName.push(name.text);
    });
    console.log("tagHotel Name", this.hotel.tagHotelName);
  }

  private setTagHotelImage(value: any, index: string) {
    let name = this.tagImageList.find((item) => item.value === value);
    this.hotel.tagHotelImage[index].tagName = name.text;
  }

  private setFeature(value: any[]) {
    console.log("feature", value);
    this.hotel.featureName = [];
    value.forEach((item) => {
      let name = this.featureList.find((x) => x.value === item);
      this.hotel.featureName.push(name.text);
    });
    console.log("tagHotel Name", this.hotel.featureName);
  }

  private addAreaAroundHotel() {
    this.hotel.areaAroundHotel.push({
      placeName: "",
      distanceInMeters: "",
      description: "",
    });
  }

  private deleteAreaAroundHotel(index: number) {
    console.log(index);
    this.hotel.areaAroundHotel.splice(index, 1);
  }

  private addChildPolicy() {
    this.hotel.childPolicy.push({
      minAge: "",
      maxAge: "",
    });
  }

  private uploadImage(index: any, indexImg: any, image: any) {
    console.log("image", image);
    this.$api.image
      .uploadImage(image)
      .then((res: any) => {
        this.overlay = false;
        this.$toast.success("Success Upload Image");
        console.log("Success Upload Image: ", res);
        this.hotel.tagHotelImage[index].hotelImage[indexImg].url = res.url;
        this.hotel.tagHotelImage[index].hotelImage[indexImg].urlImageShow =
          res.url;
      })
      .catch((err: any) => {
        console.error("error: ", err);
        this.overlay = false;
        this.$toast.error(err.statusMessage.toString());
      });
  }

  private deleteChildPolicy(index: number) {
    console.log(index);
    this.hotel.childPolicy.splice(index, 1);
  }

  // private hotelTagImageChanged(item: any) {
  //   if (!this.hotel.tagHotelImage[item].tagName) {
  //     console.log(this.hotel.tagHotelImage[item].tagName);
  //   } else {
  //     console.log(item);
  //   }
  // }

  private onAddImage(index: any, indexImg: any) {
    if (this.hotel.tagHotelImage[index].hotelImage[indexImg].urlImage) {
      this.overlay = true;
      this.uploadImage(
        index,
        indexImg,
        this.hotel.tagHotelImage[index].hotelImage[indexImg].urlImage
      );
    }
  }

  private addTagHotelImage() {
    this.hotel.tagHotelImage.push({
      tagId: "",
      hotelImage: [
        {
          url: "",
          urlImageShow: "",
          description: "",
          isMain: false,
        },
      ],
    });
  }

  @Watch("roomTypeSearch")
  roomTypeSearchChange(newVal: string) {
    if (!this.loading && newVal) {
      clearTimeout(this.debounce);
      this.debounce = setTimeout(() => {
        this.fetchRoomTypes(newVal);
      }, 500);
    }
  }

  @Watch("mealPlanSearch")
  mealPlanSearchChange(newVal: string) {
    if (!this.loading && newVal) {
      clearTimeout(this.debounceMealPlan);
      this.debounceMealPlan = setTimeout(() => {
        this.fetchMealPlan(newVal);
      }, 500);
    }
  }

  private fetchRoomTypes(search: any) {
    this.loading = true;
    this.$api.hotel
      .getAllRoomTypes(this.page, 20, search)
      .then((res: any) => {
        this.loading = false;
        console.log("data", res.data);
        let data = res.data.map((x: any) => {
          let item = {
            value: x.id,
            text: x.name,
          };
          return item;
        });
        this.roomTypeList = data;
      })
      .catch((err: any) => {
        console.error("error: ", err);
        this.loading = false;
        this.$toast.error(err.statusMessage.toString());
      });
  }

  private fetchMealPlan(search: any) {
    this.loading = true;
    this.$api.hotel
      .getAllMealPlans(search)
      .then((res: any) => {
        this.loading = false;
        console.log("data", res.mealPlan.data);
        let data = res.data.map((x: any) => {
          let item = {
            value: x.id,
            text: x.mealPlanName,
          };
          return item;
        });
        this.mealPlanList = data;
      })
      .catch((err: any) => {
        console.error("error: ", err);
        this.loading = false;
        this.$toast.error(err.statusMessage.toString());
      });
  }

  private setRoomTypeName(value: any, index: string) {
    console.log("value room type", value);
    console.log("index", index);
    let name = this.roomTypeList.find((item) => item.value === value);
    this.hotel.roomTypes[index].typeName = name.text;
    console.log("hotel room types", this.hotel.roomTypes[index].typeName);
  }

  private setFeatureRoom(value: any[], index: string) {
    console.log("value feature romm", value);
    console.log("index", index);
    this.hotel.roomTypes[index].featureName = [];
    value.forEach((item) => {
      let name = this.featureListRoom.find((x) => x.value === item);
      this.hotel.roomTypes[index].featureName.push(name.text);
    });
    console.log("tagHotel Name", this.hotel.roomTypes[index].featureName);
  }

  private setMealPlan(value: any, indexRoom: string, indexRoomPrice: string) {
    console.log("value room type", value);
    console.log("indexRoom", indexRoom);
    console.log("index room price", indexRoomPrice);
    let name = this.mealPlanList.find((item) => item.value === value);
    this.hotel.roomTypes[indexRoom].roomPrice[indexRoomPrice].mealPlanName =
      name.text;
    console.log(
      "hotel mealPlan",
      this.hotel.roomTypes[indexRoom].roomPrice[indexRoomPrice].mealPlanName
    );
  }

  private addHotelImage(index: any) {
    this.hotel.tagHotelImage[index].hotelImage.push({
      url: "",
      urlImageShow: "",
      description: "",
      isMain: false,
    });
  }

  private deleteTagHotelImage(index: string) {
    this.hotel.tagHotelImage.splice(index, 1);
  }

  private deleteHotelImage(index: any, indexImg: any) {
    this.hotel.tagHotelImage[index].hotelImage.splice(indexImg, 1);
  }

  private addRoomTypeForm() {
    this.hotel.roomTypes.push({
      roomTypeId: "",
      typeName: "",
      feature: [],
      hotelAvabilityRoom: [
        {
          startDate: "",
          endDate: "",
          totalRoomAvailable: "",
        },
      ],
      roomPrice: [
        {
          price: "",
          mealPlanListId: "",
          packageRate: false,
          availFlag: false,
          canAmend: false,
          cancelationPolicyType: "",
          canHold: false,
          cancelationPolicy: [
            {
              fromDate: "",
              toDate: "",
              amount: "",
              b2bMarkup: "",
              grossAmount: "",
              night: "",
              percent: "",
              noShow: false,
            },
          ],
        },
      ],
    });
  }

  private deleteRoomTypeForm(index: string) {
    this.hotel.roomTypes.splice(index, 1);
  }

  private addRoomForm(indexRoomType: string) {
    this.hotel.roomTypes[indexRoomType].roomPrice.push({
      price: "",
      mealPlanListId: "",
      packageRate: false,
      availFlag: false,
      canAmend: false,
      cancelationPolicyType: "",
      canHold: false,
      cancelationPolicy: [
        {
          fromDate: "",
          toDate: "",
          amount: "",
          b2bMarkup: "",
          grossAmount: "",
          night: "",
          percent: "",
          noShow: false,
        },
      ],
    });
  }

  private deleteRoomForm(indexRoomType: string, indexRoom: string) {
    this.hotel.roomTypes[indexRoomType].roomPrice.splice(indexRoom, 1);
  }

  private addCancelationPolicy(indexRoomType: string, indexRoomPrice: string) {
    this.hotel.roomTypes[indexRoomType].roomPrice[
      indexRoomPrice
    ].cancelationPolicy.push({
      fromDate: "",
      toDate: "",
      amount: "",
      b2bMarkup: "",
      grossAmount: "",
      night: "",
      percent: "",
      noShow: false,
    });
  }

 

  private deleteCancelationPolicy(
    indexRoomType: string,
    indexRoomPrice: string,
    indexCancelation: string
  ) {
    this.hotel.roomTypes[indexRoomType].roomPrice[
      indexRoomPrice
    ].cancelationPolicy.splice(indexCancelation, 1);
  }

   private addHotelRoomAvaible(indexRoomType: string) {
    this.hotel.roomTypes[indexRoomType]
      .hotelAvabilityRoom.push({
        startDate: "",
        endDate: "",
        totalRoomAvailable: ""
      })
  }

  private deletehotelAvabilityRoom(indexRoomType: string, indexHotelAvaibleRoom: string ) {
     this.hotel.roomTypes[indexRoomType].hotelAvabilityRoom.splice(indexHotelAvaibleRoom, 1);
  }

  private addCommasPrice(i: any, j: any) {
      this.hotel.roomTypes[i].roomPrice[j].price = this.hotel.roomTypes[i].roomPrice[j].price.replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ".");
  }
  private addCommasAmount(i: any, j: any, k: any) {
      this.hotel.roomTypes[i].roomPrice[j].cancelationPolicy[k].amount = this.hotel.roomTypes[i].roomPrice[j].cancelationPolicy[k].amount.replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ".");
  }
  private addCommasB2B(i: any, j: any, k: any) {
      this.hotel.roomTypes[i].roomPrice[j].cancelationPolicy[k].b2bMarkup = this.hotel.roomTypes[i].roomPrice[j].cancelationPolicy[k].b2bMarkup.replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ".");
  }
  private addCommasGross(i: any, j: any, k: any) {
      this.hotel.roomTypes[i].roomPrice[j].cancelationPolicy[k].grossAmount = this.hotel.roomTypes[i].roomPrice[j].cancelationPolicy[k].grossAmount.replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ".");
  }

  private startDateCondition() {
    let date = new Date();
    return date.toISOString().slice(0, 10);
  }
  private resetEndDate(i: any, j: any) {
    this.hotel.roomTypes[i].hotelAvabilityRoom[j].endDate = "";
  }

  private fromDateCondition() {
    let date = new Date();
    return date.toISOString().slice(0, 10);
  }
  private resetToDate(i: any, j: any, k: any) {
    this.hotel.roomTypes[i].roomPrice[j].cancelationPolicy[k].toDate = "";
  }

  @Emit("save-hotel")
  saveAddedHotel() {
    for (let i = 0; i < this.hotel.roomTypes.length; i++) {
      for (let j = 0; j < this.hotel.roomTypes[i].roomPrice.length; j++) {
        this.hotel.roomTypes[i].roomPrice[j].price = this.hotel.roomTypes[i].roomPrice[j].price.split('.').join("");

        for (let k = 0; k < this.hotel.roomTypes[i].roomPrice[j].cancelationPolicy.length; k++) {
          this.hotel.roomTypes[i].roomPrice[j].cancelationPolicy[k].amount.split('.').join("");
          this.hotel.roomTypes[i].roomPrice[j].cancelationPolicy[k].b2bMarkup.split('.').join("");
          this.hotel.roomTypes[i].roomPrice[j].cancelationPolicy[k].grossAmount.split('.').join("");
        }
      }
    }
    return this.hotel;
  }
}
