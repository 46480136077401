






























/* eslint-disable */

import { Component, Vue } from "vue-property-decorator";
import TableComponent from "@/components/Hotel/TopHotel/Table.vue"; // @ is an alias to /src
import PermissionType from "@/constants/PermissionType.ts";

@Component({
  components: {
    TableComponent
  }
})
export default class TopHotel extends Vue {

  private loading: boolean = false
  private optionsTable: any = {
    page: 1,
    itemsPerPage: 10,
  };
  private totalHotel: number = 0
  private headers: Array<object> = [
    { text: 'Name', value: 'name', align: 'start', sortable: false },
    { text: 'Action', value: 'action', sortable: false },
  ]
  private isDetail: boolean = false
  private isAddTopHotel: boolean = false
  private hotels: Array<any> = []
  private permissions: Array<string> = []
  private permission: boolean = true
  private hotelList: any[] = []
  private mockHotel: any = {
   "statusCode": 200,
   "statusMessage": "OK",
   "hotels": [{
      "id": "nacsdjdnsjd",
      "hotelFitruumsId": 152905,
      "destinationId": 11726,
      "resortId": 16034,
      "transfer": 0,
      "notes": null,
      "types": "hotel",
      "name": "Bagues",
      "address": "La Rambla, 105 08002 Barcelona Spain",
      "headline": "This boutique hotel is bcscbsd",
      "description": "Majestic is a 5 star hotel located on Paseo de Gracia in the centre of Barcelona, within walking distance from Plaza Catalunya and Las Ramblas. Hotel facilities include reception 24h, 2 restaurants, bar, pool bar, outdoor  pool, sauna, steam bath, massage, gym, business center and parking. The guest rooms have bathroom/shower, hair dryer, mini bar, safe, telephone, internet, cable/satellite TV, work desk and air conditioning.",
      "Address": "Paseo De Gracia 68, 08007 Barcelona, Spain",
      "phone": "6217227",
      "resort": "Barcelona",
      "destination": "Barcelona",
      "star": 5,
      "isBestBuy": false,
      "isSuperDeal": false,
      "longitude": 106.09227,
      "latitude": 3833638,
      "sourceHotel": "fitruums",
      "isRecommended": false,
      "roomTypes": [{
        "roomTypeId": "bbacbhs",
        "roomTypeFitruumsId": 1077,
        "name": "",
        "rooms": [{
          "id": "sabchsbjsd",
          "roomFitruumsId": 67446939,
          "beds": 2,
          "extrabeds": 0,
          "meals": [{
            "id": "cnsknds",
            "fitruumsMealsId": 1,
            "price":4390551,
            "name": "No Meals",
            // "priceNotes": "{\"\currency\":\"\IDR\", \"\paymentMehods\": \"\1\"}",
            "discountedPrice": [{
              "price": 3000000,
              "checkinDate": "2021-02-14",
              "checkoutDate": "2021-02-16"
            }]
          }],
          "cancelationPolicies": [{
            "id": "cbjddjcs",
            "deadlineInHours": 24,
            "percentage": 50,
            "description": "Cancellation made less than 1 day (12:00) prior to the arrival will be charged with 50% of the total booking value."
          }],
          "paymentMethods": [{
            "id": "bsbjbvk",
            "name": "Invoice/Pay Now"
          }]
        }]
      }]
    }]
  }

  private isDelete: boolean = false;
  private editedTopHotel: any = {};
  // private permissions: Array<string> = [];
  // private permission: boolean = true;

  created() {
    // this.hotels = this.mockHotel.hotels
    // this.totalHotel = 1
    const permissions = this.$store.state['modules/auth'].user['permissions']
    console.log('permissions: ', permissions)
    this.permissions = [...permissions]
    this.checkCanCreateUpdateDeleteTopTenHotel();
    if (this.canViewTopTenHotel) {
      this.fetchTopTenHotel(this.optionsTable);
      this.fetchAllHotel();
    }
  }

  public get canViewTopHotel() {
    return true
    // return this.permissions.includes(PermissionType.VIEW_FEATURE)
  }

  // public fetchTopHotelList(options: any) {
  //   this.optionsTable = options
  //   this.hotels = []
  //   // this.loading = true
  //   console.log("Option => ", options);
  //   const { page, search, itemsPerPage } = options;

  //   // this.hotels = this.mockHotel.hotels
  //   // this.totalHotel = 1

  //   this.$api.feature.getFeatureList(page - 1, itemsPerPage == -1 ? null : itemsPerPage)
  //     .then((res: any) => {
  //       console.log("data", res.data)
  //       this.totalHotel = res.totalItems
  //       this.hotels = res.data
  //       this.loading = false
  //       console.log('Success get top Hotel list => ', res)
  //     })
  //     .catch((err: any) => {
  //       console.error('error: ', err)
  //       this.$toast.error(err.statusMessage.toString())
  //     })
  // }

  public get canViewTopTenHotel() {
    return this.permissions.includes(PermissionType.VIEW_TOP_TEN_HOTEL)
  }

  public checkCanCreateUpdateDeleteTopTenHotel(): void {
    this.permission = this.permissions.includes(PermissionType.CREATE_TOP_TEN_HOTEL) && this.permissions.includes(PermissionType.UPDATE_TOP_TEN_HOTEL) && this.permissions.includes(PermissionType.DELETE_TOP_TEN_HOTEL)
  }

  public fetchAllHotel() {
    this.hotelList = [];
    this.loading = true;

    this.$api.hotel.getAllHotel(0, 20)
      .then((res: any) => {
        this.loading = false
        this.hotelList = res.data
        for (let i = 0; i < this.hotels.length; i++) {
          let filter = this.hotelList.findIndex(el => el.id === this.hotels[i].id);
          if (filter >= 0) {
            this.hotelList.splice(filter, 1);
          }
        }
      })
      .catch((err: any) => {
        this.loading = false
        console.error('error: ', err)
        this.$toast.error(err.statusMessage.toString())
      })
  }

  public fetchTopTenHotel(options: any) {
    this.optionsTable = options
    this.hotels = []
    this.loading = true
    console.log("Option => ", options);
    const { page, itemsPerPage } = options;

    this.$api.hotel.getTopTenHotelList(page - 1, itemsPerPage == -1 ? null : itemsPerPage)
      .then((res: any) => {
        this.totalHotel = res.totalItems
        this.hotels = res.data
        this.loading = false
        console.log('Success get Top Ten Hotel List => ', res)
      })
      .catch((err: any) => {
        console.error('error: ', err)
        this.$toast.error(err.statusMessage.toString())
      })
  }

  private fetchCreateTopHotel(id:string, request: object) {
     this.loading = true 
     this.isAddTopHotel = false
     this.$api.hotel.updateHotelPopular(id, request)
      .then((res: any) => {
         
         this.$toast.success(res.statusMessage || res.message)
         this.fetchTopTenHotel(this.optionsTable);
      })
      .catch((err: any) => {
         console.error('error: ', err)
        this.$toast.error(err.statusMessage.toString())
      })
  }

 

  public saveAddedHotel(item: any) {
    console.log("added Hotel", item)
    this.fetchCreateTopHotel(item.id, item);
    // if (item.id) {
    //   console.log("update item", item);
    //   this.fetchUpdateTopHotel(item);
    // } else {
    //   console.log("create item", item);
    //   this.fetchCreateTopHotel(item.id, item);
    // }
    setTimeout(() => {
      this.closeAddEditDialog();
    }, 500);
  }

  public detailHotel(item: any) {
    console.log("Detail Hotel", item)
    this.isAddTopHotel = true;
    this.editedTopHotel = item;
  }

  public deleteHotel(item: any) {
    item.isPopular = false
    console.log("Delete Hotel", item)
    this.isDelete = false;
    setTimeout(() => {
      this.fetchDeleteTopHotel(item.id, item)
    }, 500);
  }

  public closeAddEditDialog() {
    this.editedTopHotel = {};
    this.isAddTopHotel = false;
  }

  public checkCanCreateUpdateDeleteTopHotel(): void {
    // this.permission = this.permissions.includes(PermissionType.CREATE_FEATURE) && 
    //                   this.permissions.includes(PermissionType.UPDATE_FEATURE) && 
    //                   this.permissions.includes(PermissionType.DELETE_FEATURE)
  }

  public getPagination(page: any) {
    this.optionsTable.page = page;
    this.fetchTopTenHotel(this.optionsTable);
  }

  public cancelAddEditDiscount() {
    this.isAddTopHotel = false;
    this.editedTopHotel = {};
  }

  // public fetchCreateTopHotel(topHotel: any): void {
  //   // this.$api.feature
  //   //   .createFeature(feature)
  //   //   .then((res: any) => {
  //   //     this.$toast.success(res.statusMessage);
  //   //     this.isAddEdit = false;
  //   //     this.editedFeature = {};
  //   //     this.fetchFeatureList(this.optionsTable, this.filter);
  //   //     console.log("Success Create Feature: ", res);
  //   //   })
  //   //   .catch((err: any) => {
  //   //     console.error("error: ", err);
  //   //     this.$toast.error(err.statusMessage.toString());
  //   //   });
  // }

  // public fetchUpdateTopHotel(topHotel: any): void {
  //   this.$api.feature
  //     .updateFeature(feature.id, feature)
  //     .then((res: any) => {
  //       this.$toast.success(res.statusMessage);
  //       this.isAddEdit = false;
  //       this.editedFeature = {};
  //       this.fetchFeatureList(this.optionsTable, this.filter);
  //       console.log("Success updated Feature: ", res);
  //     })
  //     .catch((err: any) => {
  //       console.error("error: ", err);
  //       this.$toast.error(err.statusMessage.toString());
  //     });
  // }

  public fetchDeleteTopHotel(id: string, item: object): void {
    console.log("id", id)
    this.$api.hotel.updateHotelPopular(id, item)
      .then((res: any) => {
         
         this.$toast.success(res.statusMessage)
         this.fetchTopTenHotel(this.optionsTable);
      })
      .catch((err: any) => {
         console.error('error: ', err)
        this.$toast.error(err.statusMessage.toString())
      })
  }

}
