
















































































































































































































































































































































































































































































































































































































































































































































import { Vue } from "vue-property-decorator";

export default Vue.extend({
  name: "FilterComponent",
  data: function () {
    return {
      minDate: "",
      maxDate: "",
      menuStartDate: false,
      menuEndDate: false,
      menuFlightDateStart: false,
      menuFlightDateEnd: false,
      menuDepartureDate: false,
      menuCheckInDate: false,
      menuCheckOutDate: false,
      menuPaymentDate: false,
      menuExpired: false,
      transactionIdFlight: null,
      custEmailFlight: null,
      custPhoneFLight: null,
      custEmailHotel: null,
      custPhoneHotel: null,
      statusTransaction: null,
      statusInvoice: null,
      startBookingDate: "",
      endBookingDate: "",
      flightDateStart: "",
      flightDateEnd: "",
      expired: "",
      voucherId: null,
      promoCode: null,
      departureDate: "",
      paymentMethod: null,
      passengerName: "",
      originFlight: null,
      checkInDate: "",
      checkOutDate: "",
      tourName: null,
      promoType: null,
      orderSource: null,
      bookingCode: null,
      price: null,
      paymentDate: "",
      destinationFlight: null,
      flightNumber: null,
      airlines: null,
      route: null,
      transactionIdHotel: null,
      statusTransactionHotel: null,
      statusInvoiceHotel: null,
      paymentMethodHotel: null,
      startBookingDateHotel: "",
      endBookingDateHotel: "",
      transactionIdTour: null,
      statusTransactionTour: null,
      statusInvoiceTour: null,
      paymentMethodTour: null,
      startBookingDateTour: "",
      endBookingDateTour: "",
    };
  },
  props: {
    tab: { required: true },
    // pageSize: { required: true},
    // pageNumber: { required: true},
  },
  watch: {
    startBookingDate: {
      handler() {
        this.minDate = this.startBookingDate
      }
    },
    endBookingDate: {
      handler() {
        this.maxDate = this.endBookingDate
      }
    }
  },
  methods: {
    clicked: function () {
      const filter = {
        transactionIdFlight: this.transactionIdFlight,
        startBookingDate: this.startBookingDate,
        endBookingDate: this.endBookingDate,
        expired: this.expired,
        price: this.price,
        statusTransaction: this.statusTransaction,
        statusInvoice: this.statusInvoice,
        paymentMethod: this.paymentMethod,
        paymentDate: this.paymentDate,
        bookingCode: this.bookingCode,
        custEmailFlight: this.custEmailFlight,
        custPhoneFLight: this.custPhoneFLight,
        route: this.route,
        passengerName: this.passengerName,
        flightDateStart: this.flightDateStart,
        flightDateEnd: this.flightDateEnd,
        flightNumber: this.flightNumber,
        airlines: this.airlines,
        originFlight: this.originFlight,
        destinationFlight: this.destinationFlight,
        transactionIdHotel: this.transactionIdHotel,
        statusTransactionHotel: this.statusTransactionHotel,
        statusInvoiceHotel: this.statusInvoiceHotel,
        paymentMethodHotel: this.paymentMethodHotel,
        startBookingDateHotel: this.startBookingDateHotel,
        endBookingDateHotel: this.endBookingDateHotel,
        custEmailHotel: this.custEmailHotel,
        custPhoneHotel: this.custPhoneHotel,
        checkInDate: this.checkInDate,
        checkOutDate: this.checkOutDate,
        voucherId: this.voucherId,
        transactionIdTour: this.transactionIdTour,
        statusTransactionTour: this.statusTransactionTour,
        statusInvoiceTour: this.statusInvoiceTour,
        startBookingDateTour: this.startBookingDateTour,
        endBookingDateTour: this.endBookingDateTour,
        paymentMethodTour: this.paymentMethodTour,
        departureDate: this.departureDate,
        promoCode: this.promoCode,
        promoType: this.promoType,
        tourName: this.tourName,
        orderSource: this.orderSource,
        // pageSize: this.pageSize,
        // pageNumber: this.pageNumber,
      }
      this.$emit('applyFilter', filter);
    },
  },
});
