enum PermissionType {
  VIEW_AUDIT_TRAIL = "View Audit Trail",

  VIEW_LANDING_PAGE_PROMO = "View Landing Page Promo",
  CREATE_LANDING_PAGE_PROMO = "Create Landing Page Promo",
  UPDATE_LANDING_PAGE_PROMO = "Update Landing Page Promo",
  DELETE_LANDING_PAGE_PROMO = "Delete Landing Page Promo",
  
  VIEW_PROMOTION_CODE = "View Promotion Code",
  CREATE_PROMOTION_CODE = "Create Promotion Code",
  UPDATE_PROMOTION_CODE = "Update Promotion Code",
  DELETE_PROMOTION_CODE = "Delete Promotion Code",
  
  VIEW_FLIGHT = "View Flight",
  UPDATE_FLIGHT = "Update Flight",
  
  VIEW_CUSTOMER = "View Customer",
  UPDATE_CUSTOMER = "Update Customer",

  VIEW_USER = "View User",
  CREATE_USER = "Create User",
  UPDATE_USER = "Update User",
  RESET_USER_PASSWORD = "Reset Password",

  VIEW_MEALPLAN = "View Meal Plan",
  CREATE_MEALPLAN = "Create Meal Plan",
  UPDATE_MEALPLAN = "Update Meal Plan",
  DELETE_MEALPLAN = "Delete Meal Plan",

  VIEW_TAG_HOTEL = "View Tag Hotel",
  CREATE_TAG_HOTEL  = "Create Tag Hotel",
  UPDATE_TAG_HOTEL = "Update Tag HoTel",
  DELETE_TAG_HOTEL = "Delete Tag Hotel",

  VIEW_TAG_HOTEL_IMAGE = "View Tag Hotel Image",
  CREATE_TAG_HOTEL_IMAGE = "Create Tag Hotel Image",
  UPDATE_TAG_HOTEL_IMAGE = "Update Tag Hotel Image",
  DELETE_TAG_HOTEL_IMAGE = "Delete Tag Hotel Image",

  VIEW_ROOM_TYPE = "View Room Type",
  CREATE_ROOM_TYPE = "Create Room Type",
  UPDATE_ROOM_TYPE = "Update Room Type",
  DELETE_ROOM_TYPE = "Delete Room Type",

  VIEW_FEATURE = "View Feature",
  CREATE_FEATURE = "Create Feature",
  UPDATE_FEATURE = "Update Feature",
  DELETE_FEATURE = "Delete Feature",

  VIEW_HOTEL = "View Hotel",
  CREATE_HOTEL = "Create Hotel",
  UPDATE_HOTEL = "Update Hotel",
  DELETE_HOTEL = "Delete Hotel",

  VIEW_TOP_TEN_HOTEL = "View Top Ten Hotel",
  CREATE_TOP_TEN_HOTEL = "Create Top Ten Hotel",
  UPDATE_TOP_TEN_HOTEL = "Update Top Ten Hotel",
  DELETE_TOP_TEN_HOTEL = "Delete Top Ten Hotel",

  VIEW_DISCOUNT = "View Discount",
  CREATE_DISCOUNT = "Create Discount",
  UPDATE_DISCOUNT = "Update Discount",
  DELETE_DISCOUNT = "Delete Discount",

  VIEW_BANK = "View Bank",
  CREATE_BANK  = "Create Bank",
  UPDATE_BANK = "Update Bank",
  DELETE_BANK = "Delete Bank",

}

export default PermissionType