































/* eslint-disable */

import { Component, Vue } from "vue-property-decorator";
import TableComponent from "@/components/Hotel/HotelAvailabilityRoom/Table.vue"; // @ is an alias to /src
import DialogComponent from "@/components/Hotel/HotelAvailabilityRoom/Dialog.vue"; // @ is an alias to /src
import PermissionType from "@/constants/PermissionType.ts";
// import PermissionType from "@/constants/PermissionType.ts";
@Component({
  components: {
    TableComponent,
    DialogComponent,
  }
})

export default class MealPlan extends Vue {
  private loading: boolean = false
  private isAddEditAvability: boolean = false
  private isDelete: boolean = false
  private editedMealPlan: any = {}
  private optionsTable: any = {
    page: 1,
    itemsPerPage: 5,
  };
  private totalMealPlan: number = 0
  private mealPlans: any = []
  private permissions: Array<string> = []
  private permission: boolean = true
  private headers: Array<object> = [
    { text: 'Meal Plan Name', value: 'mealPlanName', align: 'start', sortable: false },
    { text: 'Meal Plan Code', value: 'mealPlanCode', align: 'start', sortable: false },
    { text: 'Source', value: 'source', align: 'start', sortable: false }, 
    { text: 'Action', value: 'action', sortable: false },
  ]
  private mockMealPlan: any = [
    {
      "id": "akjsgdajhsgd sdsds",
      "mealPlanName": "Meal Plan Name 1",
      "mealPlanCode": "MPN1"
    },
    {
      "id": "akjssdsdsdsdcxzxczxcxzcxzgdajhsgd",
      "mealPlanName": "Meal Plan Name 2",
      "mealPlanCode": "MPN2"
    },
    {
      "id": "akjsgsdsdsdajhsgd",
      "mealPlanName": "Meal Plan Name 3",
      "mealPlanCode": "MPN3"
    },
  ]

  created() {
    const permissions = this.$store.state['modules/auth'].user['permissions']
    console.log('permissions: ', permissions)
    this.permissions = [...permissions]
    this.checkCanCreateUpdateDeleteMealPlan();
    if (this.canViewMealPlan) {
      this.fetchMealPlanList(this.optionsTable);
    }
  }

  public get canViewMealPlan() {
    return this.permissions.includes(PermissionType.VIEW_MEALPLAN)
  }

  public fetchMealPlanList(options: any) {
    this.optionsTable = options
    this.mealPlans = []
    this.loading = true
    console.log("Option => ", options);
    const { page, search, itemsPerPage } = options;

    this.$api.mealPlan.getMealPlanList(page - 1, itemsPerPage == -1 ? null : itemsPerPage)
      .then((res: any) => {
        this.totalMealPlan = res.totalItems
        this.mealPlans = res.data
        this.loading = false
        console.log('Success get meal plan list => ', res)
      })
      .catch((err: any) => {
        console.error('error: ', err)
        this.$toast.error(err.statusMessage.toString())
      })
  }

  public getPagination(page: any) {
    this.optionsTable.page = page
    this.fetchMealPlanList(this.optionsTable)
  }

  public deleteMealPlan(item: any) {
    setTimeout(() => {
      this.fetchDeleteMealPlan(item.id)
      this.isDelete = false
    }, 500);
  }

  public detailMealPlan(item: any) {
    this.isAddEditAvability = true
    this.editedMealPlan = item
  }

  public closeAddEditDialog() {
    this.editedMealPlan = {}
    this.isAddEditAvability = false
  }

  public fetchCreateMealPlan(mealPlan: any): void {

    this.$api.mealPlan.createMealPlan(mealPlan)
      .then((res: any) => {
        this.$toast.success(res.statusMessage)
        this.isAddEditAvability = false
        this.fetchMealPlanList(this.optionsTable)
        console.log('Success Create Meal Plan: ', res)
      })
      .catch((err: any) => {
        console.error('error: ', err)
        this.$toast.error(err.statusMessage.toString())
      })
  }

  public fetchUpdateMealPlan(id: string, mealPlan: any): void {
    console.log("id", id)
    this.$api.mealPlan.updateMealPlan(id, mealPlan)
      .then((res: any) => {
        this.$toast.success(res.statusMessage)
        this.isAddEditAvability = false
        this.fetchMealPlanList(this.optionsTable)
        console.log('Success updated Meal Plan: ', res)
      })
      .catch((err: any) => {
        console.error('error: ', err)
        this.$toast.error(err.statusMessage.toString())
      })
  }

  public fetchDeleteMealPlan(id: string): void {
    console.log("id", id)
    this.$api.mealPlan.deleteMealPlan(id)
      .then((res: any) => {
        this.$toast.success(res.statusMessage)
        this.isAddEditAvability = false
        this.fetchMealPlanList(this.optionsTable)
        console.log('Success Delete Meal Plan: ', res)
      })
      .catch((err: any) => {
        console.error('error: ', err)
        this.$toast.error(err.statusMessage.toString())
      })
  }

  public saveAddedMealPlan(item: any) {
    if (item.id) {
      console.log("update item", item)
      this.fetchUpdateMealPlan(item.id, item )

    } else {
      console.log("create item", item)
      this.fetchCreateMealPlan(item)
    }
    // setTimeout(() => {
    //   this.closeAddEditDialog()
    // }, 500);
  }

  public checkCanCreateUpdateDeleteMealPlan(): void {
    this.permission = this.permissions.includes(PermissionType.CREATE_MEALPLAN) && this.permissions.includes(PermissionType.UPDATE_MEALPLAN) && this.permissions.includes(PermissionType.DELETE_MEALPLAN)
  }
}
