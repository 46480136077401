

































































































































































































































































import {
    Component,
    Vue
  } from "vue-property-decorator";
@Component({})
export default class ProductPurchase extends Vue {
    get productType() {
        return this.$store.state['modules/booking'].productType;
    }

    get bookingDetail() {
        return this.$store.state['modules/booking'].bookingOrder;
    }

    convertDate(date: string) {
        return date ? date.replace('T', ' ').replace('.000Z', '') : '';
    }
}
