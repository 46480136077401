import Vue from 'vue'
import Vuex from 'vuex'
import VuexPersistence from 'vuex-persist'
import Booking from '@/store/modules/booking'
import Auth from '@/store/modules/auth'
import LandingPage from '@/store/modules/landingpagepromo'

const vuexPersistence = new VuexPersistence({
  key: 'my-app',
  storage: window.localStorage
})

Vue.use(Vuex)

const store = new Vuex.Store({
  modules: {
    'modules/booking': Booking,
    'modules/auth': Auth,
    'modules/landingpagepromo': LandingPage,
  },
  plugins: [vuexPersistence.plugin]
})
export default store