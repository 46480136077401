import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import * as strings from './assets/strings'
import * as api from './service'
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";
import { PluginOptions } from 'vue-toastification/dist/types/src/types'
import JsonExcel from "vue-json-excel";

const options: PluginOptions = {
    draggable: false,
    timeout: 5000,
    hideProgressBar: true,
};

Vue.use(Toast, options);

Vue.prototype.$strings = strings
Vue.prototype.$api = api

Vue.config.productionTip = false
Vue.component("downloadExcel", JsonExcel);

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
