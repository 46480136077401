import { VuexModule, Module, Mutation, Action } from 'vuex-module-decorators'

@Module({ namespaced: true })
class Booking extends VuexModule {
  public filter = ''
  public bookingOrder = null
  public productType = 'FLight'

  @Mutation
  public setFilter(filter: string): void {
    this.filter = filter
  }

  @Mutation
  public setBookingOrder(bookingOrder: any): void {
    this.bookingOrder = bookingOrder
  }

  @Mutation
  public setProductType(productType: any): void {
    this.productType = productType
  }

  @Action
  public updateFilter(filter: string): void {
    this.context.commit('setFilter', filter)
  }

  get filterUpperCase() {
    return this.filter.toUpperCase()
  }
  get bookingOrderDetail() {
    return this.bookingOrder
  }
  get productTypes() {
    return this.productType
  }
}
export default Booking