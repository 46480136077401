














































/* eslint-disable */
import {
  Component,
  Vue,
  Watch
} from "vue-property-decorator";
import SummaryComponent from "@/components/OrderDetail/Summary.vue";
import ProductPurchase from "@/components/OrderDetail/ProductPurchase.vue";
import CustomerComponent from "@/components/OrderDetail/Customer.vue";
import PaymentComponent from "@/components/OrderDetail/Payment.vue";
import PassengerComponent from "@/components/OrderDetail/Passenger.vue";
import PermissionType from "@/constants/PermissionType.ts";

@Component({
  components: {
    SummaryComponent,
    ProductPurchase,
    CustomerComponent,
    PaymentComponent,
    PassengerComponent
  },
})

// @Component
export default class OrderDetail extends Vue {
  private itemsBreadcrumbs: Array < object > = [{
      text: 'Home',
      disabled: false,
      href: '/',
    },
    {
      text: 'Order Detail',
      disabled: true,
    },
  ]
  private tabsItem: any = []
  private totalBooking = 0;
  private tab = "0";
  private optionsTable: any = {
    page: 1,
    itemsPerPage: 10,
  };
  private fixOptionsTable: any = {
    page: 1,
    itemsPerPage: 10,
  };
  private totalSumTransaction = 0;
  private loadingTable = false;
  private isEdit: any = {
    flight: false,
    hotel: false,
    tour: false
  }
  private editedItemRefundStatus = "";
  private bookingList: Array < object > = []
  private totalItems = 0
  private filter = {}
  private openFilter = false;

  private permissions: Array < string > = [];

  private totalTransaction = "0";
  private totalFinalPrice = "0";
  private totalCoupon = "0";
  private totalDiscount = "0";

  @Watch('tab')
  private tabChanged() {
    console.log("tab changed", this.$strings.common.getTextProductType(this.tab))
    this.openFilter = false;
    this.totalSumTransaction = 0
    this.filter = {}
    this.fetchBookingList(this.fixOptionsTable)
    this.fetchSummary()
  }

  created() {
    // this.fetchBookingList(this.optionsTable)
    const permissions = this.$store.state['modules/auth'].user['permissions']
    this.permissions = [...permissions]
  }

  mounted() {
    console.log('TAB', this.tab)
    const dataType = this.$store.getters['modules/booking/productTypes']
    if (dataType === "Flight") {
      this.tabsItem = ['Order Detail', 'Payment', 'Passenger']
    } else if (dataType === "Hotel") {
      this.tabsItem = ['Order Detail', 'Payment', 'Guest']
    } else {
      this.tabsItem = ['Order Detail', 'Payment', 'Participant']
    }
  }

  public get canViewFlightBooking() {
    return this.permissions.includes(PermissionType.VIEW_FLIGHT)
  }

  public get canUpdateFlightBooking() {
    return this.permissions.includes(PermissionType.UPDATE_FLIGHT)
  }

  public applyFilter(filter: object): void {
    // call api from backend to apply filter
    this.filter = {
      ...filter
    }
    this.fetchBookingList(this.fixOptionsTable)
    console.log("parent", filter);
  }

  public changeStatus(item: any): void {
    if (item !== 'cancel') {
      this.fetchUpdateBooking(item)
    } else {
      this.isEdit[this.$strings.common.getTextProductType(this.tab).toLowerCase()] = false
    }
  }

  public actionUser(item: any): void {
    this.isEdit[this.$strings.common.getTextProductType(this.tab).toLowerCase()] = true
    this.editedItemRefundStatus = item.refund ? item.refund.status : item.refund
  }

  public fetchSummary() {
    this.$api.booking.getSummaryBooking(this.$strings.common.getTextProductType(this.tab))
      .then((res: any) => {
        this.totalTransaction = res.totalTransaction ? res.totalTransaction : "0";
        this.totalFinalPrice = res.totalFinalPrice ? res.totalFinalPrice : "0";
        this.totalCoupon = res.totalKupon ? res.totalKupon : "0";
        this.totalDiscount = res.totalDiscount ? res.totalDiscount : "0";
      })
      .catch((err: any) => {
        console.error('error: ', err)
        this.$toast.error(err.statusMessage.toString())
      })
  }

  public fetchBookingList(options: any) {
    this.optionsTable = options
    this.loadingTable = true
    this.totalBooking = 0
    this.bookingList = []
    const {
      sortBy,
      sortDesc,
      page,
      itemsPerPage
    } = options;

    this.$api.booking.getBookingHistoryListByProductTypeAndParams(page - 1, itemsPerPage, this.$strings.common
        .getTextProductType(this.tab).toLowerCase(), this.filter)
      .then((res: any) => {
        if (this.totalSumTransaction === 0) this.totalSumTransaction = res.totalItems
        this.bookingList = res.data
        this.loadingTable = false
        this.totalBooking = res.totalItems
        console.log('Success Get Booking History => ', res)
      })
      .catch((err: any) => {
        console.error('error: ', err)
        this.$toast.error(err.statusMessage.toString())
      })
  }

  public fetchUpdateBooking(item: any): void {
    //fetch
    const bookingId = item.bookings.bookingId
    const updateItem = {
      statusTransaction: item.bookings.statusTransaction,
      statusInvoice: item.bookings.statusInvoice
    }

    if (item.refund.status !== this.editedItemRefundStatus && item.refund.status === "REFUNDED") {
      const req = {
        invoiceId: item.bookings.invoiceId,
        notes: item.refund.notes
      }
      this.$api.booking.updateStatusRefund(req)
        .then((res: any) => {
          console.log("Success Update Refund")
        })
    }

    this.$api.booking.updateStatusBooking(bookingId, updateItem)
      .then((res: any) => {
        this.$toast.success(res.statusMessage)
        console.log('Success Update Status => ', res)
        this.isEdit[this.$strings.common.getTextProductType(this.tab).toLowerCase()] = false
        this.fetchBookingList(this.optionsTable)
      })
      .catch((err: any) => {
        console.error('error: ', err)
        this.$toast.error(err.statusMessage.toString())
      })
  }
}
