






























































































































import { Component, Emit, Prop, Vue, Watch } from "vue-property-decorator";

@Component
export default class Table extends Vue {
  @Prop({required: true, type: Array}) readonly headers!: []
  @Prop({required: true, type: Array}) readonly items!: []
  @Prop({required: true}) readonly loading!: boolean
  @Prop({required: true}) readonly optionsTable!: object
  @Prop({required: true}) readonly totalAuditTrail!: number

  private options = {}
  private dialog = false
  private dialogItem = {}

  @Watch('options')
  optionsChanged() {
    this.fetchData()
  }

  @Emit('fetchData')
  public fetchData() {
    return this.options
  }
  
  public selectedRow(item: object) {
    Object.assign(this.dialogItem, item)
    this.dialog = true
  }

  public closeDialog() {
    this.dialog = false
  }
}
