

























































/* eslint-disable */
import { Component, Vue } from "vue-property-decorator";
import FilterComponent from "@/components/User/Filter.vue"; // @ is an alias to /src
import TableComponent from "@/components/User/Table.vue";
import DialogComponent from "@/components/User/Dialog.vue";
import PermissionType from "@/constants/PermissionType.ts";
import RoleType from "@/constants/RoleType.ts";

@Component({
  components: {
    FilterComponent,
    TableComponent,
    DialogComponent,
  },
})

// @Component
export default class User extends Vue {
  private users: Array<object> = [];
  private totalUser = 0;
  private optionsTable: any = {
    page: 1,
    itemsPerPage: 10,
  };
  private filter: any = ""
  private loadingTable = false;
  private isCreate = false;
  private isEdit = false;
  private isReset = false;
  private editUser = {};
  private itemsBreadcrumbs: Array<object> = [
    {
      text: "Home",
      disabled: false,
      href: "/",
    },
    {
      text: "User Management",
      disabled: true,
    },
  ];
  private permissions: Array<string> = [];
  private role: string = ''

  created() {
    // this.fetchAllUserList(this.optionsTable, '');
    const role = this.$store.state['modules/auth'].user['role']
    const permissions = this.$store.state['modules/auth'].user['permissions']
    this.role = role
    this.permissions = [...permissions]
    console.log('isSuperAdmin: ', this.isSuperAdmin)
  }

  public get canCreateUser() {
    return this.permissions.includes(PermissionType.CREATE_USER)
  }

  public get canViewUser() {
    return this.permissions.includes(PermissionType.VIEW_USER)
  }

  public get isSuperAdmin() {
    return this.role === RoleType.SUPER_ADMIN
  }

  public getData(): Array<object> {
    return [
      { userId: "1", name: "Muhammad Imam Santosa", password: "admin123", role: "Super Admin", email: "mohimamsaa@gmail.com", status: "Inactive" },
      { userId: "2", name: "Muhammad Imam Santosa", password: "admin123", role: "Admin", email: "mohimamsa@gmail.com", status: "Active" },
      { userId: "3", name: "Muhammad Imams Santosa", password: "admin123s", role: "Admin", email: "mohimamsasss@gmail.com", status: "Active" },
      { userId: "4", name: "Muhammad Imams Santosa", password: "admin123s", role: "User", email: "mohimamsasss@gmail.com", status: "Active" },
      { userId: "5", name: "Muhammad Imams Santosa", password: "admin123s", role: "User", email: "mohimamsasss@gmail.com", status: "Active" },
      { userId: "6", name: "Muhammad Imams Santosa", password: "admin123s", role: "User", email: "mohimamsasss@gmail.com", status: "Active" },
      { userId: "7", name: "Muhammad Imams Santosa", password: "admin123s", role: "Admin", email: "mohimamsasss@gmail.com", status: "Active" },
      { userId: "8", name: "Muhammad Imams Santosa", password: "admin123s", role: "Admin", email: "mohimamsasss@gmail.com", status: "Active" },
      { userId: "9", name: "Muhammad Imams Santosa", password: "admin123s", role: "Admin", email: "mohimamsasss@gmail.com", status: "Active" },
      { userId: "10", name: "Muhammad Imams Santosa", password: "admin123s", role: "Admin", email: "mohimamsasss@gmail.com", status: "Active" },
      { userId: "11", name: "Muhammad Imams Santosa", password: "admin123s", role: "Admin", email: "mohimamsasss@gmail.com", status: "Active" },
      { userId: "12", name: "Muhammad Imams Santosa", password: "admin123s", role: "Admin", email: "mohimamsasss@gmail.com", status: "Active" },
      { userId: "13", name: "Muhammad Imams Santosa", password: "admin123s", role: "Admin", email: "mohimamsasss@gmail.com", status: "Active" },
      { userId: "14", name: "Muhammad Imams Santosa", password: "admin123s", role: "Admin", email: "mohimamsasss@gmail.com", status: "Active" },
      { userId: "15", name: "Muhammad Imams Santosa", password: "admin123s", role: "Admin", email: "mohimamsasss@gmail.com", status: "Active" },
      { userId: "16", name: "Muhammad Imams Santosa", password: "admin123s", role: "Admin", email: "mohimamsasss@gmail.com", status: "Active" },
      { userId: "17", name: "Muhammad Imams Santosa", password: "admin123s", role: "Admin", email: "mohimamsasss@gmail.com", status: "Active" },
      { userId: "18", name: "Muhammad Imams Santosa", password: "admin123s", role: "User", email: "mohimamsasss@gmail.com", status: "Active" },
      { userId: "19", name: "Muhammad Imams Santosa", password: "admin123s", role: "User", email: "mohimamsasss@gmail.com", status: "Active" },
      { userId: "20", name: "Muhammad Imams Santosa", password: "admin123s", role: "User", email: "mohimamsasss@gmail.com", status: "Active" },
    ];
  }

  public fetchAllUserList(options: any, search: string): void {
    this.optionsTable = options
    this.loadingTable = true
    this.users = []
    const { sortBy, sortDesc, page, itemsPerPage } = options;

    this.$api.user.getUsersList(page - 1, itemsPerPage === -1 ? this.totalUser : itemsPerPage, search)
      .then((res: any) => {
        this.users = res.data
        this.totalUser = res.totalItems
        this.loadingTable = false
        console.log('get Users List => ', res)
      })
      .catch((err: any) => {
        console.error('error: ', err)
        this.$toast.error(err.error.toString())
      })
  }

  public fetchResetPassword(item: any): void {
    //call api reset password
    const reqBody = {
      newPassword: item.newPassword,
    }
    this.$api.user.resetPasswordUser(item.id, reqBody)
      .then((res: any) => {
        console.log("Success reset password => ", res)
        this.isReset = false
        this.$toast.success('Password Berhasil Diubah')
      })
      .catch((err: any) => {
        console.error('error: ', err.statusMessage)
        this.$toast.error(err.statusMessage.toString())
      })
  }

  public fetchUpdateUser(user: any): void {

    const {id} = user
    console.log("user => ", user)

    this.$api.user.updateUser(id, user)
      .then((res: any) => {
        this.isEdit = false
        console.log('success update user => ', res)
        this.$toast.success(res.statusMessage)
        this.fetchAllUserList(this.optionsTable, this.filter.email)
      })
      .catch((err: any) => {
        console.error('error: ', err)
        this.$toast.error(err.statusMessage.toString())
      })
  }

  public fetchCreateUser(user: any): void {

    this.$api.user.createUser(user)
      .then((res: any) => {
        this.isCreate = false;
        console.log('success create User => ', res)
        this.$toast.success(res.statusMessage)
        this.fetchAllUserList(this.optionsTable, this.filter.email)
      })
      .catch((err: any) => {
        console.error('error: ', err)
        this.$toast.error(err.statusMessage.toString())
      })
  }

  public cancelAction(action: any): void {
    if (action.isCancelCreate) {
      this.isCreate = false;
    } else {
      this.isEdit = false;
    }
  }

  public actionUser(value: string): void {
    if (value === "create") {
      this.isCreate = true;
    } else {
      this.editUser = value;
      this.isEdit = true;
    }
  }

  public applyFilter(filter: object): void {
    // call api from backend to apply filter
    this.filter = filter
    console.log(this.filter)
    this.fetchAllUserList(this.optionsTable, this.filter.email)
  }

  public saveUser(item: any): void {
    if (item.action === "create") {
      //call api create User
      this.fetchCreateUser(item.user)
    } else {
      //call api edit user
      //call fetch list api with filter
      this.fetchUpdateUser(item.user)
    }
  }
}
