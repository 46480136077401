// eslint-disable-next-line
const getTextPromoCode = (promoCodes: Array<any>, promoCodeId: string): string => {
  const promo = promoCodes.find(item => item.id === promoCodeId)
  if (promo === undefined) { return '' }
  return promo.promoCode
}

export default {
  text: {
    id: 'id',
    title: 'Title',
    content: 'Content',
    imageUrl: 'Image URL',
    startDate: 'Start Date',
    endDate: 'End Date',
    promoCode: 'Promo Code',
  },
  getTextPromoCode
}