import axios from "axios"
import url from './Url'

export const getTagHotel = async (pageNumber: number, pageSize: number, search?: string ) => {
  const qs = {
    pageNumber,
    pageSize,
    search
  }

  try {
    const result = await axios.get(url.tagHotel.TAG_HOTEL, {params:qs})
    return result.data
  } catch(error) {
    return Promise.reject(error);
  }
}

export const createTagHotel = async (request: object) => {
   
  try {
    const result = await axios.post(url.tagHotel.TAG_HOTEL, request)
    return result.data
  } catch (error) {
    return Promise.reject(error)
  }
}

export const updateTagHotel = async (id: string, request: object) => {
  try {
    const urlEdit = `${url.tagHotel.TAG_HOTEL}?id=${id}`
    const result = await axios.put(urlEdit, request)
    return result.data
  } catch (error) {
    return Promise.reject(error)
  }
}

export const deleteTagHotel = async (id: string) => {
  try {
    const urlDelete = `${url.tagHotel.TAG_HOTEL}?id=${id}`
    const result = await axios.delete(urlDelete)
    return result.data
  } catch (error) {
    return Promise.reject(error)
  }
}