
































































































import { Component, Prop, Emit, Vue } from "vue-property-decorator";

@Component
export default class Navbar extends Vue {
  mini = false;
  navs: Array<object> = [
    { title:"Dashboard", href:"/", icon:"mdi-home" },
    { title:"Hotel Management", href:"/hotel-management", icon:"mdi-bed" },
    { title:"Booking Management", href:"/booking-management", icon:"mdi-calendar", subMenus: [{title: 'Order', href: '/booking-management-order'}, {title: 'Sales', href: '/booking-management-sales'}] },
    { title:"Customer Management", href:"/customer-management", icon:"mdi-account-circle" },
    { title:"Voucher Discount", href:"/voucher-discount", icon:"mdi-percent" },
    { title:"Banner", href:"/banner", icon:"mdi-image" }, //title:"Landing Page Promo", href:"/landing-page-promo", icon:"mdi-image"
    { title:"Bank Management", href:"/bank-management", icon:"mdi-account-cash" },
    { title:"User Management", href:"/user-management", icon:"mdi-cog" },
    { title:"Customer Support", href:"/customer-support", icon:"mdi-at" },
    { title:"Audit Trail", href:"/audit-trail", icon:"mdi-chart-timeline-variant" },
  ]

  @Prop({required: true, type: String}) readonly username!: string

  private openSubMenus = false;

  @Emit('logout')
  public logout (): boolean {
    console.log('logout')
    return false
  }
}
