/* eslint-disable */
import store from '../../store'

export const authGuard = ((to: any, from: any, next: any) => {
  let isAuthenticated = false
  const token = store.state['modules/auth'].token;

  if (token !== '' && token !== null) {
    isAuthenticated = true;
  }

  if (isAuthenticated) next()
  else next('/login')
})