export default {
  text: {
    userId: 'User Id',
    email: 'Email',
    role: 'Role',
    status: 'User Status',
    name: 'Name',
    password: 'Password',
    phoneNumber: 'Phone Number'
  },
  status: ['Active', 'Inactive'],
  role: [
    {
      text: 'Super Admin',
      value: 'superadmin',
    },
    {
      text: 'Admin', 
      value: 'admin'
    },
    {
      text: 'User',
      value: 'user'
    }
  ]
}