























































































































































/* eslint-disable */

import { Component, Emit, Prop, Vue, Watch } from "vue-property-decorator";

@Component
export default class Dialog extends Vue {
    @Prop({required: true}) readonly isAddEdit!: boolean
    @Prop({required: true}) readonly editedBank!: any

    private bank: any = { logo: '' }
    private imageUrl: any = { logos: null }
    private isValid = false

    @Watch('isAddEdit')
    isAddRoomTypeChanged(newVal: boolean) {
        this.bank = {...this.editedBank}
        console.log(this.bank)
        this.imageUrl = { logos: null }

        if (!this.bank.id)
        {
            this.imageUrl = { logos: null }
            this.bank = { logo: '' }
            this.reset()
        }
    }

    @Emit('save-bank')
    saveAddedBank() {
        return this.bank
    }

    private fetchImage (image: any) {
        this.$api.image.uploadImage(image)
            .then((res: any) => {
                this.$toast.success('Success Upload Image')
                console.log('Success Upload Image: ', res)

                this.bank.logo = res.url
            })
            .catch((err: any) => {
                console.error('error: ', err)
                this.$toast.error(err.statusMessage.toString())
            })
    }

    private addImageLogos() {
        if (this.imageUrl.logos !== null && this.imageUrl.logos !== undefined) {
          this.fetchImage(this.imageUrl.logos)
        }
    }

    private editImageLogos() {
        if (this.bank.logo !== null && this.bank.logo !== undefined) {
          this.fetchImage(this.bank.logo)
        }
    }

    private deleteImage () {
        this.imageUrl.logos = null
        this.bank.logo = ""
    }
    
    private requiredRule (message: string): any
    {
        return [(v: any) => !!v || `${message} is required`]
    }

    private validate () {
        (this.$refs.form as Vue & { validate: () => boolean }).validate()
    }

    private reset () {
        this.$nextTick(() => {
        (this.$refs.form as Vue & { reset: () => boolean }).reset();
        })
    }
}
