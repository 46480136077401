











































































































































































































/* eslint-disable */
import { Component, Emit, Prop, Vue, Watch } from "vue-property-decorator";

@Component
export default class DialogComponent extends Vue {
  @Prop({required: true}) readonly isCreate!: boolean
  @Prop({required: true}) readonly isEdit!: boolean
  @Prop() readonly editUser!: Object
  @Prop() readonly permission!: boolean
  @Prop() readonly isSuperAdmin!: boolean
  @Prop() readonly isReset!: boolean

  private newPassword = ''
  private user = {
    email: '',
    roleName: '',
    name: '',
    password: ''
  }
  private editedUser = {
    id: '',
    password: ''
  }
  private isValid = false
  private isValidResetPass = false

  get roles(): Array<string> {
    return this.$strings.user.role.filter((role: any) => (!this.isSuperAdmin) ? role.text === 'User' : role)
  }

  @Watch('editUser')
  editUserChanged(newVal: any) {
    this.editedUser = {...newVal}
  }

  @Watch('isCreate')
  isCreateChanged(newVal: boolean) {
    if (newVal) {
      this.user = {
        email: '',
        roleName: '',
        name: '',
        password: ''
      }

      this.reset()
    }
  }

  @Watch('isReset')
  isResetChanged(newVal: boolean) {
    if (newVal) {
      this.newPassword = ''
    }
    this.resetResetPass()
  }

  @Emit('resetPassword')
  resetPassword() {
    return {id: this.editedUser.id, newPassword: this.newPassword}
  }

  @Emit('isCancel')
  clickCancel(value: string) {
    if (value === 'create') {
      return {isCancelCreate: true}
    } else {
      return {}
    }
  }

  @Emit('saveUser')
  saveUser(value: string) {
    if (value === 'create') {
      return {action: 'create', user: this.user}
    } else {
      return {action: 'edit', user: this.editedUser}
    }
  }

  private requiredRule (message: string): any
  {
    return [(v: any) => !!v || `${message} is required`]
  }

  private requiredRuleEmail (message: string): any
  {
    return [
      (v: any) => !!v || `${message} is required`,
      (v: string) => !v || /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || 'Email must be valid'
      ]
  }

  private requiredRulePassword (message: string): any
  {
    return [
      (v: string) => !!v || `${message} is required`,
      (v: string) => v.length >= 6 || 'Min 6 characters',
      (v: string) => v.search(/[A-Z]/) >= 0 || 'At least need one upper case letter',
      (v: string) => v.search(/[0-9]/) >= 0 || 'At least need one number',
      (v: string) => v.match(/[!@#$%^&*]/) || 'At least need one special character'
      ]
  }

  private validate () {
    (this.$refs.form as Vue & { validate: () => boolean }).validate()
  }

  private reset () {
    this.$nextTick(() => {
      (this.$refs.form as Vue & { reset: () => boolean }).reset();
    })
  }

// reset password
  private validateResetPass () {
    (this.$refs.formResetPass as Vue & { validate: () => boolean }).validate()
  }

  private resetResetPass () {
    this.$nextTick(() => {
      (this.$refs.formResetPass as Vue & { reset: () => boolean }).reset();
    })
  }

  public isPhoneNumber(event: any) {
    if (!isNaN(event.key) || event.key === "Delete" || event.key === "Backspace" || event.key.includes("Arrow")) {
      return true
    } else {
      event.preventDefault()
    }
  }
}
