






















import { Component, Emit, Vue } from "vue-property-decorator";

@Component
export default class FilterComponent extends Vue {
  private filter = {
    email: ''
  }

  @Emit('applyFilter')
  public clicked() {
    return this.filter
  }
}
