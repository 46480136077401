











































































/* eslint-disable */

import { Component, Emit, Prop, Vue, Watch } from "vue-property-decorator";

@Component
export default class Table extends Vue {
  @Prop({required: true}) readonly loading!: boolean
  @Prop({required: true}) readonly optionsTable!: any
  @Prop({required: true}) readonly totalBank!: number
  @Prop({required: true}) readonly headers!: Array<object>
  @Prop({required: true}) readonly bank!: Array<any>
  @Prop({required: true}) readonly isDelete!: Array<any>

  private options: any = {}
  private deletedItem: any = {}

  @Emit('get-pagination')
  getPagination(page: any) {
    console.log("page", page)
    return page
  }

  @Emit('get-item-perpage')
  getItemPerPage(item: any) {
    return item
  }

  @Watch('options')
  optionsChanged() {
    this.fetchData()
  }

  @Emit('fetchData')
  public fetchData() {
    return this.options
  }

  @Emit('detail-item')
  detailItem(item: any) {
    return item
  }

  @Emit('delete-item')
  deleteItemConfirm(item: any) {
    return this.deletedItem
  }

  @Emit('open-delete-dialog')
  deleteItem(item: any) {
    this.deletedItem = item
  }
}
