import axios from "axios"
import url from './Url'

export const getTagHotelImage = async (pageNumber: number, pageSize: number, search?: string ) => {
  const qs = {
    pageNumber,
    pageSize,
    search
  }

  try {
    const result = await axios.get(url.tagImage.TAG_IMAGE, {params:qs})
    return result.data
  } catch(error) {
    return Promise.reject(error);
  }
}

export const createTagHotelImage = async (request: object) => {
   
  try {
    const result = await axios.post(url.tagImage.TAG_IMAGE, request)
    return result.data
  } catch (error) {
    return Promise.reject(error)
  }
}

export const updateTagHotelImage = async (id: string, request: object) => {
  try {
    const urlEdit = `${url.tagImage.TAG_IMAGE}?id=${id}`
    const result = await axios.put(urlEdit, request)
    return result.data
  } catch (error) {
    return Promise.reject(error)
  }
}

export const deleteTagHotelImage = async (id: string) => {
  try {
    const urlDelete = `${url.tagImage.TAG_IMAGE}?id=${id}`
    const result = await axios.delete(urlDelete)
    return result.data
  } catch (error) {
    return Promise.reject(error)
  }
}