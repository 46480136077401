






























































































































import {
    Component,
    Vue,
    Watch
  } from "vue-property-decorator";
import { toCurrency } from "@/plugins/filter"
@Component({})
export default class ProductPurchase extends Vue {
    get bookingDetail() {
        return this.$store.state['modules/booking'].bookingOrder;
    }

    get productType() {
        return this.$store.state['modules/booking'].productType;
    }
    
    getCurrency(currency: any) {
        return currency ? toCurrency(Number.parseInt(currency)) : 'Rp 0';
    }

    convertDate(date: string) {
        return date ? date.replace('T', ' ').replace('.000Z', '') : '';
    }

    getOutstandingBalance(data: any) {
        const paid = data.paidAmount ? Number.parseInt(data.paidAmount) : 0;
        const total = Number.parseInt(data.totalPrice);
        const res = total - paid;
        return this.getCurrency(res);
    }
}
