import axios from "axios"
import url from './Url'

/**
 * Get Promo List
 * @param pageNumber number
 * @param pageSize number
 * @param promoCode string
 * @param productType string
 */
export const getPromoList = async (pageNumber: number, pageSize: number, promoCode: string, productType: string) => {
  const qs = {
    pageNumber: pageNumber,
    pageSize: pageSize,
    promoCode: promoCode,
    productType: productType
  }
  try {
    const result = await axios.get(url.promo.PROMO, { params: qs })
    return result.data
  } catch (error) {
    return Promise.reject(error)
  }
}

/**
 * Create Promo
 * @param request object
    * @param promoName string
    * @param promoDescription string 
    * @param startDate string 
    * @param endDate string  
    * @param quota string  
    * @param product string  
    * @param promoCode string optional
 */
export const createPromo = async (request: object) => {
  
  try {
    const result = await axios.post(url.promo.PROMO, request)
    return result.data
  } catch (error) {
    return Promise.reject(error)
  }
}

/**
 * Update Promo
 * @param promoId string
 * @param request object
    * @param promoName string
    * @param promoDescription string 
    * @param startDate string 
    * @param endDate string  
    * @param quota string  
    * @param product string
 */
export const updatePromo = async (promoId: string, request: object) => {
  const qs = {
    id: promoId
  }

  try {
    const result = await axios.put(url.promo.PROMO, request, { params: qs })
    return result.data
  } catch (error) {
    return Promise.reject(error)
  }
}

/**
 * Delete Promo
 * @param promoId string
*/
export const deletePromo = async (promoId: string) => {
  const qs = {
    id: promoId
  }

  try {
    const result = await axios.put(url.promo.DELETE_PROMO, {}, { params: qs })
    return result.data
  } catch (error) {
    return Promise.reject(error)
  }
}

/**
 * Get Promo List Asc
 * @param pageNumber number
 * @param pageSize number
 * @param promoCode string
 * @param productType string
 */
 export const getPromoListAsc = async (pageNumber: number, pageSize: number, promoCode: string, productType: string) => {
  const qs = {
    pageNumber: pageNumber,
    pageSize: pageSize,
    promoCode: promoCode,
    productType: productType
  }
  try {
    const result = await axios.get(url.promo.PROMO_ASC, { params: qs })
    return result.data
  } catch (error) {
    return Promise.reject(error)
  }
}