




























































































































/* eslint-disable */

import { Component, Emit, Prop, Vue, Watch } from "vue-property-decorator";

@Component
export default class FilterComponent extends Vue {
  private amountOptions = ["%", "Rp"]
  private ratingOptions = ["1", "2", "3", "4", "5"]
  private locationOptions = ["Jakarta Utara", "Tangerang", "Jakarta Selatan", "Depok", "Jakarta", "Bangkok"] // change later with location from backend
  private menu: boolean = false
  private menu2: boolean = false
  private maxDate: string = ""
  private minDate: string = ""
  private amount: string = ""
  private amountType: string = "Rp"
  private location: string = ""
  private rating: string = ""
  private validFrom: string = ""
  private validTo: string = ""

  private pageNumber: number = 0;
  private pageSize: number = 5;
  private pageTotal = 0;
  private search: string = "";

  async created()
  {
    const resPaginatedLocation = await this.paginatedLocation();
    this.pageTotal = resPaginatedLocation.totalPages;

    this.$api.discount.getAllCity(this.pageNumber, this.pageSize, this.search)
      .then((res: any) => {
        const result = res.data.map((r: { cityName: any; }) => r.cityName);
        this.locationOptions = result
      })
      .catch((err: any) => {
        console.error('error: ', err)
        this.$toast.error(err.statusMessage.toString())
      })
  }

  @Watch('validFrom')
  public validFromChange() {
    this.minDate = this.validFrom
  }

  @Watch('validTo')
  public validToChange() {
    this.maxDate = this.validTo
  }

  @Watch("search")
  private isSearchLocation(val: any) {
    if (!val || val === "") {
      this.location = "";
    }
  }

  @Emit('applyFilter')
  public applyFilter() {
    let amount = this.amount ? this.amount : null
    let location = this.location ? this.location : null
    let rating = this.rating ? this.rating : null
    let validFrom = this.validFrom ? this.validFrom : null
    let validTo = this.validTo ? this.validTo : null
    console.log(`amount: ${amount}, location: ${location}, rating: ${rating}, valid from: ${validFrom}, valid to: ${validTo}`)
    return {
      amount,
      location,
      rating,
      validFrom,
      validTo,
    }
  }

  async paginatedLocation () {
    return this.$api.discount.getAllCity(this.pageNumber, this.pageSize, this.search)
      .then((res: any) => {
        const result = res.data.map((r: { cityName: any; }) => r.cityName);
        this.locationOptions = result
        return res
      })
      .catch((err: any) => {
        console.error('error: ', err)
        this.$toast.error(err.statusMessage.toString())
      })
  }

  hasPrevPageLocation () {
    if (this.pageNumber - 1 >= 0) {
      this.pageNumber -= 1;
      this.paginatedLocation();
    }
    return Boolean(this.pageNumber > 0)
  }

  hasNextPageLocation () {
    if (this.pageNumber + 1 < this.pageTotal) {
      this.pageNumber += 1;
      this.paginatedLocation();
    }
    return Boolean(this.pageNumber < this.pageTotal)
  }
}
