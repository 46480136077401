import axios from "axios"
import url from './Url'

/**
 * Get Audit Trail List
 * @param pageNumber number
 * @param pageSize number
 * @param search string
 */
export const getAuditTrailList = (pageNumber: number, pageSize: number, search: string, tab?: number) => {
  const qs = {
    pageNumber,
    pageSize,
    search
  }

  let baseUrl = ''
  if (tab === 0) baseUrl = url.auditTrail.GET_BOOKING_AUDIT_TRAIL_LIST
  else if (tab === 1) baseUrl = url.auditTrail.GET_PROMO_AUDIT_TRAIL_LIST
  else if (tab === 2) baseUrl = url.auditTrail.GET_IDENTITY_AUDIT_TRAIL_LIST

  return axios.get(baseUrl, { params: qs })
}