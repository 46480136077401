var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.tags,"options":_vm.options,"items-per-page":_vm.optionsTable.itemsPerPage,"footer-props":{'items-per-page-options' : [5 ,10, 15, 25, 50, 100]},"server-items-length":_vm.totalTag,"loading":_vm.loading},on:{"update:options":function($event){_vm.options=$event},"update:page":_vm.getPagination},scopedSlots:_vm._u([{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticStyle:{"margin-right":"1%"},attrs:{"medium":""},on:{"click":function($event){return _vm.detailtag(item)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-pencil ")])]}}],null,true)},[_c('span',{staticStyle:{"font-size":"12px"}},[_vm._v(" Detail Tag ")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"medium":""},on:{"click":function($event){return _vm.deleteItem(item)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-delete ")])]}}],null,true)},[_c('span',{staticStyle:{"font-size":"12px"}},[_vm._v(" Delete Tag ")])])]}}],null,true)}),_c('v-dialog',{attrs:{"max-width":"500px"},model:{value:(_vm.isDelete),callback:function ($$v) {_vm.isDelete=$$v},expression:"isDelete"}},[_c('v-card',[_c('v-card-title',{staticClass:"headline justify-center",staticStyle:{"font-size":"20px !important"}},[_vm._v("Are you sure you want to delete this tag?")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{staticStyle:{"font-size":"12px"},attrs:{"color":"blue darken-1","text":""},on:{"click":function($event){_vm.isDelete = false}}},[_vm._v("Cancel")]),_c('v-btn',{staticStyle:{"font-size":"12px"},attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.deleteItemConfirm}},[_vm._v("OK")]),_c('v-spacer')],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }