export default {
  SUPER_ADMIN_PERMISSION: [
    "Create User",
    "Update User",
    "Reset Password",
    "View User",
    "Update Customer",
    "View Customer",
    "Update Flight",
    "View Flight",
    "Create Promotion Code",
    "Update Promotion Code",
    "View Promotion Code",
    "Delete Promotion Code",
    "Create Landing Page Promo",
    "Update Landing Page Promo",
    "View Landing Page Promo",
    "Delete Landing Page Promo",
    "View Audit Trail",
    "View Meal Plan",
    "Create Meal Plan",
    "Update Meal Plan",
    "Delete Meal Plan",
    "View Tag Hotel",
    "Create Tag Hotel",
    "Update Tag HoTel",
    "Delete Tag Hotel",
    "View Tag Hotel Image",
    "Create Tag Hotel Image",
    "Update Tag Hotel Image",
    "Delete Tag Hotel Image",
    "View Room Type",
    "Create Room Type",
    "Update Room Type",
    "Delete Room Type",
    "View Feature",
    "Create Feature",
    "Update Feature",
    "Delete Feature",
    "View Hotel",
    "Create Hotel",
    "Update Hotel",
    "Delete Hotel",
    "View Top Ten Hotel",
    "Create Top Ten Hotel",
    "Update Top Ten Hotel",
    "Delete Top Ten Hotel",
    "View Discount",
    "Create Discount",
    "Update Discount",
    "Delete Discount",
    "View Bank",
    "Create Bank",
    "Update Bank",
    "Delete Bank"
  ],
  ADMIN_PERMISSION: [
    "Create User", 
    "Update User", 
    "Reset Password", 
    "View User", 
    "Update Customer", 
    "View Customer", 
    "Update Flight", 
    "View Flight", 
    "Create Promotion Code", 
    "Update Promotion Code", 
    "View Promotion Code", 
    "Delete Promotion Code", 
    "Create Landing Page Promo", 
    "Update Landing Page Promo", 
    "View Landing Page Promo", 
    "Delete Landing Page Promo", 
    "View Audit Trail",
    "Create Meal Plan",
    "Update Meal Plan",
    "Delete Meal Plan",
    "Create Tag Hotel",
    "Update Tag HoTel",
    "Delete Tag Hotel",
    "Create Tag Hotel Image",
    "Update Tag Hotel Image",
    "Delete Tag Hotel Image",
    "Create Room Type",
    "Update Room Type",
    "Delete Room Type",
    "Create Feature",
    "Update Feature",
    "Delete Feature",
    "Create Hotel",
    "Update Hotel",
    "Delete Hotel",
    "Create Top Ten Hotel",
    "Update Top Ten Hotel",
    "Delete Top Ten Hotel",
    "View Discount",
    "Create Discount",
    "Update Discount",
    "Delete Discount",
    "View Bank",
    "Create Bank",
    "Update Bank",
    "Delete Bank"
  ],
  USER_PERMISSION: [
    "View User", 
    "View Customer", 
    "View Flight", 
    "View Promotion Code", 
    "View Landing Page Promo", 
    "View Audit Trail",
    "View Meal Plan",
    "View Tag Hotel",
    "View Tag Hotel Image",
    "View Room Type",
    "View Feature",
    "View Hotel",
    "View Top Ten Hotel",
    "View Discount"
  ]
}