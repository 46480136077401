
















































































































































































































































































































































































/* eslint-disable */

import { hotel } from "@/assets/strings";
import { Component, Emit, Prop, Vue, Watch } from "vue-property-decorator";
import AutoCompleteHotel from "./AutoCompleteHotel.vue";
import AutoCompleteRoomType from "./AutoCompleteRoomType.vue"

@Component({
  components: {
    AutoCompleteHotel,
    AutoCompleteRoomType
  }
})
export default class Dialog extends Vue {
  @Prop({required: true}) readonly isAddEditDiscount!: boolean
  @Prop({required: true}) readonly editedDiscount!: any

  private menu1: boolean = false
  private menu2: boolean = false
  private menu3: boolean = false
  private menu4: boolean = false
  private bookMaxDate: string = ""
  private bookMinDate: string = ""
  private stayMaxDate: string = ""
  private stayMinDate: string = ""
  private discountValueText: string = ""
  private locationOptions = ["Jakarta Utara", "Tangerang", "Jakarta Selatan", "Depok"] // change later with location from backend
  private promoName: string = ""
  private description: string = ""
  private hotels: any = [
    {
      hotelId: "",
      hotelName: "",
      roomTypeId: "",
      roomTypeName: "",
    }
  ]
  private countHotel: number = 1
  private bookStartDate: string = ""
  private bookEndDate: string = ""
  private stayStartDate: string = ""
  private stayEndDate: string = ""
  private status: string = ""
  private discountType: string = "Rp"
  private discountValue: string = ""
  private discountMax: string = ""
  private combinationPromo: string = ""
  private location: string = ""
  private star: string = ""

  // location
  private pageNumberLocation: number = 0;
  private pageSizeLocation: number = 5;
  private pageTotalLocation = 0;
  private searchLocation: string = "";

  private roomTypeOptions:object[][] = [];
  private isEdit = false;
  private isValid = false;

  private discount: any = {
    "promoName": "",
    "description": "",
    "bookStartDate": "", 
    "bookEndDate": "", 
    "checkinDate": "", 
    "checkoutDate": "", 
    "status": "", 
    "discountType": "Rp", 
    "discountValue": "", 
    "discountMax": "",
    "combinationPromo": "",
    "location": "",
    "star": "",
    "hotel": [{
      hotelId: "",
      roomTypeId: "",
      hotelName: "",
      roomTypeName: "",
    }]
  };

  created ()
  {
    // await this.paginatedLocation();
  }

  @Watch('isAddEditDiscount')
  async isAddDiscountChanged(newVal: boolean) {
    this.discount = {...this.editedDiscount}

    if(this.editedDiscount.id)
    {
      this.isEdit = true;

      this.discount.bookStartDate = this.discount.bookStartDate.substr(0, 10);
      this.discount.bookEndDate = this.discount.bookEndDate.substr(0, 10);
      this.discount.checkinDate = this.discount.checkinDate.substr(0, 10);
      this.discount.checkoutDate = this.discount.checkoutDate.substr(0, 10);

      this.discount.discountType = this.discount.discountType === "Percentage" ? "%" : "Rp"; 
      this.discountValue = parseInt(this.discount.discountValue).toString();
      // this.discount.discountValue = parseInt(this.discount.discountValue).toString();
      // this.discount.discountValue = this.discountValue;
      this.discount.discountMax = this.discount.discountMax ? parseInt(this.discount.discountMax).toString() : "";

      this.discount.combinationPromo = this.discount.combinationPromo === true ? "TRUE" : "FALSE";
      this.discount.location = this.discount.location === null ? "" : this.discount.location;
      this.discount.star = this.discount.star === null ? "" : this.discount.star.toString();
      this.locationOptions = [this.discount.location];

      await this.getHotelAndRoomType(this.editedDiscount.id);

      this.discount.discountValue = this.discountValue;
    }
    else
    {
      this.discount = {
        "promoName": "",
        "description": "",
        "bookStartDate": "", 
        "bookEndDate": "", 
        "checkinDate": "", 
        "checkoutDate": "", 
        "status": "", 
        "discountType": "Rp", 
        "discountValue": "", 
        "discountMax": "",
        "combinationPromo": "",
        "location": "",
        "star": "",
        "hotel": [{
          hotelId: "",
          roomTypeId: "",
          hotelName: "",
          roomTypeName: "",
        }]
      };

      this.hotels = [
        {
          hotelId: "",
          roomTypeId: "",
          hotelName: "",
          roomTypeName: "",
        }
      ]

      this.reset()
      this.isEdit = false;
      await this.paginatedLocation();
    }
  }

  @Watch('discount.discountValue')
  discountValueChanged(newVal: any) {
    if (this.discount.discountType === "Rp") {
      this.discount.discountValue = this.$strings.common.changeToCurrencyBasis(newVal.replace(/\./g, '').split(''))
    } else if (this.discount.discountType === "%") {
      if (parseInt(newVal) > 100) {
        this.$toast.error("Discount is not allowed to be more than 100%")
      }
    }
  }

  @Watch('discount.discountMax')
  discountMaxChanged(newVal: any) {
    if(this.discount.discountMax != undefined) 
    {
      this.discount.discountMax = this.$strings.common.changeToCurrencyBasis(newVal.toString().replace(/\./g, '').split(''))
    }
  }

  @Watch('discount.discountType')
  discountTypeChanged(newVal: any) {
    this.discount.discountValue = ""
  }

  @Watch('discount.hotel')
  hotelsChanged(newVal: any) {
    return newVal
  }

  @Watch('discount.checkinDate') //stayStartDate
  public stayStartChange() {
    this.stayMinDate = this.discount.checkinDate// this.stayStartDate
  }

  @Watch('discount.checkoutDate') //stayEndDate
  public stayEndChange() {
    this.stayMaxDate = this.discount.checkoutDate// this.stayEndDate
  }
  
  @Watch('discount.bookStartDate')
  public bookStartChange() {
    this.bookMinDate = this.discount.bookStartDate
  }

  @Watch('discount.bookEndDate')
  public bookEndChange() {
    this.bookMaxDate = this.discount.bookEndDate
  }

  @Emit('save')
  public saveDiscount() {
    // this.discount.discountType = this.discount.discountType === '%' ? 'Percentage' : 'Amount';
    // this.discount.discountValue = +this.discount.discountValue.replace('.', '');
    // // this.discount.discountMax = this.discount.discountMax.length > 0 ? +this.discount.discountMax.replace('.', '') : null;
    // this.discount.discountMax = this.discount.discountMax !== undefined ? +this.discount.discountMax.replace('.', '') : undefined;
    // this.discount.combinationPromo = this.discount.combinationPromo === 'TRUE' ? true : false;
    // // this.discount.location = this.discount.location.length > 0 ? this.discount.location : undefined;
    // // this.discount.star = this.discount.star.length > 0 ? this.discount.star : undefined;
    // this.discount.location = this.discount.location !== undefined ? this.discount.location : undefined;
    // this.discount.star = this.discount.star !== undefined ? this.discount.star : undefined;
    // // this.discount.hotel = this.discount.hotel[0].hotelId.length === 0 ? undefined : this.discount.hotel;
    this.discount.hotel = this.hotels[0].hotelId.length === 0 ? undefined : this.hotels;

    const disc = { ... this.discount };
    disc.discountType = disc.discountType === '%' ? 'Percentage' : 'Amount';
    disc.discountValue = +disc.discountValue.replace('.', '');
    disc.discountMax = disc.discountMax !== undefined ? +disc.discountMax.replace('.', '') : undefined;
    disc.combinationPromo = disc.combinationPromo === 'TRUE' ? true : false;
    disc.location = disc.location !== undefined ? disc.location : undefined;
    disc.star = disc.star !== undefined ? disc.star : undefined;

    return disc;
    // return this.discount;
  }

  public addForm() {
    const hotel: any = {}
    // hotel['hotel' + (this.hotels.length + 1)] = ""
    // hotel['room' + (this.hotels.length + 1)] = ""
    hotel['hotelId'] = ""
    hotel['roomTypeId'] = ""
    hotel['hotelName'] = ""
    hotel['roomTypeName'] = ""
    this.hotels.push(hotel)
    this.discount.hotel.push(hotel);
  }

  public isNumber(event: any) {
    if (!isNaN(event.key) || event.key === "Delete" || event.key === "Backspace" || event.key.includes("Arrow")) {
      if (this.discount.discountType === "Rp") {
        return true
      } else {
        if (parseInt(this.discount.discountValue + event.key) > 100) {
          console.log('bla', event.key)
          event.preventDefault()
        } else {
          return true
        }
      }
    } else {
      event.preventDefault()
    }
  }

  public isNumberDiscountMax(event: any) {
    if (!isNaN(event.key) || event.key === "Delete" || event.key === "Backspace" || event.key.includes("Arrow")) {
      return true
    } else {
      event.preventDefault()
    }
  }

// Location
  paginatedLocation () {
    return this.$api.discount.getAllCity(this.pageNumberLocation, this.pageSizeLocation, this.searchLocation)
      .then((res: any) => {
        const result = res.data.map((r: { cityName: any; }) => r.cityName);
        this.locationOptions = result;
        this.pageTotalLocation = res.totalPages;
        return res
      })
      .catch((err: any) => {
        console.error('error: ', err)
        this.$toast.error(err.statusMessage.toString())
      })
  }

  hasPrevPageLocation () {
    if (this.pageNumberLocation - 1 >= 0) {
      this.pageNumberLocation -= 1;
      this.paginatedLocation();
    }
    return Boolean(this.pageNumberLocation > 0)
  }

  hasNextPageLocation () {
    if (this.pageNumberLocation + 1 < this.pageTotalLocation) {
      this.pageNumberLocation += 1;
      this.paginatedLocation();
    }
    return Boolean(this.pageNumberLocation < this.pageTotalLocation)
  }

  findLocation (){
    this.pageNumberLocation = 0;
    this.paginatedLocation();
  }

  // get hotel and room type for update
  getHotelAndRoomType (discountConfigId: string) {
    return this.$api.discount.getHotelRoomForDiscount(discountConfigId)
      .then((res: any) => {
        this.hotels = res.data.hotels;

        if (this.hotels.length == 0)
        {
          this.hotels = [{
            hotelId: "",
            roomTypeId: "",
            hotelName: "",
            roomTypeName: "",
          }]
        }
      })
      .catch((err: any) => {
        console.error('error: ', err)
        this.$toast.error(err.statusMessage.toString())
      })
  }

  private fillHotel(item: any, index: any) {
    this.hotels.splice(index, 1, item)
  }

  private fillRoomType(item: any, index: any) {
    this.hotels[index] = item;
  }

  private requiredRule (message: string): any
  {
    return [(v: any) => !!v || `${message} is required`]
  }

  private validate () {
    (this.$refs.form as Vue & { validate: () => boolean }).validate()
  }

  private reset () {
    this.$nextTick(() => {
      (this.$refs.form as Vue & { reset: () => boolean }).reset();
    })
  }

  private bookStartDateCondition() {
    let date = new Date();
    return date.toISOString().slice(0, 10);
  }
  private resetBookEndDate() {
    this.discount.bookEndDate = "";
  }

  private stayStartDateCondition() {
    let date = new Date();
    return date.toISOString().slice(0, 10);
  }
  private resetStayEndDate() {
    this.discount.checkoutDate = "";
  }
}
