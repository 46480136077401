import axios from "axios"
import url from './Url'

/* eslint-disable */

/**
 * Login method
 * @param request object
 */
export const uploadImage = async (file: any) => {
  const data = new FormData()
  data.append('file', file)
  console.log("data", file)
  
  try {
    
    const result = await axios.post(url.image.UPLOAD_IMAGE, data)
    return result.data
  } catch (error) {
    return Promise.reject(error)
  }
}