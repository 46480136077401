

























































/* eslint-disable */
import { Component, Vue } from "vue-property-decorator";
import FilterComponent from "@/components/Bank/Filter.vue"; // @ is an alias to /src
import TableComponent from "@/components/Bank/Table.vue";
import DialogComponent from "@/components/Bank/Dialog.vue";
import PermissionType from "@/constants/PermissionType.ts";

@Component({
  components: {
    FilterComponent,
    TableComponent,
    DialogComponent,
  },
})

export default class BankManagement extends Vue {
  private loading: boolean = false;
  private isAddEdit: boolean = false;
  private isDelete: boolean = false;
  private editedBank: any = {};
  private optionsTable: any = {
    page: 1,
    itemsPerPage: 5
  };
  private permissions: Array<string> = [];
  private permission: boolean = true;
  private totalBank: number = 0;
  private bankList: any = [];
  private filter: any = "";
  private itemsBreadcrumbs: Array<object> = [
    {
      text: 'Home',
      disabled: false,
      href: '/',
    },
    {
      text: 'Bank Management',
      disabled: true,
    },
  ]
  private headers: Array<object> = [
    { text: "Id", value: "id", align: "start", sortable: false },
    { text: "Logo", value: "logo", align: "start", sortable: false },
    { text: "Name", value: "name", align: "start", sortable: false },
    { text: "Source", value: "codes", align: "start", sortable: false },
    { text: "Action", value: "action", sortable: false }
  ];
  private mockBank: any = [
    {
      id: 1,
      logo: "https://www.kibrispdr.org/data/sejarah-icon-png-0.jpg",
      name: "Bank Management",
      codes: "Bank"
    },
    {
      id: 2,
      logo: "https://www.kibrispdr.org/data/sejarah-icon-png-0.jpg",
      name: "Bank Management",
      codes: "Bank"
    },
    {
      id: 3,
      logo: "https://www.kibrispdr.org/data/sejarah-icon-png-0.jpg",
      name: "Bank Management",
      codes: "Bank"
    }
  ];

  created() {
    // this.bank = this.mockBank
    // this.totalBank = 4
    const permissions = this.$store.state["modules/auth"].user["permissions"];
    console.log("permissions: ", permissions);
    this.permissions = [...permissions];
    this.checkCanCreateUpdateDeleteBank();
    if (this.canViewBank) {
      this.fetchBankList(this.optionsTable, this.filter);
    }
  }

  public get canViewBank() {
    return this.permissions.includes(PermissionType.VIEW_BANK);
  }

  public fetchBankList(options: any, search: string) {
    this.optionsTable = options;
    this.bankList = [];
    this.loading = true;
    console.log("Option => ", options);
    const { sortBy, sortDesc, page, itemsPerPage } = options;

    this.$api.bank
      .getBankList(page - 1, itemsPerPage == -1 ? null : itemsPerPage, search)
      .then((res: any) => {
        this.totalBank = res.totalItems;
        this.bankList = res.data;
        this.loading = false;
        console.log("Success get bank list => ", res);
      })
      .catch((err: any) => {
        console.error("error: ", err);
        this.$toast.error(err.statusMessage.toString());
      });
  }

  public getPagination(page: any) {
    this.optionsTable.page = page;
    this.fetchBankList(this.optionsTable, this.filter);
  }

  public getItemPerPage(item: any) {
    this.optionsTable.itemsPerPage = item;
    this.fetchBankList(this.optionsTable, this.filter);
  }

  public fetchCreateBank(bank: any): void {
    this.$api.bank
      .createBank(bank)
      .then((res: any) => {
        this.$toast.success(res.statusMessage);
        this.isAddEdit = false;
        this.fetchBankList(this.optionsTable, this.filter);
        console.log("Success Create Bank: ", res);
      })
      .catch((err: any) => {
        console.error("error: ", err);
        this.$toast.error(err.statusMessage.toString());
      });
  }

  public fetchUpdateBank(id: string, bank: any): void {
    console.log("id", id)
    this.$api.bank.updateBank(id, bank)
      .then((res: any) => {
        this.$toast.success(res.statusMessage)
        this.isAddEdit = false
        this.fetchBankList(this.optionsTable, this.filter)
        console.log('Success Updated Bank: ', res)
      })
      .catch((err: any) => {
        console.error('error: ', err)
        this.$toast.error(err.statusMessage.toString())
      })
  }

  public fetchDeleteBank(id: string): void {
    console.log("id", id)
    this.$api.bank.deleteBank(id)
      .then((res: any) => {
        this.$toast.success(res.statusMessage)
        this.isAddEdit = false
        this.filter = '';
        this.fetchBankList(this.optionsTable, this.filter);
        console.log('Success Delete Bank: ', res)
      })
      .catch((err: any) => {
        console.error('error: ', err)
        this.$toast.error(err.statusMessage.toString())
      })
  }

  public deleteBank(item: any) {
    setTimeout(() => {
      this.fetchDeleteBank(item.id)
      this.isDelete = false;
    }, 500);
  }

  public detailBank(item: any) {
    this.isAddEdit = true;
    this.editedBank = item;
  }

  public closeAddEditDialog() {
    this.editedBank = {};
    this.isAddEdit = false;
  }

  public saveAddedBank(item: any) {
    if (item.id) {
      console.log("update item", item);
      this.fetchUpdateBank(item.id, item);
    } else {
      console.log("create item", item);
      this.fetchCreateBank(item);
    }
    // setTimeout(() => {
    //   this.closeAddEditDialog();
    // }, 500);
  }

  public checkCanCreateUpdateDeleteBank(): void {
    this.permission =
      this.permissions.includes(PermissionType.CREATE_BANK) &&
      this.permissions.includes(PermissionType.UPDATE_BANK) &&
      this.permissions.includes(PermissionType.DELETE_BANK);
  }

  public applyFilter(filter: object): void {
    // call api from backend to apply filter
    this.filter = filter;
    console.log(this.filter);
    this.fetchBankList(this.optionsTable, this.filter);
  }
}
