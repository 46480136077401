const getTextProductType = function(tab: number): string {
  if (tab === 0) {
    return "Flight"
  } else if (tab === 1) {
    return "Hotel"
  } else {
    return "Tour"
  }
}

const changeToCurrencyBasis = function(array: Array<string>): string {
  let result = ""
  let counter = 0
  for (let i = array.length - 1; i>=0; i--) {
    if (counter === 3) {
      result = array[i] + "." + result
      counter = 0
    } else {
      result = array[i] + result
    }
    counter ++;
  }
  return result
}

const changeFormatCurrencyRupiah = function(input: string): string {
  if (input === undefined) {
    return ""
  }
  const array = input.split('.')[0].split('')

  const result = "Rp. " + changeToCurrencyBasis(array)
  return result
}

const changeFormatDate = function(input: string): string {
  if (input) {
    return `${input.replace('T', ' ').replace('.000Z', '')}`
  } else {
    return '';
  }
}

export default {
  changeToCurrencyBasis,
  changeFormatCurrencyRupiah,
  getTextProductType,
  changeFormatDate,
  productType: ["Flight", "Hotel", "Tour"],
  booleanOptions: ["TRUE", "FALSE"]
}