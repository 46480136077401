

























































/* eslint-disable */
import { Component, Emit, Prop, Vue, Watch } from "vue-property-decorator";

@Component({
  components: {
    // TagHotelImageFormComponent
  }
})

export default class AutoCompleteRoomType extends Vue {
  @Prop({required: true}) readonly hotelRoomProp!: any

  private isDetail: boolean = false
  private hotelRoom: any = {}
  private roomTypes: any = []
  private roomType: any = ""
  private loading: boolean = false
  private debounce: any = null

  created() {
    this.hotelRoom = {...this.hotelRoomProp}
    this.roomType = this.hotelRoom.roomType.name
    this.fetchRoomType(this.roomType)
  }

  @Watch("hotelRoomProp")
  hotelRoomPropChanged(newVal: any) {
    this.hotelRoom = {...this.hotelRoomProp}
    this.roomType = this.hotelRoom.roomType.name
  }

  @Watch("hotelRoom.roomType")
  chooseFromAutoComplete(newVal: any) {
    this.hotelRoom.roomTypeId = newVal.id
    this.emitData()
  }

  @Emit("get-hotel-data")
  private emitData () {
    return this.hotelRoom
  }
  

  @Watch('roomType')
  categoryFeatureSearchChanged(newVal: string) {
    if (!this.loading && newVal) {
      this.loading = true;
      clearTimeout(this.debounce);
      this.debounce = setTimeout(() => {
        this.fetchRoomType(
          this.roomType
        );
      }, 500);
    }
  }

  fetchRoomType(search: string) {
    setTimeout(() => {
      this.roomTypes =  [
        {
          id: "1",
          name: "EXECUTIVE",
          "source": "Mg Jarvis",
          "size": "24.00 Sq.m.",
          "maxOccupancy": 1,
          "maxAdults": 1,
          "maxChild": 0,
          "isSmookingAllowed": false,
        },
        {
          id: "2",
          name: "BUSINESS",
          "source": "Mg Jarvis",
          "size": "24.00 Sq.m.",
          "maxOccupancy": 190,
          "maxAdults": 1123,
          "maxChild": 3434,
          "isSmookingAllowed": true,
        },
        {
          id: "3",
          name: "GATAU INI APA",
          "source": "Mg Jarvis",
          "size": "24.00 Sq.m.",
          "maxOccupancy": 1231,
          "maxAdults": 1234234,
          "maxChild": 2342342340,
          "isSmookingAllowed": false,
        }
      ]
      this.loading = false
    }, 500);
  }
}
