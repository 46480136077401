






































/* eslint-disable */

import { Component, Vue } from "vue-property-decorator";
import TableComponent from "@/components/Hotel/Tags/Table.vue"; // @ is an alias to /src
import DialogComponent from "@/components/Hotel/Tags/Dialog.vue"; // @ is an alias to /src
import PermissionType from "@/constants/PermissionType.ts";

@Component({
  components: {
    TableComponent,
    DialogComponent
  }
})

export default class TagImage extends Vue {

  private loading: boolean = false
  private optionsTable: any = {
    page: 1,
    itemsPerPage: 5,
  };
  private totalTagImage: number = 0
  private headers: Array<object> = [
    { text: 'Id', value: 'id', align: 'start', sortable: false },
    { text: 'Name', value: 'tagName', align: 'start', sortable: false },
    { text: 'Action', value: 'action', sortable: false },
  ]
  private isDetail: boolean = false
  private isAddEditTagImage: boolean = false
  private tagImages: Array<any> = []
  private editedTag: any = {}
  private permissions: Array<string> = []
  private permission: boolean = true
  private mockTagImage: any = {
   "statusCode": 200,
   "statusMessage": "OK",
   "hotelImagesTags": [
      {
        "id": "abcd",
        "tagName": "Indoor"
      },
      {
        "id": "abcd3",
        "tagName": "Kitchen"
      },
      {
        "id": "abcd4",
        "tagName": "Pool"
      },
      {
        "id": "abcd5",
        "tagName": "Outdoor"
      },
    ],
   "totalPages": 1,
   "totalItems": 1,
   "currentPage": 1
  }

  created() {
   const permissions = this.$store.state['modules/auth'].user['permissions']
    console.log('permissions: ', permissions)
    this.permissions = [...permissions]
    this.checkCanCreateUpdateDeleteTagImage();
    if(this.canViewTagHotelImage)  {
        this.fetchTagHotelImage(this.optionsTable)
    }
  }

  public getPagination(page: any) {
    this.optionsTable.page = page
    this.fetchTagHotelImage(this.optionsTable)
  }


  public fetchTagHotelImage(options: any) {
     this.optionsTable = options
    this.tagImages = []
    this.loading = true
    console.log("Option => ", options);
    const { page, search, itemsPerPage } = options;

    this.$api.tagImage.getTagHotelImage(page - 1, itemsPerPage == -1 ? null : itemsPerPage)
      .then((res: any) => {
        this.totalTagImage = res.totalItems
        this.tagImages = res.data
        this.loading = false
        console.log('Success get tag hotel image list => ', res)
      })
      .catch((err: any) => {
        console.error('error: ', err)
        this.$toast.error(err.statusMessage.toString())
      })
  }

  public fetchCreateTagHotelImage(tagImage: any): void {

    this.$api.tagImage.createTagHotelImage(tagImage)
      .then((res: any) => {
        this.$toast.success(res.statusMessage)
        this.isAddEditTagImage = false
        this.editedTag = {}
        this.fetchTagHotelImage(this.optionsTable)
        console.log('Success Create Tag Hotel Image: ', res)
      })
      .catch((err: any) => {
        console.error('error: ', err)
        this.$toast.error(err.statusMessage.toString())
      })
  }

  public fetchUpdateTagHotelImage(id: string, tagImage: any): void {
    console.log("id", id)
    this.$api.tagImage.updateTagHotelImage(id, tagImage)
      .then((res: any) => {
        this.$toast.success(res.statusMessage)
        this.isAddEditTagImage = false
        this.editedTag = {}
        this.fetchTagHotelImage(this.optionsTable)
        console.log('Success updated Tag Image: ', res)
      })
      .catch((err: any) => {
        console.error('error: ', err)
        this.$toast.error(err.statusMessage.toString())
      })
  }

  public fetchDeleteTagHotelImage(id: string): void {
    console.log("id", id)
    this.$api.tagImage.deleteTagHotelImage(id)
      .then((res: any) => {
        this.$toast.success(res.statusMessage)
        this.isAddEditTagImage = false
        this.fetchTagHotelImage(this.optionsTable)
        console.log('Success Delete Tag Image: ', res)
      })
      .catch((err: any) => {
        console.error('error: ', err)
        this.$toast.error(err.statusMessage.toString())
      })
  }

  public get canViewTagHotelImage() {

    console.log("permision type", PermissionType.VIEW_TAG_HOTEL_IMAGE )
    console.log("permision tag",this.permissions.includes(PermissionType.VIEW_TAG_HOTEL_IMAGE) )
    return this.permissions.includes(PermissionType.VIEW_TAG_HOTEL_IMAGE);
  }

   public checkCanCreateUpdateDeleteTagImage(): void {
    this.permission = this.permissions.includes(PermissionType.CREATE_TAG_HOTEL_IMAGE) && this.permissions.includes(PermissionType.UPDATE_TAG_HOTEL_IMAGE) && this.permissions.includes(PermissionType.DELETE_TAG_HOTEL_IMAGE)
  }

  public saveAddedTagImage(item: any) {
    if (item.id) {
      console.log("edit tag", item)
      this.fetchUpdateTagHotelImage(item.id, item);
    } else {
      console.log("create tag", item)
      this.fetchCreateTagHotelImage(item);
    }
  }

  public cancelAddEditTagImage() {
    this.isAddEditTagImage = false
    this.editedTag = {}
  }

  public detailTagImage(item: any) {
    this.editedTag = item
    this.isAddEditTagImage = true
  }

  public deleteTagImage(item: any) {
    console.log("Delete TagHotel", item)
    this.fetchDeleteTagHotelImage(item.id);
  }
}
