












































/* eslint-disable */
import { Component, Vue } from "vue-property-decorator";
import SearchComponent from "@/components/AuditTrail/Search.vue"; // @ is an alias to /src
import TableComponent from "@/components/AuditTrail/Table.vue"; // @ is an alias to /src
import PermissionType from "@/constants/PermissionType.ts";


@Component({
  components: {
    SearchComponent,
    TableComponent
  },
})

// @Component
export default class AuditTrail extends Vue {
  private itemsBreadcrumbs: Array<object> = [
    {
      text: 'Home',
      disabled: false,
      href: '/',
    },
    {
      text: 'Audit Trail',
      disabled: true,
    },
  ]
  private tab = 0
  private isLoading = false
  private auditTrailList: Array<object> = []
  private totalAuditTrail = 0
  private optionsTable = {
    page: 1,
    itemsPerPage: 5
  }
  private headers = [
    { text: 'ID', value: 'id', align: 'start', sortable: false },
    { text: 'Event Type', value: 'eventType' },
    { text: 'Event Name', value: 'eventName' },
    { text: 'Event Description', value: 'eventDescription' },
    { text: 'Event Date', value: 'eventDateTime' },
    { text: 'Object Name', value: 'objectName' },
    { text: 'Object ID', value: 'objectId' },
    { text: 'User ID', value: 'userId' },
    { text: 'Action', value: 'action' },
  ]
  private searchFilter = 'null'

  private permissions: Array<string> = [];

  created() {
    const permissions = this.$store.state['modules/auth'].user['permissions']
    this.permissions = [...permissions]
  }

  public get canViewAuditTrail() {
    return this.permissions.includes(PermissionType.VIEW_AUDIT_TRAIL)
  }

  public fetchAuditTrailList(options: any, tab: number, filter?: string) {
    this.optionsTable = options
    this.auditTrailList = []
    this.isLoading = true
    const { sortBy, sortDesc, page, itemsPerPage } = options
    let querySearch = filter ? filter : null;

    if (tab === 0) {
      // booking
      this.$api.auditTrail.getAuditTrailList(page - 1, itemsPerPage, querySearch, tab)
      .then((response: any) => {
        let items = response.data['data'];
        this.totalAuditTrail = response.data.totalItems;
        this.auditTrailList = items
        this.isLoading = false

        // this.$toast.success('Get Booking Audit Trail List Success!')
      })
      .catch((error: any) => {
        console.error('error: ', error)
        this.$toast.error('Get Booking Audit Trail List Failed!')
      })
      // this.totalAuditTrail = 1;
      // this.auditTrailList = [{ id: 1, eventType: 'DELETE', eventName: 'DELETE audit trail in booking'}];
      // this.isLoading = false;
    } else if (tab === 1) {
      // promo
      this.$api.auditTrail.getAuditTrailList(page - 1, itemsPerPage, querySearch, tab)
      .then((response: any) => {
        let items = response.data['data'];
        this.totalAuditTrail = response.data.totalItems;
        this.auditTrailList = items
        this.isLoading = false

        // this.$toast.success('Get Promo Audit Trail List Success!')
      })
      .catch((error: any) => {
        console.error('error: ', error)
        this.$toast.error('Get Promo Audit Trail List Failed!')
      })
      // this.totalAuditTrail = 2;
      // this.auditTrailList = [{ id: 1, eventType: 'DELETE', eventName: 'DELETE audit trail in promo'}, { id: 2, eventType: 'DELETE', eventName: 'DELETE audit trail in promo'}];
      // this.isLoading = false;
    } else if (tab === 2) {
      // user
      this.$api.auditTrail.getAuditTrailList(page - 1, itemsPerPage, querySearch, tab)
      .then((response: any) => {
        let items = response.data['data'];
        this.totalAuditTrail = response.data.totalItems;
        this.auditTrailList = items
        this.isLoading = false

        // this.$toast.success('Get User Audit Trail List Success!')
      })
      .catch((error: any) => {
        console.error('error: ', error)
        this.$toast.error('Get User Audit Trail List Failed!')
      })
    }
  }

  public applySearch(param: any) {
    this.fetchAuditTrailList(this.optionsTable, this.tab, param.tableName);
  }

  onTabChanged(tab: any) {
    this.fetchAuditTrailList(this.optionsTable, tab);
  }
}
