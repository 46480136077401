import axios from "axios"
import * as auditTrail from "./AuditTrailService"
import * as promoBanner from "./PromoBannerService"
import * as booking from "./BookingService"
import * as user from "./UserService"
import * as customer from "./CustomerService"
import * as auth from "./AuthService"
import * as promo from "./PromoService"
import * as image from "./ImageService"
import * as customerSupport from "./CustomerSupportService"
import * as mealPlan from "./MealPlanService"
import * as tagHotel from "./TagHotelService"
import * as roomType from "./RoomTypeService"
import * as feature from "./FeatureService"
import * as tagImage from "./TagHotelImageService"
import * as hotel from "./HotelService"
import * as discount from "./DiscountConfigurationService"
import * as bank from "./BankService"

import store from "../store"
import router from "@/router"

// Add a request interceptor
axios.interceptors.request.use(function (config) {
    const token = store.state['modules/auth'].token
    // console.log('token from api: ', token)
    config.headers.Authorization = `Bearer ${token}`

    return config;
}, function (error) {
    return Promise.reject(error);
})

// Add a response interceptor
axios.interceptors.response.use(function (response) {
    return response;
}, function (error) {
    if (error.response && error.response.status) {
        if (error.response.status === 401) {
            console.log("Unauthorized, logging out....");
            store.dispatch('clearState')
            router.push({ name: 'login' })
        } else {
            return Promise.reject(error.response.data)
        }
    }

    return Promise.reject(error.message);
})

export {
    axios,
    auditTrail,
    promoBanner,
    booking,
    user,
    customer,
    auth,
    promo,
    image,
    customerSupport,
    mealPlan,
    tagHotel,
    roomType,
    feature,
    tagImage,
    hotel,
    discount,
    bank
}