


















































































/* eslint-disable */
import { Vue } from "vue-property-decorator";
export default Vue.extend({
  name: "TableComponent",
  data: function () {
    return {
      headers: [
        // { text: "Promo Id", value: "id", sortable: false },
        { text: "Promo Title", value: "title", sortable: false },
        // { text: "Promo Subtitle", value: "subtitle", sortable: false },
        // { text: "Content", value: "content", sortable: false },
        // { text: "Details Image URL", value: "detailsImageUrl", sortable: false },
        // { text: "Home Image URL", value: "homeImageUrl", sortable: false },
        { text: "Start Date", value: "startDate", sortable: false },
        { text: "End Date", value: "endDate", sortable: false },
        // { text: "Promo Code", value: "promoCode", sortable: false},
        // { text: "Term & Conditions", value: "tnc", sortable: false },
        { text: 'Action', value: 'action', sortable: false },
      ],
      dialog: false,
      dialogData: {
        ticketStatus: ''
      },
      newTicketStatus: '',
      options: {},
      optionsTab: 0,
      isDelete: false,
      deletedItem: {}
    };
  },
  created() {
    if (!this.permission) {
      this.headers.pop()
    }
    this.optionsTab = this.tab
  },
  props: {
    loading: { required: true },
    data: { type: Array, required: true },
    optionsTable: { type: Object, required: true},
    totalPromo: { required: true },
    permission: { type: Boolean, required: true},
    tab: { type: Number, required: true },
    promoCode: { type: Array },
  },
  watch: {
    options: {
      handler(newVal: any, oldVal: any) {
        if (oldVal !== newVal) {
          if (this.optionsTab === this.tab) {
            this.fetchData()
          }
        }
      },
      deep: true
    },
    tab: {
      handler(newVal: number) {
        this.optionsTab = newVal
      }
    }
  },
  methods: {
    fetchData: function () {
      this.$emit('fetchData', this.options)
    },
    clickedRow: function (item: any) {
      this.dialog = true;
      this.dialogData = item
    },
    actionPromo: function (item: any) {
      if (item === 'create') {
        this.$emit('actionPromo', 'create')
      } else if (item === 'createForPwa') {
        this.$emit('actionPromo', 'createForPwa')
      } else {
        this.$emit('actionPromo', item)
      }
    },
    deleteItem: function (item: any) {
      this.deletedItem = item.id
      this.isDelete = true
    },
    deleteItemConfirm: function () {
      this.isDelete = false
      this.$emit('delete-promo', this.deletedItem)
    }
  },
});
