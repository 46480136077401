import { VuexModule, Module, Mutation, Action } from 'vuex-module-decorators'

@Module({ namespaced: true })
class LandingPagePromo extends VuexModule {
  public filter = ''

  @Mutation
  public setFilter(filter: string): void {
    this.filter = filter
  }

  @Action
  public updateFilter(filter: string): void {
    this.context.commit('setFilter', filter)
  }

  get filterUpperCase() {
    return this.filter.toUpperCase()
  }
}
export default LandingPagePromo