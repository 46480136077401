

































































































































































/* eslint-disable */

import { Component, Emit, Prop, Vue, Watch } from "vue-property-decorator";

@Component
export default class Table extends Vue {
  @Prop({ required: true }) readonly loading!: boolean;
  @Prop({ required: true }) readonly optionsTable!: any;
  @Prop({ required: true }) readonly totalHotel!: number;
  @Prop({ required: true }) readonly headers!: Array<object>;
  @Prop({ required: true }) readonly isDetail!: boolean;
  @Prop({ required: true }) readonly isAddTopHotel!: boolean;
  @Prop({ required: true }) readonly hotels!: Array<any>;
  @Prop({ required: true }) hotelList!: Array<any>;
  @Prop({ required: true }) readonly editedTopHotel!: any;
  @Prop({ required: true, default: false })  isDelete!: any;

  private isEdit: boolean = false;
  private deletedItem: any = {};
  private hotel: any = {};
  private popularNumber: Number = 0;
  private existingPopularNumber: Number = 0;
  private options: any = {};
  private hotelSearch: string = "";
  private debounce: any = null;
  private isLoading: boolean = false;
  private isValid = false;
  private page: number = 0;
  private rules = {
    select: [(v: any) => Object.keys(v).length !== 0 || "Hotel is required"],
  };

  @Watch(`hotelSearch`)
  hotelSearchChange(newVal: string) {
    console.log("hotel", newVal);
    if (!this.isLoading) {
      clearTimeout(this.debounce);
      this.debounce = setTimeout(() => {
        this.fetcHotels(newVal);
      }, 500);
    }
  }

  // table
  @Emit("get-pagination")
  getPagination(page: any) {
    console.log("page", page);
    return page;
  }

  @Watch("options")
  optionsChanged() {
    console.log("masuk option change");
    this.fetchData();
  }

  @Emit("fetchData")
  public fetchData() {
    return this.options;
  }

  @Emit("open-delete-dialog")
  deleteItem(item: any) {
    this.isDelete = true;
    this.deletedItem = item;
  }

  // @Emit('detail-item')
  // @Emit("detail-hotel")
  // detailHotel(item: any) {
  //   console.log("update", item);
  //   return item;
  // }

  // @Emit('delete-item')
  @Emit("delete-hotel")
  deleteItemConfirm(item: any) {
    // return item
    this.isDelete = false;
    return this.deletedItem;
  }

  // dialog
  @Watch("isAddTopHotel")
  isAddTopHotelChanged(newVal: boolean) {
    if (!this.isEdit) {
      this.reset();
    }
    if (newVal && !this.isEdit) {
      console.log("is add top", newVal);
      if (newVal) {
        this.hotel = {};
      }
    }
  }

  selectHotel() {
    const filter = this.hotels.find((el: any) => Number(el.popularNumber) === Number(this.popularNumber));
    let existNumber: any[] = [];
    let unUsedNumber: any[] = [];
    this.hotels.map((el: any) => {
      if (el.popularNumber <= 10) {
        existNumber.push(el.popularNumber);
      }
    })
    for (let i = 1; i <= 10; i++) {
      const index = existNumber.indexOf(i);
      if (index < 0) {
        unUsedNumber.push(i);
      }
    }

    const checkAgain = existNumber.find((el: any) => el === Number(this.popularNumber));
    if (this.popularNumber !== this.existingPopularNumber && checkAgain !== undefined) {
      this.popularNumber = unUsedNumber[0];
    }
  }

  hasPrevPageLocation () {
    if (this.page >= 1) {
      this.page -= 1;
      this.fetcHotels(this.hotelSearch);
    }
  }

  hasNextPageLocation () {
    this.page += 1;
    this.fetcHotels(this.hotelSearch);
  }

  // private endIntersect(isIntersecting: any) {
  //   if (isIntersecting) {
  //     this.page += 1;
  //     this.fetcHotels("");
  //   }
  // }

  private fetcHotels(search: any) {
    this.isLoading = true;
    this.$api.hotel
      .getAllHotel(this.page, 20, search)
      .then((res: any) => {
        this.isLoading = false;
        this.hotelList = res.data;
        for (let i = 0; i < this.hotels.length; i++) {
          let filter = this.hotelList.findIndex(el => el.id === this.hotels[i].id);
          if (filter >= 0) {
            this.hotelList.splice(filter, 1);
          }
        }
        // if (this.hotelList.length > 0) {
        //   this.hotelList.push(...res.data)
        // } else {
        //   this.hotelList = res.data;
        // }
        // for (let i = 0; i < this.hotels.length; i++) {
        //   let filter = this.hotelList.findIndex(el => el.id === this.hotels[i].id);
        //   if (filter >= 0) {
        //     this.hotelList.splice(filter, 1);
        //   }
        // }
      })
      .catch((err: any) => {
        this.isLoading = false;
        this.$toast.error(err.statusMessage.toString());
      });
  }

  // deleteItem(item: any) {
  //   this.deletedItem = item;
  //   this.isDelete = true;
  //   // if (this.hotel.id == undefined)
  //   // {
  //   //   this.reset()
  //   // }
  // }

  // deleteItem(item: any) {
  //   this.deletedItem = item
  //   this.isDelete = true
  // }

  changeHotel() {
    if (this.totalHotel >= 10) {
      this.$toast.error("Erase Hotel From Table to Add More Hotel!");
    } else {
      this.addTopHotel();
    }
  }

  @Emit("add-hotel")
  addTopHotel() {
    this.isEdit = false;
    console.log("masuk add top hotel");
  }

  @Emit("save-hotel")
  saveAddedHotel() {
    this.hotel.isPopular = true;
    this.hotel.popularNumber = Number(this.popularNumber);
    console.log("hotel", this.hotel);

    return this.hotel;
  }

  @Emit("detail-hotel")
  detailHotel(item: any) {
    this.isEdit = true;
    this.hotel = item;
    this.existingPopularNumber = item.popularNumber;
    this.popularNumber = item.popularNumber;
    console.log("hoteldata", this.hotel);
  }

  // @Emit("delete-hotel")
  // deleteItemConfirm() {
  //   this.isDelete = false;
  //   return this.deletedItem;
  // }

  private requiredRule(message: string): any {
    return [(v: any) => !!v || `${message} is required`];
  }

  async validate() {
    if ((this.$refs.form as Vue & { validate: () => boolean }).validate()) {
      await this.selectHotel();
      await this.saveAddedHotel();
    }
  }

  private reset() {
    this.$nextTick(() => {
      (this.$refs.form as Vue & { reset: () => boolean }).reset();
    });
  }
}
