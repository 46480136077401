import axios from "axios"
import url from './Url'

/**
 * Get Users List
 * @param pageNumber number
 * @param pageSize number
 * @param search string
 */
export const getUsersList = async (pageNumber: number, pageSize: number, search: string) => {
  const qs = {
    pageNumber: pageNumber,
    pageSize: pageSize,
    search: search
  }
  try {
    const result = await axios.get(url.user.USER, { params: qs })
    console.log('raw result get List user', result)
    return result.data
  } catch (err) {
    return Promise.reject(err)
  }
}

/**
 * Create User
 * @param request object
    * @param email string 
    * @param role string 
    * @param password string  
 */
export const createUser = async (request: object) => {

  try {
    const result = await axios.post(url.user.USER, request)
    console.log('raw result create user', result)
    return result.data
  } catch (err) {
    return Promise.reject(err)
  }
}

/**
 * Update User
 * @param userId string
 * @param request object
    * @param user object 
    * @param role string 
 */
export const updateUser = async (userId: string, request: object) => {
  const qs = {
    id: userId
  }

  try {
    const result = await axios.put(url.user.USER, request, { params: qs })
    console.log('raw result update user', result)
    return result.data
  } catch (err) {
    return Promise.reject(err)
  }

}

/**
 * Update User
 * @param userId string
 * @param request object
    * @param newPassword string
 */
export const resetPasswordUser = async (userId: string, request: object) => {
  const qs = {
    id: userId
  }
  try {
    const result = await axios.put(url.user.USER_RESET_PASSWORD, request, { params: qs })
    console.log('raw result reset Password user', result)
    return result.data
  } catch (err) {
    console.log('blabla')
    return Promise.reject(err)
  }
}

