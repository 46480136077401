






















import { Component, Emit, Vue } from "vue-property-decorator";

@Component
export default class Search extends Vue {
  private filter = ''

  @Emit('applyFilter')
  public searchClicked() {
    console.log('child: ', this.filter)
    return this.filter
  }
}
