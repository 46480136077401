
























































/* eslint-disable */

import { Component, Emit, Prop, Vue, Watch } from "vue-property-decorator";

@Component
export default class Table extends Vue {
  @Prop({required: true}) readonly loading!: boolean
  @Prop({required: true}) readonly optionsTable!: any
  @Prop({required: true}) readonly totalTag!: number
  @Prop({required: true}) readonly headers!: Array<object>
  @Prop({required: true}) readonly tags!: Array<any>

  private isDelete: boolean = false
  private deletedItem: any = {}
  private tag: string = ""
  private options: any = {}


  @Watch('options')
  optionsChanged() {
    console.log("masuk option change")
    this.fetchData()
  }

  @Emit('fetchData')
  public fetchData() {
    return this.options
  }

  deleteItem(item: any) {
    this.deletedItem = item
    this.isDelete = true
  }

  @Emit('get-pagination')
  getPagination(page: any) {
    console.log("page", page)
    return page
  }

  @Emit('detail-tag')
  detailtag(item: any) {
    return item
  }


  @Emit('delete-tag')
  deleteItemConfirm() {
    setTimeout(() => {
      this.isDelete = false
    }, 500);
    return this.deletedItem
  }
}
