









































































/* eslint-disable */
import { Component, Vue } from "vue-property-decorator";
import { namespace } from 'vuex-class'
const auth = namespace('modules/auth')

@Component
export default class Login extends Vue {

  public email = ''
  public password = ''
  public error = false
  public isLoading = false
  public rules = {
    required: (v: any) => !!v || 'Required.',
    min: (v: any) => v.length >= 6 || 'Min 6 characters',
    regex: (v: any) =>
      /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
      'E-mail must be valid',
    capital: (v: any) => v.search(/[A-Z]/) >= 0 || 'At least need one upper case letter',
    number: (v: any) => v.search(/[0-9]/) >= 0 || 'At least need one number',
    symbol: (v: any) => v.match(/[!@#$%^&*]/) || 'At least need one special character'
  }

  @auth.Action
  public doLogin!: (payload: any) => Promise<any>

  public login() {
    if ((this.$refs.entryForm as Vue & { validate: () => boolean }).validate()) {
      this.isLoading = true;

      this.doLogin({ email: this.email, password: this.password })
            .then((success: any) => {
              this.$toast.success('Login success!')
              this.$router.push({ name: 'Dashboard' })
            })
            .catch((error: any) => {
              this.$toast.error("Username atau password salah")
            })
            .finally(() => { this.isLoading = false })
    }
  }
}
