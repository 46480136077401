import axios from "axios"
import url from './Url'

/**
 * Get Discount Configuration
 * @param pageNumber number
 * @param pageSize number
 * @param search any 
 */
export const getDiscountList = async (pageNumber: number, pageSize: number, 
  location?: string, star?: string, value?: string, validDateFrom?: string, validDateTo?: string
  // search?: any
  ) => {
  const qs = {
    pageNumber,
    pageSize,
    location, 
    star, 
    value, 
    validDateFrom, 
    validDateTo
  }

  try {
    const result = await axios.get(url.discountConfig.DISCOUNT_CONFIG, { params: qs })
    return result.data
  } catch (error) {
    return Promise.reject(error)
  }
}

export const createDiscountConfig = async (request: object) => {
  try {
    const result = await axios.post(url.discountConfig.DISCOUNT_CONFIG, request)
    return result.data
  } catch (error) {
    return Promise.reject(error)
  }
}

export const updateDiscountConfig = async (request: object, id: string) => {
  const qs = { id }
  try {
    const result = await axios.put(url.discountConfig.DISCOUNT_CONFIG, request, { params: qs })
    return result.data
  } catch (error) {
    return Promise.reject(error)
  }
}

export const deleteDiscountConfig = async (id: string) => {
  try {
    const urlDelete = `${url.discountConfig.DISCOUNT_CONFIG}?id=${id}`
    const result = await axios.delete(urlDelete)
    return result.data
  } catch (error) {
    return Promise.reject(error)
  }

}

export const getAllCity = async (pageNumber: number, pageSize: number, search?: string) => {
  const qs = {
    pageNumber,
    pageSize,
    search
  }

  try {
    const result = await axios.get(url.city.CITY, { params: qs })
    return result.data
  } catch (error) {
    return Promise.reject(error)
  }
}

export const getHotel = async (pageNumber: number, pageSize: number, search?: string) => {
  const qs = {
    pageNumber,
    pageSize,
    search
  }

  try {
    const result = await axios.get(url.hotel.HOTEL, { params: qs })
    return result.data
  } catch (error) {
    return Promise.reject(error)
  }
}

export const getRoomType = async (hotelId: string) => {
  const qs = { hotelId }

  try {
    const result = await axios.get(url.discountRoomType.DISCOUNT_ROOMTYPE, { params: qs })
    return result.data
  } catch (error) {
    return Promise.reject(error)
  }
}

export const getHotelRoomForDiscount = async (discountConfigId: string) => {
  const qs = { discountConfigId }

  try {
    const result = await axios.get(url.hotelRoomForDiscount.HOTEL_ROOM_FOR_DISCOUNT, { params: qs })
    return result
  } catch (error) {
    return Promise.reject(error)
  }
}