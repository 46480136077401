


















































































/* eslint-disable */
import { Component, Vue, Watch } from "vue-property-decorator";
import FilterComponent from "@/components/Booking/Filter.vue"; // @ is an alias to /src
import SummaryComponent from "@/components/Booking/Summary.vue";
import TableComponent from "@/components/Booking/Table.vue";
import PermissionType from "@/constants/PermissionType.ts";

@Component({
  components: {
    FilterComponent,
    SummaryComponent,
    TableComponent,
  },
})

// @Component
export default class BookingManagementOrder extends Vue {
  private itemsBreadcrumbs: Array<object> = [
    {
      text: 'Home',
      disabled: false,
      href: '/',
    },
    {
      text: 'Booking Management',
      disabled: true,
    },
  ]
  private totalBooking = 0;
  private tab = "0";
  private optionsTable: any = {
    page: 1,
    itemsPerPage: 10,
  };
  private fixOptionsTable: any = {
    page: 1,
    itemsPerPage: 10,
  };
  private totalSumTransaction = 0;
  private loadingTable = false;
  private isEdit: any = {
    flight: false,
    hotel: false,
    tour: false
  }
  private editedItemRefundStatus = "";
  private bookingList: Array<object> = []
  private dataBookingList: any = []
  private totalItems = 0
  private filter = {}
  private openFilter = false;

  private permissions: Array<string> = [];

  private totalTransaction = "0";
  private totalFinalPrice = "0";
  private totalCoupon = "0";
  private totalDiscount = "0";

  @Watch('tab')
  private tabChanged() {
    console.log("tab changed",this.$strings.common.getTextProductType(this.tab))
    this.openFilter = false;
    this.totalSumTransaction = 0
    this.filter = {}
    this.fetchBookingList(this.fixOptionsTable)
    this.fetchSummary()
  }

  created() {
    // this.fetchBookingList(this.optionsTable)
    const permissions = this.$store.state['modules/auth'].user['permissions']
    this.permissions = [...permissions]
  }

  public get canViewFlightBooking() {
    return this.permissions.includes(PermissionType.VIEW_FLIGHT)
  }

  public get canUpdateFlightBooking() {
    return this.permissions.includes(PermissionType.UPDATE_FLIGHT)
  }

  public applyFilter(filter: object): void {
    // call api from backend to apply filter
    this.filter = {...filter}
    this.fetchBookingList(this.optionsTable)
    console.log("parent", filter);
  }

  public changeStatus(item: any): void {
    if (item !== 'cancel') {
        this.fetchUpdateBooking(item)
    } else {
      this.isEdit[this.$strings.common.getTextProductType(this.tab).toLowerCase()] = false
    }
  }

  public actionUser(item: any): void {
    this.isEdit[this.$strings.common.getTextProductType(this.tab).toLowerCase()] = true
    this.editedItemRefundStatus = item.refund ? item.refund.status : item.refund
  }

  public fetchSummary() {
     this.$api.booking.getSummaryBooking(this.$strings.common.getTextProductType(this.tab))
      .then((res: any) => {
        this.totalTransaction = res.totalTransaction ? res.totalTransaction : "0";
        this.totalFinalPrice = res.totalFinalPrice ? res.totalFinalPrice : "0";
        this.totalCoupon = res.totalKupon ? res.totalKupon : "0";
        this.totalDiscount = res.totalDiscount ? res.totalDiscount : "0";
      })
      .catch((err: any) => {
        console.error('error: ', err)
        this.$toast.error(err.statusMessage.toString())
      })
  }

  public fetchBookingList(options: any) {
    this.optionsTable = options
    this.loadingTable = true
    this.totalBooking = 0
    this.bookingList = []
    this.dataBookingList = []
    const { sortBy, sortDesc, page, itemsPerPage } = options;

    this.$api.booking.getBookingHistoryListByProductTypeAndParams(page - 1, itemsPerPage, this.$strings.common.getTextProductType(this.tab).toLowerCase(), this.filter)
      .then((res: any) => {
        if (this.totalSumTransaction === 0) this.totalSumTransaction = res.totalItems
        this.bookingList = res.data
        this.loadingTable = false
        this.totalBooking = res.totalItems
        this.buildDataBooking(res.data);
        console.log('Success Get Booking History => ', res)
      })
      .catch((err: any) => {
        console.error('error: ', err)
        this.$toast.error(err.statusMessage.toString())
      })
  }

  public buildDataBooking(data: any) {
    let datas = data.map((book: any) => book.bookings);
    let mapData = datas.map((d: any) => ({ 
      'Booking ID': d.bookingId,
      'Product Type': d.productType,
      'Booking Date': d.bookingDate ? this.$strings.common.changeFormatDate(d.bookingDate) : '',
      'Booking Expired': d.bookingExpired ? this.$strings.common.changeFormatDate(d.bookingExpired) : '',
      'Total Price': d.totalPrice ? this.$strings.common.changeFormatCurrencyRupiah(d.totalPrice) : '',
      'Source': d.source,
      'Status Transaction': d.statusTransaction,
      'Status Invoice': d.statusInvoice,
      'Payment Date': d.paymentDate ? this.$strings.common.changeFormatDate(d.paymentDate) : '',
      'Discount Amount': d.discountAmount ? this.$strings.common.changeFormatCurrencyRupiah(d.discountAmount) : '',
      'Payment Method': d.paymentMethod,
      'Paid Amount': d.paidAmount ? this.$strings.common.changeFormatCurrencyRupiah(d.paidAmount) : '',
      'Flight Booking Code': d.flightBookingCode,
      'Contact Name': d.contactName,
      'Contact Email': d.contactEmail,
      'Contact Phone': d.contactPhone,
      'Flight Route': d.flightType,
      'Departure Date': d.departureDate ? this.$strings.common.changeFormatDate(d.departureDate) : '',
      'Flight Number': d.flightNumber,
      'Airline Name': d.airlineName,
      'City Origin': d.cityOrigin,
      'City Destination': d.cityDestination
      }));
    // console.log(mapData)
    this.dataBookingList = mapData;
  }

  public fetchUpdateBooking(item: any): void {
    //fetch
    const bookingId = item.bookings.bookingId
    const updateItem = {
      statusTransaction: item.bookings.statusTransaction,
      statusInvoice: item.bookings.statusInvoice
    }

    if (item.refund.status !== this.editedItemRefundStatus && item.refund.status === "REFUNDED") {
      const req = {
        invoiceId: item.bookings.invoiceId,
        notes: item.refund.notes
      }
      this.$api.booking.updateStatusRefund(req)
        .then((res: any) => {
          console.log("Success Update Refund")
        }) 
    }

    this.$api.booking.updateStatusBooking(bookingId, updateItem)
      .then((res: any) => {
        this.$toast.success(res.statusMessage)
        console.log('Success Update Status => ', res)
        this.isEdit[this.$strings.common.getTextProductType(this.tab).toLowerCase()] = false
        this.fetchBookingList(this.optionsTable)
      })
      .catch((err: any) => {
        console.error('error: ', err)
        this.$toast.error(err.statusMessage.toString())
      })
  }

  public exportExcel() {
    console.log(this.optionsTable)
    const { page, itemsPerPage } = this.optionsTable;

    this.$api.booking.getBookingHistoryListByProductTypeAndParams(page - 1, itemsPerPage, this.$strings.common.getTextProductType(this.tab).toLowerCase(), this.filter)
      .then((res: any) => {
        console.log('DATA =>', res.data[0])
        const dataTemp = res.data[0];
        let csvContent = 'data:text/csv;charset=utf-8,';
        csvContent += [
          Object.keys(dataTemp[0]).join(';'),
          ...dataTemp.map((item: any) => {
            return Object.values(item).join(';');
          })
        ]
          .join('\n')
          .replace(/(^\[)|(\]$)/gm, '');

        const data = encodeURI(csvContent);
        const link = document.createElement('a');
        link.setAttribute('href', data);
        link.setAttribute('download', 'Excel.csv');
        link.click();
      })
      .catch((err: any) => {
        console.error('error: ', err)
        this.$toast.error(err.statusMessage.toString())
      })
  }
}
