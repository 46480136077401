export default {
  text : {
    promoCode: "Promo Code",
    promoName: "Promo Name",
    promoDescription: "Promo Description",
    startDate: "Start Date",
    endDate: "End Date",
    quota: "Quota",
    usageCount: "Usage Count",
    amount: "Amount",
    type: "Type",
    isOncePerUser: "isOncePerUser",
    percentageDiscount: "Percentage Discount",
    minimumTransaction: "Minimum Transaction",
    maximumDiscount: "Maximum Discount",
    promoType: "Promo Type",
    oncePerUser: "Third Per User",
    forEmployee: "Promo For Employee"
  },
  promoTypeOptions: ["Percentage", "Amount"]
}