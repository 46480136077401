












































/* eslint-disable */

import { Component, Emit, Prop, Vue, Watch } from "vue-property-decorator";

@Component
export default class Dialog extends Vue {
  @Prop({required: true}) readonly isAddEditTag!: boolean
  @Prop({required: true}) readonly editedTag!: any

  private tag: any = {
    tagName: ""
  }

  private valid = false
  private rule = [(v: any) => !!v || 'Tag Name is required']

  @Watch('isAddEditTag')
  isAddtagChanged(newVal: boolean) {
    if (newVal) {
      if (!this.editedTag) {
        this.tag = { tagName: "" }
      } else {
        this.tag = {...this.editedTag}
        
        if (!this.tag.id)
        {
          this.reset()
        }
      }
    } else {

    }
  }

  @Emit('save-tag')
  saveAddedtag() {
    return this.tag
  }
  
  validate () {
    (this.$refs.form as Vue & { validate: () => boolean }).validate()
  }

  reset () {
    this.$nextTick(() => {
      (this.$refs.form as Vue & { reset: () => boolean }).reset();
    })
  }
}

