








































/* eslint-disable */

import { Component, Vue } from "vue-property-decorator";
import TableComponent from "@/components/Hotel/Tags/Table.vue"; // @ is an alias to /src
import DialogComponent from "@/components/Hotel/Tags/Dialog.vue"; // @ is an alias to /src
import PermissionType from "@/constants/PermissionType.ts";

@Component({
  components: {
    TableComponent,
    DialogComponent
  }
})

export default class TagHotels extends Vue {

  private loading: boolean = false
  private optionsTable: any = {
    page: 1,
    itemsPerPage: 5,
  };
  private totalTagHotel: number = 0
  private headers: Array<object> = [
    { text: 'Id', value: 'id', align: 'start', sortable: false },
    { text: 'Name', value: 'tagName', align: 'start', sortable: false },
    { text: 'Action', value: 'action', sortable: false },
  ]
  private isDetail: boolean = false
  private isAddEditTagHotel: boolean = false
  private permissions: Array<string> = []
  private permission: boolean = true
  private tagHotels: Array<any> = []
  private editedTag: any = {}

  created() {
   const permissions = this.$store.state['modules/auth'].user['permissions']
    console.log('permissions: ', permissions)
    this.permissions = [...permissions]
    this.checkCanCreateUpdateDeleteMealPlan();
    if(this.canViewTagHotel)  {
        this.fetchTagHotel(this.optionsTable)
    }
  }

  public getPagination(page: any) {
    this.optionsTable.page = page
    this.fetchTagHotel(this.optionsTable)
  }

  public fetchTagHotel(options: any) {
     this.optionsTable = options
    this.tagHotels = []
    this.loading = true
    console.log("Option => ", options);
    const { page, search, itemsPerPage } = options;

    this.$api.tagHotel.getTagHotel(page - 1, itemsPerPage == -1 ? null : itemsPerPage)
      .then((res: any) => {
        this.totalTagHotel = res.totalItems
        this.tagHotels = res.data
        this.loading = false
        console.log('Success get tag hotel list => ', res)
      })
      .catch((err: any) => {
        console.error('error: ', err)
        this.$toast.error(err.statusMessage.toString())
      })
  }

  public fetchCreateTagHotel(tagHotel: any): void {
    this.$api.tagHotel.createTagHotel(tagHotel)
      .then((res: any) => {
        this.$toast.success(res.statusMessage)
        this.isAddEditTagHotel = false
        this.editedTag = {}
        this.fetchTagHotel(this.optionsTable)
        console.log('Success Create Tag Hotel: ', res)
      })
      .catch((err: any) => {
        console.error('error: ', err)
        this.$toast.error(err.statusMessage.toString())
      })
  }

  public fetchUpdateTagHotel(id: string, mealPlan: any): void {
    console.log("id", id)
    this.$api.tagHotel.updateTagHotel(id, mealPlan)
      .then((res: any) => {
        this.$toast.success(res.statusMessage)
        this.isAddEditTagHotel = false
        this.editedTag = {}
        this.fetchTagHotel(this.optionsTable)
        console.log('Success updated Meal Plan: ', res)
      })
      .catch((err: any) => {
        console.error('error: ', err)
        this.$toast.error(err.statusMessage.toString())
      })
  }

  public fetchDeleteTagHotel(id: string): void {
    console.log("id", id)
    this.$api.tagHotel.deleteTagHotel(id)
      .then((res: any) => {
        this.$toast.success(res.statusMessage)
        this.isAddEditTagHotel = false
        this.fetchTagHotel(this.optionsTable)
        console.log('Success Delete Meal Plan: ', res)
      })
      .catch((err: any) => {
        console.error('error: ', err)
        this.$toast.error(err.statusMessage.toString())
      })
  }
  
  public get canViewTagHotel() {
    console.log("permision type", PermissionType.VIEW_TAG_HOTEL )
    console.log("permision tag",this.permissions.includes(PermissionType.VIEW_TAG_HOTEL) )
    return this.permissions.includes(PermissionType.VIEW_TAG_HOTEL);
  }

  public checkCanCreateUpdateDeleteMealPlan(): void {
    this.permission = this.permissions.includes(PermissionType.CREATE_TAG_HOTEL) && this.permissions.includes(PermissionType.UPDATE_TAG_HOTEL) && this.permissions.includes(PermissionType.DELETE_TAG_HOTEL)
  }

  public saveAddedTagHotel(item: any) {
    if (item.id) {
      console.log("edit tag", item)
      this.fetchUpdateTagHotel(item.id, item)
    } else {
      console.log("create tag", item)
      this.fetchCreateTagHotel(item)
    }
    //  setTimeout(() => {
    //   this.closeAddEditDialog()
    // }, 500);
  }

  public closeAddEditDialog() {
    this.isAddEditTagHotel = false
  }

  public cancelAddEditTagHotel() {
    this.isAddEditTagHotel = false
    this.editedTag = {}
  }

  public detailTagHotel(item: any) {
    this.editedTag = item
    this.isAddEditTagHotel = true
  }

  public deleteTagHotel(item: any) {
    console.log("Delete TagHotel", item)
    this.fetchDeleteTagHotel(item.id)
  }
}
