



















































































































































































































































































































































































































































































































/* eslint-disable */
import { Component, Emit, Prop, Vue, Watch } from "vue-property-decorator";
// import TagHotelImageFormComponent from "./TagHotelImage.vue"
import AreaAroundHotelFormComponent from "./AreaAroundHotel.vue";
import ChildPolicyFormComponent from "./ChildPolicy.vue";

@Component({
  components: {
    // TagHotelImageFormComponent
    AreaAroundHotelFormComponent,
    ChildPolicyFormComponent
  }
})
export default class FormEditComponent extends Vue {
  @Prop({ required: true }) readonly isEdit!: boolean;
  @Prop({ required: true }) readonly editedItem!: any;
  @Prop({ required: true }) readonly featureList!: Array<any>;
  @Prop({ required: true }) readonly tagHotelList!: Array<any>;
  @Prop({ required: true }) readonly tagImageList!: Array<any>;
  @Prop({ required: true }) readonly featureListRoom!: Array<any>;

  // private editTagHotelImage: boolean = false
  private editAreaAroundHotel: boolean = false;
  private editChildPolicy: boolean = false;

  private hotel: any = {};
  private overlay: boolean = false;
  private loading: boolean = false;
  private mockTag = [{ tagName: "Clean" }, { tagName: "Super" }];

  @Watch("isEdit")
  isAddhotelChanged(newVal: boolean) {
    console.log("editedItem", this.editedItem);
    this.hotel = { ...this.editedItem };
  }

  @Emit("save-hotel")
  private saveHotel() {
    return this.hotel;
  }

  private setTagHotel(value: any[]) {
    console.log("tagHotel", value);
    this.hotel.tagHotelName = [];
    value.forEach(item => {
      let name = this.tagHotelList.find(x => x.value === item);
      this.hotel.tagHotelName.push(name.text);
    });
    let removeTagHotel = this.hotel.tagHotelTemp.filter((item: any) => !value.includes(item));
    console.log("remove tag hotel", removeTagHotel)
    this.hotel.removeTagHotel = removeTagHotel ? removeTagHotel : [];
    console.log("tagHotel Name", this.hotel.removeTagHotel);
  }

  private setTagHotelImage(value: any, index: string) {
    let name = this.tagImageList.find(item => item.value === value);
    this.hotel.tagHotelImage[index].tagName = name.text;
  }

  private setFeature(value: any[]) {
    console.log("feature", value);
    this.hotel.featureName = [];
    value.forEach(item => {
      let name = this.featureList.find(x => x.value === item);
      this.hotel.featureName.push(name.text);
    });

    let removeFeature = this.hotel.featureTemp.filter((item: any) => !value.includes(item));
    console.log("remove feature", removeFeature)
    this.hotel.removeFeature = removeFeature ? removeFeature : [];
    console.log("tagHotel Name", this.hotel.featureName);
  }

  private addAreaAroundHotel() {
    this.hotel.areaAroundHotel.push({
      placeName: "",
      distanceInMeters: "",
      description: ""
    });
  }

  private deleteAreaAroundHotel(index: number) {
    console.log(index);

    this.hotel.areaAroundHotel[index]["deletedAt"] = new Date(Date.now());
    this.hotel.areaAroundHotel[index]["deletedBy"] = "delete";
    this.hotel.areaAroundHotel[index]["isDelete"] = true
    // this.hotel.areaAroundHotel.splice(index, 1);
  }

  private addChildPolicy() {
    this.hotel.childPolicy.push({
      minAge: "",
      maxAge: ""
    });
  }

  private uploadImage(index: any, indexImg: any, image: any) {
    console.log("image", image);
    this.$api.image
      .uploadImage(image)
      .then((res: any) => {
        this.overlay = false;
        this.$toast.success("Success Upload Image");
        console.log("Success Upload Image: ", res);
        this.hotel.tagHotelImage[index].hotelImage[indexImg].url = res.url;
        this.hotel.tagHotelImage[index].hotelImage[indexImg].urlImageShow =
          res.url;
      })
      .catch((err: any) => {
        console.error("error: ", err);
        this.overlay = false;
        this.$toast.error(err.statusMessage.toString());
      });
  }

  private deleteChildPolicy(index: number) {
    console.log(index);
    this.hotel.childPolicy[index]["deletedAt"] = new Date(Date.now());
    this.hotel.childPolicy[index]["deletedBy"] = "delete";
    this.hotel.childPolicy[index]["isDelete"] = true;
    // this.hotel.childPolicy.splice(index, 1);
  }

  // private hotelTagImageChanged(item: any) {
  //   if (!this.hotel.tagHotelImage[item].tagName) {
  //     console.log(this.hotel.tagHotelImage[item].tagName);
  //   } else {
  //     console.log(item);
  //   }
  // }

  private onAddImage(index: any, indexImg: any) {
    if (this.hotel.tagHotelImage[index].hotelImage[indexImg].urlImage) {
      this.overlay = true;
      this.uploadImage(
        index,
        indexImg,
        this.hotel.tagHotelImage[index].hotelImage[indexImg].urlImage
      );
    }
  }

  private addHotelImage(index: any) {
    this.hotel.tagHotelImage[index].hotelImage.push({
      url: "",
      urlImageShow: "",
      description: "",
      isMain: false
    });
  }

  private addTagHotelImage() {
    this.hotel.tagHotelImage.push({
      tagId: "",
      hotelImage: [
        {
          url: "",
          urlImageShow: "",
          description: "",
          isMain: false
        }
      ]
    });
  }

  private deleteTagHotelImage(index: string) {
    this.hotel.tagHotelImage[index]["deletedAt"] = new Date(Date.now());
    this.hotel.tagHotelImage[index]["deletedBy"] = "delete";
        this.hotel.tagHotelImage[index]["isDelete"] = true;
    console.log("hotel tag hotel image", this.hotel.tagHotelImage)
    // this.hotel.tagHotelImage.splice(index, 1);
  }

  private deleteHotelImage(index: any, indexImg: any) {
    this.hotel.tagHotelImage[index].hotelImage[indexImg][
      "deletedAt"
    ] = new Date(Date.now());
    this.hotel.tagHotelImage[index].hotelImage[indexImg]["deletedBy"] =
      "delete";
    this.hotel.tagHotelImage[index].hotelImage[indexImg]["isDelete"] =
      true;
    // this.hotel.tagHotelImage[index].hotelImage.splice(indexImg, 1);
  }
}
