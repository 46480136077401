










































































































/* eslint-disable */
import { Component, Emit, Prop, Vue, Watch } from "vue-property-decorator";

@Component({
  components: {
  }
})

export default class FormEditRoomPriceComponent extends Vue {
  @Prop({required: true}) readonly isEdit!: boolean
  @Prop({required: true}) readonly editedItem!: any
  
  private roomPrices: any = []

  created() {
    this.roomPrices = [...this.editedItem]
  }

  @Watch('isEdit')
  isEditChanged(newVal: boolean) {
    if (newVal) {
      this.roomPrices = [...this.editedItem]
    } else {
      this.roomPrices = []
    }
  }

  @Emit('save')
  private saveRoom() {
    return this.roomPrices
  }

  private addRoomPrice() {
    this.roomPrices.push({
      mealPlanListId: "",
      cancelationPolicyType: "",
      packageRate: "",
      price: "",
      availFlight: false,
      isAmend: false,
      isHold: false
    })
  }

  private deleteRoomPrice(index: number) {
    this.roomPrices.splice(index, 1)
  }

  private mealPlans: any = [
    {
      id: "1",
      mealPlanName: "Meal Plan 1",
      mealPanCode: "MP1"
    },
    
    {
      id: "2",
      mealPlanName: "Meal Plan 2",
      mealPanCode: "MP2"
    }
  ]
}
