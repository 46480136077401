import axios from "axios"
import url from './Url'

/**
 * Get Feature List
 * @param pageNumber number
 * @param pageSize number
 * @param search string
 */
export const getFeatureList = async (pageNumber: number, pageSize: number, search?: string) => {
  const qs = {
    pageNumber,
    pageSize,
    search
  }

  try {
    console.log("Feature Url => ", url.feature.FEATURE)
    const result = await axios.get(url.feature.FEATURE, { params: qs})
    return result.data
  } catch (error) {
    return Promise.reject(error)
  }
}

export const getCategoryFeatureList = async (search?: string) => {
  const qs = {
    search
  }

  try {
    const result = await axios.get(`${url.feature.FEATURE}/category`, {params: qs})
    return result.data
  } catch (error) {
    return Promise.reject(error)
  }
}

export const createFeature = async (request: object) => {
  try {
    const result = await axios.post(url.feature.FEATURE, request)
    return result.data
  } catch (error) {
    return Promise.reject(error)
  }
}

export const updateFeature = async (id: string, request: object) => {
  try {
    const urlEdit = `${url.feature.FEATURE}?id=${id}`
    const result = await axios.put(urlEdit, request)
    return result.data
  } catch (error) {
    return Promise.reject(error)
  }
}

export const deleteFeature = async (id: string) => {
  try {
    const urlDelete = `${url.feature.FEATURE}?id=${id}`
    const result = await axios.delete(urlDelete)
    return result.data
  } catch (error) {
    return Promise.reject(error)
  }
}