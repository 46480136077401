










































































/* eslint-disable */

import { Component, Emit, Prop, Vue, Watch } from "vue-property-decorator";

@Component
export default class TableComponent extends Vue {
  @Prop({ required: true }) readonly loading!: boolean;
  @Prop({ required: true }) readonly optionsTable!: any;
  @Prop({ required: true }) readonly totalItems!: number;
  @Prop({ required: true }) readonly headers!: Array<object>;
  @Prop({ required: true }) readonly items!: Array<any>;
  @Prop({required: true}) readonly isDelete!: Array<any>;

  private options: any = {};
  private deletedItem: any = {};

  @Emit("edit-hotel")
  edit(item: any) {
    console.log("Item => ", item);
    return item;
  }

  @Emit("get-item-perpage")
  getItemPerPage(item: any) {
    return item;
  }

  @Emit("get-pagination")
  getPagination(page: any) {
    console.log("page", page);
    return page;
  }

  @Emit("edit-hotel-room")
  editHotelRoom(item: any) {
    return item;
  }

  @Emit("delete-item")
  deleteItemConfirm(item: any) {
    return this.deletedItem;
  }

  @Emit("open-delete-dialog")
  deleteItem(item: any) {
    this.deletedItem = item;
  }
}
