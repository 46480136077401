























































/* eslint-disable */
import { Component, Vue } from "vue-property-decorator";

@Component
export default class CustomerService extends Vue {
  private itemsBreadcrumbs: Array<object> = [
    {
      text: 'Home',
      disabled: false,
      href: '/',
    },
    {
      text: 'Customer Support',
      disabled: true,
    },
  ]
  private email = ""
  private id = ""
  private isEdit = false

  created() {
    this.fetchEmailAddress()
  }

  public fetchEmailAddress() {
    this.$api.customerSupport.getCustomerSupport()
      .then((res: any) => {
        this.email = res.customerSupports[0].email
        this.id = res.customerSupports[0].id
        this.isEdit = false
        console.log("Success Get Customer Support => ", res)
      })
      .catch((err: any) => {
        console.error('error: ', err)
      })
  }

  public saveEmail() {
    this.$api.customerSupport.putCustomerSupport(this.id, this.email)
      .then((res: any) => {
        this.isEdit = false
        this.$toast.success(res.statusMessage.toString())
        console.log("Success Update Customer Support => ", res)
      })
      .catch((err: any) => {
        console.error('error: ', err)
      })
  }
}
