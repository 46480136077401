

































/* eslint-disable */

import { Component, Vue } from "vue-property-decorator";
import TableComponent from "@/components/Hotel/Discount/Table.vue"; // @ is an alias to /src
import FilterComponent from "@/components/Hotel/Discount/Filter.vue"; // @ is an alias to /src
import DialogComponent from "@/components/Hotel/Discount/Dialog.vue"; // @ is an alias to /src
import PermissionType from "@/constants/PermissionType.ts";

@Component({
  components: {
    TableComponent,
    FilterComponent,
    DialogComponent,
  },
})
export default class Discount extends Vue {
  private loading: boolean = false;
  private isAddEditDiscount: boolean = false;
  private isDelete: boolean = false;
  private editedDiscount: any = {};
  private optionsTable: any = {
    page: 1,
    itemsPerPage: 5,
  };
  private totalDiscount: number = 0;
  private discounts: any = [];
  private permissions: Array<string> = [];
  private permission: boolean = true;
  private filter: any = "";
  private headers: Array<object> = [
    { text: "Promo Name", value: "promoName", align: "start", sortable: false },
    {
      text: "Discount Type",
      value: "discountType",
      align: "start",
      sortable: false,
    }, //text: 'Normal Price', value: 'price',
    {
      text: "Discount Value",
      value: "discountValue",
      align: "start",
      sortable: false,
    }, //text: 'Discount (Rp)', value: 'discountRp',
    {
      text: "Discount Max",
      value: "discountMax",
      align: "start",
      sortable: false,
    }, //text: 'Discount (%)', value: 'discountPer',
    { text: "Location", value: "location", align: "start", sortable: false },
    { text: "Star Rating", value: "rating", align: "start", sortable: false },
    { text: "Action", value: "action", sortable: false },
  ];

  private mockDiscount: any = [
    {
      name: "Hotel 1",
      price: "650000",
      discountRp: "50000",
      discountPer: "7.5",
      location: "Tangerang, Banten",
      rating: "3",
    },
    {
      name: "Hotel 2",
      price: "650000",
      discountRp: "50000",
      discountPer: "7.5",
      location: "Tangerang, Banten",
      rating: "3",
    },
    {
      name: "Hotel 3",
      price: "650000",
      discountRp: "50000",
      discountPer: "7.5",
      location: "Tangerang, Banten",
      rating: "3",
    },
  ];

  created() {
    const permissions = this.$store.state["modules/auth"].user["permissions"];
    console.log("permissions: ", permissions);
    this.permissions = [...permissions];
    this.checkCanCreateUpdateDeleteDiscount();
    if (this.canViewDiscount) {
      this.fetchDiscountList(this.optionsTable, this.filter);
    }
  }

  public get canViewDiscount() {
    return this.permissions.includes(PermissionType.VIEW_DISCOUNT);
  }

  public fetchDiscountList(options: any, searchh?: any) {
    this.optionsTable = options;
    this.discounts = [];
    this.loading = true;
    const { page, itemsPerPage } = options;

    let location = null;
    let star = null;
    let value = null;
    let validDateFrom = null;
    let validDateTo = null;

    if (searchh != undefined) {
      location = searchh.location;
      star = searchh.rating;
      value = searchh.amount;
      validDateFrom = searchh.validFrom;
      validDateTo = searchh.validTo;
    }

    this.$api.discount
      .getDiscountList(
        page - 1,
        itemsPerPage == -1 ? null : itemsPerPage,
        location,
        star,
        value,
        validDateFrom,
        validDateTo
      )
      .then((res: any) => {
        this.totalDiscount = res.totalItems;
        this.discounts = res.data;
        this.loading = false;
      })
      .catch((err: any) => {
        console.error("error: ", err);
        this.$toast.error(err.statusMessage.toString());
      });
  }

  public applyFilter(filter: object): void {
    this.filter = filter;
    this.optionsTable.page = 1;
    this.fetchDiscountList(this.optionsTable, this.filter);
  }

  public checkCanCreateUpdateDeleteDiscount(): void {
    this.permission =
      this.permissions.includes(PermissionType.CREATE_DISCOUNT) &&
      this.permissions.includes(PermissionType.UPDATE_DISCOUNT) &&
      this.permissions.includes(PermissionType.DELETE_DISCOUNT);
  }

  public getPagination(page: any) {
    this.optionsTable.page = page;
    this.fetchDiscountList(this.optionsTable, this.filter);
  }

  public detailDiscount(item: any) {
    this.isAddEditDiscount = true;
    this.editedDiscount = item;
  }

  public closeAddEditDialog() {
    this.editedDiscount = {};
    this.isAddEditDiscount = false;
  }

  public cancelAddEditDiscount() {
    this.isAddEditDiscount = false;
    this.editedDiscount = {};
  }

  public fetchCreateDiscount(discount: any): void {
    this.$api.discount
      .createDiscountConfig(discount)
      .then((res: any) => {
        this.$toast.success(res.statusMessage);
        this.isAddEditDiscount = false;
        this.editedDiscount = {};
        this.fetchDiscountList(this.optionsTable, this.filter);
        console.log("Success Create Discount: ", res);
      })
      .catch((err: any) => {
        console.error("error: ", err);
        // let status = "";
        if(err.statusMessage)
        {
          // status = err.statusMessage.toString()
          this.$toast.error(err.statusMessage.toString());
        }
        else if (err.message)
        {
          // status = err.statusMessage.toString()
          this.$toast.error(err.message.toString());
        }
        // this.$toast.error(err.statusMessage.toString());
        // this.$toast.error(status);
      });
  }

  public fetchUpdateDiscount(discount: any): void {
    this.$api.discount
      .updateDiscountConfig(discount, discount.id)
      .then((res: any) => {
        this.$toast.success(res.statusMessage);
        this.isAddEditDiscount = false;
        this.editedDiscount = {};
        this.fetchDiscountList(this.optionsTable, this.filter);
        console.log("Success updated Discount: ", res);
      })
      .catch((err: any) => {
        console.error("error: ", err);
        this.$toast.error(err.statusMessage.toString());
      });
  }

  public saveDiscount(item: any) {
    if (item.id) {
      console.log("update discount", item);
      this.fetchUpdateDiscount(item);
    } else {
      console.log("create discount config", item);
      this.fetchCreateDiscount(item);
    }

    // setTimeout(() => {
    //   this.closeAddEditDialog();
    // }, 500);
  }

  public deleteDiscount(item: any) {
    console.log("Delete Discount", item)
    setTimeout(() => {
      this.fetchDeleteDiscount(item.id)
      this.isDelete = false;
    }, 500);
  }

  public fetchDeleteDiscount(id: string): void {
    console.log("id", id)
    this.$api.discount.deleteDiscountConfig(id)
      .then((res: any) => {
        this.$toast.success(res.statusMessage)
        this.isAddEditDiscount = false
        this.fetchDiscountList(this.optionsTable)
        console.log('Success Delete Meal Plan: ', res)
      })
      .catch((err: any) => {
        console.error('error: ', err)
        this.$toast.error(err.statusMessage.toString())
      })
  }
}
