var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',[_c('v-col',[_c('v-row',{staticClass:"summary-title",attrs:{"no-gutters":""}},[_c('h2',{staticClass:"mt-2"},[_vm._v("Bank List")]),_c('v-spacer'),_c('v-btn',{staticClass:"blue accent-4 white--text text-capitalize",staticStyle:{"font-size":"12px"},on:{"click":function($event){return _vm.$emit('add-bank')}}},[_vm._v(" Create Bank ")])],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.bank,"items-per-page":_vm.optionsTable.itemsPerPage,"footer-props":{'items-per-page-options' : [5 ,10, 15, 25, 50, 100]},"server-items-length":_vm.totalBank,"loading":_vm.loading},on:{"update:page":_vm.getPagination,"update:items-per-page":_vm.getItemPerPage},scopedSlots:_vm._u([{key:"item.logo",fn:function(ref){
var item = ref.item;
return [_c('strong',[_c('img',{staticStyle:{"background":"no-repeat","width":"60px","height":"55px","padding":"5%","display":"block","text-indent":"-9999px"},attrs:{"src":item.logo}})])]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticStyle:{"margin-right":"1%"},attrs:{"medium":""},on:{"click":function($event){return _vm.detailItem(item)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-pencil ")])]}}],null,true)},[_c('span',{staticStyle:{"font-size":"12px"}},[_vm._v(" Edit Bank ")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"medium":""},on:{"click":function($event){return _vm.deleteItem(item)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-delete ")])]}}],null,true)},[_c('span',{staticStyle:{"font-size":"12px"}},[_vm._v(" Delete Bank ")])])]}}],null,true)})],1)],1),_c('v-dialog',{attrs:{"max-width":"500px"},model:{value:(_vm.isDelete),callback:function ($$v) {_vm.isDelete=$$v},expression:"isDelete"}},[_c('v-card',[_c('v-card-title',{staticClass:"headline justify-center",staticStyle:{"font-size":"20px !important"}},[_vm._v("Are you sure you want to delete this item?")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{staticStyle:{"font-size":"12px"},attrs:{"color":"blue darken-1","text":""},on:{"click":function($event){return _vm.$emit('close-delete-dialog')}}},[_vm._v("Cancel")]),_c('v-btn',{staticStyle:{"font-size":"12px"},attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.deleteItemConfirm}},[_vm._v("OK")]),_c('v-spacer')],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }