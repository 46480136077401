























import { Vue } from "vue-property-decorator";

export default Vue.extend({
  name: "FilterComponent",
  data: function () {
    return {
      filter: '',
    };
  },
  methods: {
    clicked: function () {
      this.$emit('applyFilter', this.filter)
      // this.applyFilter(this.filter)
      console.log('child', this.filter);
    },
  },
});
