






















/* eslint-disable */
import { hotel } from "@/assets/strings";
import { Component, Emit, Prop, Vue, Watch } from "vue-property-decorator";

@Component
export default class AutoCompleteHotel extends Vue {
  @Prop({ required: true }) readonly hotelProp!: any;
  @Prop({ required: true }) readonly isEdit!: any;

  private hotelOptions: any = [];
  private hotel: any = {};

  private pageSizeHotel: number = 5;
  private pageNumberHotel = 0;
  private pageTotalHotel = 0;
  private searchHotel: string = "";

  created() {
    this.hotel = { ...this.hotelProp };
    this.searchHotel = this.hotel.hotelName.name;
    this.fetchHotel();
  }

  @Watch("hotelProp")
  hotelPropChanged(newVal: any) {
    this.hotel = { ...this.hotelProp };
    this.searchHotel = this.hotel.hotelName? this.hotel.hotelName.name : "";
    this.fetchHotel();
  }
  
  private inputHotel ()
  {
    if (this.hotel.hotelName !== undefined)
    {
      this.hotel.hotelId = this.hotel.hotelName.id;
    }
    this.emitData();
  }

  @Emit("get-hotel-data")
  private emitData() {
    return this.hotel;
  }

  @Watch("searchHotel")
  hotelSearchChanged(newVal: string) {
    if (newVal) {
      this.pageNumberHotel = 0;
      this.fetchHotel();
    }
  }

  fetchHotel() {
    this.paginatedHotel();
  }

  async paginatedHotel() {
    await this.$api.discount
      .getHotel(this.pageNumberHotel, this.pageSizeHotel, this.searchHotel)
      .then((res: any) => {
        this.hotelOptions = res.data;
        this.pageTotalHotel = res.totalPages;
      })
      .catch((err: any) => {
        console.error("error: ", err);
        this.$toast.error(err.statusMessage.toString());
      });
  }

  hasPrevPageHotel() {
    if (this.pageNumberHotel - 1 >= 0) {
      this.pageNumberHotel -= 1;
      this.paginatedHotel();
    }
  }

  hasNextPageHotel() {
    if (this.pageNumberHotel + 1 < this.pageTotalHotel) {
      this.pageNumberHotel += 1;
      this.paginatedHotel();
    }
  }
}
