









































































































































































































































































































































































































/* eslint-disable */
import { Vue } from "vue-property-decorator";
export default Vue.extend({
  name: "TableComponent",
  data: function () {
    return {
      headersFlight: [
        { text: "Order ID", value: "bookings.bookingId", sortable: false},
        { text: "Order / Payment Date", value: "bookings.bookingDate", sortable: false },
        { text: "Passenger Name", value: "bookings.passengerName", sortable: false},
        { text: "Cust. Email", value: "bookings.contactEmail", sortable: false },
        { text: "Cust. Phone", value: "bookings.contactPhone", sortable: false },
        { text: "Order Status", value: "bookings.statusInvoice", sortable: false },
        { text: "Payment Method", value: "bookings.paymentMethod", sortable: false },
        { text: "Price", value: "bookings.totalPrice", sortable: false },
        { text: "Booking Code", value: "bookings.bookingCode", sortable: false },
        { text: "Ticket Status", value: "bookings.statusTransaction", sortable: false },
        { text: "Flight Number", value: "bookings.flightNumber", sortable: false },
        { text: "Airline Name", value: "bookings.airlineName", sortable: false },
        { text: "Flight Route", value: "bookings.flightType", sortable: false },
        { text: "City Origin", value: "bookings.cityOrigin", sortable: false },
        { text: "City Destination", value: "bookings.cityDestination", sortable: false },
        { text: "Expired", value: "bookings.bookingExpired", sortable: false },
      ],
      headersHotel: [
        { text: "Order Date", value: "bookings.bookingDate", sortable: false},
        { text: "Order ID", value: "bookings.bookingId", sortable: false },
        { text: "Guest Name", value: "bookings.guestName", sortable: false},
        { text: "Check In / Out", value: "bookings.checkInOut", sortable: false },
        { text: "Cust. Email", value: "bookings.contactEmail", sortable: false },
        { text: "Cust. Phone", value: "bookings.contactPhone", sortable: false },
        { text: "Order Status", value: "bookings.statusInvoice", sortable: false },
        { text: "Payment Method", value: "bookings.paymentMethod", sortable: false },
        { text: "Price", value: "bookings.totalPrice", sortable: false },
        { text: "Voucher ID", value: "bookings.voucherNo", sortable: false },
        { text: "Voucher Status", value: "bookings.statusTransaction", sortable: false },
      ],
      headersTour: [
        { text: "Order Date", value: "bookings.bookingDate", sortable: false },
        { text: "Order ID", value: "bookings.bookingId", sortable: false},
        { text: "Tour Name", value: "bookings.tourName", sortable: false},
        { text: "Departure Date", value: "bookings.departureDate", sortable: false },
        { text: "Price", value: "bookings.totalPrice", sortable: false },
        { text: "Promo Code", value: "promo.promoCode", sortable: false },
        { text: "Discount Value", value: "bookings.discountAmount", sortable: false },
        { text: "Ticket Status", value: "bookings.statusTransaction", sortable: false },
        { text: "Order Status", value: "bookings.statusInvoice", sortable: false },
        { text: "Order Source", value: "bookings.source", sortable: false },
      ],
      dialog: false,
      dialogData: {
        bookings: {
          bookingDate: '',
          statusInvoice: '',
          statusTransaction: ''
        },
        promo: [],
        refund: {
          requestDate: '',
          refundPaymentDate: ''
        }
      },
      newTicketStatus: '',
      options: {}
    };
  },
  props: {
    isEdit: { required: true },
    loading: { required: true },
    data: { type: Array, required: true },
    optionsTable: { type: Object, required: true},
    totalBooking: { required: true },
    tab: { required: true },
    permission: { type: Boolean, required: true }
  },
  watch: {
    options: {
      handler(newVal: any, oldVal: any) {
        this.fetchData()
      },
      deep: true
    },
    isEdit: {
      handler(newVal: any) {
        if (!newVal) {
          this.dialogData = {
            bookings: {
              bookingDate: '',
              statusInvoice: '',
              statusTransaction: ''
            },
            promo: [],
            refund: {
              requestDate: '',
              refundPaymentDate: ''
            }
          }
        } else {
          this.dialogData.bookings.statusTransaction = this.dialogData.bookings.statusTransaction.toUpperCase()
          this.dialogData.bookings.bookingDate = this.dialogData.bookings.bookingDate.split("T")[0]
          this.dialogData.refund.requestDate = this.dialogData.refund.requestDate ? this.dialogData.refund.requestDate.split("T")[0] : ""
          this.dialogData.refund.refundPaymentDate = this.dialogData.refund.refundPaymentDate ? this.dialogData.refund.refundPaymentDate.split("T")[0] : ""
          this.dialogData.bookings.statusInvoice = this.dialogData.bookings.statusInvoice ? this.dialogData.bookings.statusInvoice.toUpperCase() : ""
        }
      }
    }
  },
  methods: {
    async toBookingDetail(data: any) {
      await this.$store.commit('modules/booking/setBookingOrder', data);
      await this.$store.commit('modules/booking/setProductType', this.tab == 0 ? 'Flight' : this.tab == 1 ? 'Hotel' : 'Tour');
      await this.$router.push('/booking-order-detail');
    },
    fetchData: function () {
      this.$emit('fetchData', this.options)
    },
    getPaymentDate(date: any) {
      if (date) {
        return ` / ${date.replace('T', ' ').replace('.000Z', '')}`
      } else {
        return '';
      }
    },
    clickedRow: function (item: any) {
        this.dialogData.bookings = {...item.bookings}
        this.dialogData.promo = item.promo ? {...item.promo} : null
        this.dialogData.refund = {...item.refund}
        this.newTicketStatus = item.bookings.status
        this.$emit('actionUser', item)
    },
    saveNewTicketStatus: function (value: string) {
      if (value !== 'cancel') {
        this.$emit('changeStatus', this.dialogData)
      } {
        this.$emit('changeStatus', 'cancel')
      }
    },
    resendEticket: function(id: any) {
      console.log("Resend to transactionId ==>", id)
      this.$api.booking.resendEticket(id)
        .then((res: any) => {
          this.$toast.success('Success to resend e-ticket')
          this.$emit('closeModal')
      })
      
    }
  },
});
