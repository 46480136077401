























































































































































































































































/* eslint-disable */
import { Component, Emit, Prop, Vue, Watch } from "vue-property-decorator";
import VueSweetalert2 from 'vue-sweetalert2';

// If you don't need the styles, do not connect
import 'sweetalert2/dist/sweetalert2.min.css';

Vue.use(VueSweetalert2);

@Component
export default class Table extends Vue {
  @Prop({ required: true, type: Array }) readonly items!: [];
  @Prop({ required: true }) readonly loading!: boolean;
  @Prop({ required: true }) readonly optionsTable!: any;
  @Prop({ required: true }) readonly totalPromos!: boolean;
  @Prop({ required: true }) readonly isDetail!: boolean;
  @Prop({ required: true }) readonly tab!: number;
  @Prop({ required: true }) readonly permission!: boolean;

  private deletedItem: string = "";
  private isEdit: boolean = false;
  private isDelete: boolean = false;
  private options: any = {};
  private optionsTab = 0;
  private itemType = "";
  private isLoading: boolean = false;
  private page: number = 0;

  private isValid = false

  private selectedItem: any = {
    promoCode: "",
    promoName: "",
    promoDescription: "",
    startDate: null,
    endDate: "",
    quota: "",
    type: "",
    percentageDiscount: "",
    minimumTransaction: "",
    maximumDiscount: "",
    amount: "",
    isOncePerUser: false,
    isForEmployee: false
  }

  private fixItem = {
    promoCode: "",
    promoName: "",
    promoDescription: "",
    startDate: "",
    endDate: "",
    quota: "",
    type: "",
    percentageDiscount: "",
    minimumTransaction: "",
    maximumDiscount: "",
    amount: "",
    isOncePerUser: false,
    isForEmployee: false
  }

  private headers = [
    { text: "Promo Code", value: "promoCode", sortable: false },
    { text: "Promo Name", value: "promoName", sortable: false },
    { text: "Promo Description", value: "promoDescription", sortable: false },
    { text: "Start Date", value: "startDate", sortable: false },
    { text: "End Date", value: "endDate", sortable: false },
    { text: "Quota", value: "quota", sortable: false },
    { text: "Usage Count", value: "usageCount", sortable: false },
    { text: "Action", value: "action", sortable: false }
  ];

  created() {
    if (!this.permission) {
      this.headers.pop();
    }
    this.optionsTab = this.tab;
  }

  @Watch("options")
  optionsChanged(newVal: boolean) {
    if (this.optionsTab === this.tab) {
      this.fetchData();
    }
  }

  @Watch("tab")
  tabChanged(newVal: number) {
    this.optionsTab = newVal;
  }

  @Watch("isDetail")
  isDetailChanged(newVal: boolean) {
    if (newVal) {
      if (this.selectedItem.startDate !== undefined && this.selectedItem.endDate !== undefined)
      {
        this.selectedItem.startDate = this.selectedItem.startDate.split('T')[0]
        this.selectedItem.endDate = this.selectedItem.endDate.split('T')[0]
      }
    } else {
      this.selectedItem = {...this.fixItem}
      this.selectedItem.isOncePerUser = false
      this.selectedItem.isForEmployee = false
      this.reset()
    }
  }
  @Watch("selectedItem.startDate")
  alertDate(val: any){
    if(val && !this.isEdit){
      if(val < new Date().toISOString().slice(0, 10)){
        this.$swal.fire({
          text: 'Are you sure to choose this date?',
          icon: 'info',
          showDenyButton: true,
          denyButtonText: 'No',
          confirmButtonText: 'Yes',
        }).then((result) => {
          if (result.isDenied) {
            this.selectedItem.startDate = null;
            this.$swal.fire('You not choose this date!', '', 'error')
          }
        })
      }
    }
  }

  @Emit("changeTable")
  public fetchData() {
    return this.options;
  }

  @Emit("close-dialog")
  public closeDialog() {
    this.selectedItem = { ...this.fixItem };
    this.selectedItem.startDate = null;
    this.isEdit = true;
  }

  @Emit("open-dialog")
  public selectedRow(item: object) {
    this.isEdit = true
    this.selectedItem = {...item}
    this.selectedItem.minimumTransaction = this.selectedItem.minimumTransaction.slice(0, -2)
    this.selectedItem.minimumTransaction = this.selectedItem.minimumTransaction.replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ".");

    if (this.selectedItem.amount !== null)
    {
      this.selectedItem.amount = this.selectedItem.amount.slice(0, -2)
      this.selectedItem.amount = this.selectedItem.amount.replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    }

    if (this.selectedItem.maximumDiscount !== null)
    {
      this.selectedItem.maximumDiscount = this.selectedItem.maximumDiscount.slice(0, -2)
      this.selectedItem.maximumDiscount = this.selectedItem.maximumDiscount.replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    }


    // this.selectedItem.type = this.selectedItem.type === "percentage" ? "Percentage" : "Amount"
    if (this.selectedItem.type === "percentage")
    {
      this.selectedItem.type = "Percentage"
    }
    else if (this.selectedItem.type === "amount") 
    {
      this.selectedItem.type = "Amount"
    }
  }

  @Emit("createUpdatePromo")
  public savePromo() {
    this.selectedItem.minimumTransaction = this.selectedItem.minimumTransaction.split('.').join("");

    if (this.selectedItem.amount !== null)
    {
      this.selectedItem.amount = this.selectedItem.amount.split('.').join("");
    }

    if (this.selectedItem.maximumDiscount !== null) 
    {
      this.selectedItem.maximumDiscount = this.selectedItem.maximumDiscount.split('.').join("");
    }
    
    return this.selectedItem
  }

  @Emit("close-dialog")
  public deleteItem(item: any) {
    this.deletedItem = item.id;
    this.isDelete = true;
    this.isEdit = true;
    this.selectedItem.startDate = null;
  }

  @Emit("delete-promo")
  public deleteItemConfirm() {
    console.log("deletedItem --> ", this.deletedItem);
    this.isDelete = false;
    return this.deletedItem;
  }

  private requiredRule (message: string): any
  {
    return [(v: any) => !!v || `${message} is required`]
  }

  private commasAmount() {
    this.selectedItem.amount = this.selectedItem.amount.replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    }
  private commasMinimum() {
    this.selectedItem.minimumTransaction = this.selectedItem.minimumTransaction.replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ".");
  }
  private commasMaximum() {
    this.selectedItem.maximumDiscount = this.selectedItem.maximumDiscount.replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ".");
  }

  private filterPromo() {
        this.isLoading = true;
        this.$api.promo
        .getPromoList(0, 10000, "")
        .then((res: any) => {
            this.isLoading = false;
            this.selectedItem = res.data;
            let filter = this.selectedItem.find((el: any) => el.promoName === this.selectedItem.promoName
            || el.promoCode === this.selectedItem.promoCode);
            if (filter) {
                this.$toast.success("This name already exist");
            }
            else {
                this.savePromo();
            }
        })
        .catch((err: any) => {
            this.isLoading = false;
            this.$toast.error(err.statusMessage.toString())
        });
    }

  private validate () {
      if ((this.$refs.form as Vue & { validate: () => boolean }).validate()) {
      this.filterPromo();
      }
  }

  private reset () {
    this.$nextTick(() => {
      (this.$refs.form as Vue & { reset: () => boolean }).reset();
    })
  }

  private checkPercentage(event: any) {
    this.selectedItem.percentageDiscount = this.selectedItem.percentageDiscount.replace(/[^0-9]/g, '');
    if (parseInt(this.selectedItem.percentageDiscount + event.key) > 100) {
      this.selectedItem.percentageDiscount = this.selectedItem.percentageDiscount.replace(/\D/g, '')
      console.log('bla', event.key)
      event.preventDefault()
    } else {
      return true
    }
  }

  private startDateCondition() {
    let date = new Date();
    date.setDate(date.getDate() - 7);
    return date.toISOString().slice(0, 10);
  }
  private resetEndDate() {
    this.isEdit = false
    this.selectedItem.endDate = "";
  }
}
