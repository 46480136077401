import axios from "axios"
import url from './Url'

/**
 * Get Meal Plan List
 * @param pageNumber number
 * @param pageSize number
 * @param search string
 */
export const getRoomTypeList = async (pageNumber: number, pageSize: number, search?: string) => {
  const qs = {
    pageNumber,
    pageSize,
    search
  }

  try {
    console.log("Room Type Url => ", url.roomType.ROOM_TYPE)
    const result = await axios.get(url.roomType.ROOM_TYPE, { params: qs})
    return result.data
  } catch (error) {
    return Promise.reject(error)
  }
}

/**
 * Create Room Type
 * @param request object
    * @param name string
    * @param size string 
    * @param maxOccupancy integer
    * @param maxAdults integer
    * @param maxChild integer
    * @param isSmookingAllowed boolean
    * @param roomDescription string
 */
export const createRoomType = async (request: object) => {
  
  try {
    const result = await axios.post(url.roomType.ROOM_TYPE, request)
    return result.data
  } catch (error) {
    return Promise.reject(error)
  }
}

/**
 * Update Meal Plan
 * @param request object
 * @param id: string
 */

export const updateRoomType = async (id: string, request: object) => {
  try {
    const urlEdit = `${url.roomType.ROOM_TYPE}?id=${id}`
    const result = await axios.put(urlEdit, request)
    return result.data
  } catch (error) {
    return Promise.reject(error)
  }
}

/**
 * Delete Meal Plan
 * @param id: string
 */

 export const deleteRoomType = async (id: string) => {
  try {
    const urlDelete = `${url.roomType.ROOM_TYPE}?id=${id}`
    const result = await axios.delete(urlDelete)
    return result.data
  } catch (error) {
    return Promise.reject(error)
  }

 }