








































































































/* eslint-disable */

import { Component, Emit, Prop, Vue, Watch } from "vue-property-decorator";

@Component
export default class Dialog extends Vue {
  @Prop({required: true}) readonly isAddEdit!: boolean
  @Prop({required: true}) readonly editedRoomType!: any

  private roomType: any = {}
  private isValid = false

  @Watch('isAddEdit')
  isAddRoomTypeChanged(newVal: boolean) {
    this.roomType = {...this.editedRoomType}
    console.log(this.roomType)
    this.roomType.maxOccupancy = this.roomType.maxOccupancy != undefined ? this.roomType.maxOccupancy.toString() : ""
    this.roomType.maxAdults = this.roomType.maxAdults != undefined ? this.roomType.maxAdults.toString() : ""
    this.roomType.maxChild = this.roomType.maxChild != undefined ? this.roomType.maxChild.toString() : ""

    if (!this.roomType.id)
    {
      this.reset()
    }
  }

  @Emit('save-roomType')
  saveAddedRoomType() {
    return this.roomType
  }

  public isNumber(event: any) {
    if (!isNaN(event.key) || event.key === "Delete" || event.key === "Backspace" || event.key.includes("Arrow")) {
      return true
    } else {
      event.preventDefault()
    }
  }
  
  private requiredRule (message: string): any
  {
    return [(v: any) => !!v || `${message} is required`]
  }

  private validate () {
    (this.$refs.form as Vue & { validate: () => boolean }).validate()
  }

  private reset () {
    this.$nextTick(() => {
      (this.$refs.form as Vue & { reset: () => boolean }).reset();
    })
  }
}
