import axios from "axios"
import url from './Url'

export const createHotel = async (request: object) => {
  try {
    const result = await axios.post(url.hotel.HOTEL, request)
    return result.data
  } catch (error) {
    return Promise.reject(error)
  }
}

export const updateHotel = async (id: string, request: object) => {
  try {
    const result = await axios.put(`${url.hotel.HOTEL}?id=${id}`, request)
    return result.data
  } catch (error) {
    return Promise.reject(error)
  }
}

export const deleteHotel = async (id: string) => {
  try {
    const urlDelete = `${url.hotel.HOTEL}?id=${id}`
    const result = await axios.delete(urlDelete)
    return result.data
  } catch (error) {
    return Promise.reject(error)
  }
}

export const updateRoom = async (id: string, request: object) => {
  try {
    const data = {
      roomTypes: request
    }
    const result = await axios.put(`${url.hotel.HOTEL}/room?id=${id}`, data)
    return result.data
  } catch (error) {
    return Promise.reject(error)
  }
}

export const getHotelList = async (pageNumber: number, pageSize: number, search?: string) => {
  const qs = {
    pageNumber,
    pageSize,
    search
  }

  try {
    const result = await axios.get(url.hotel.HOTEL, { params: qs })
    return result.data
  } catch (error) {
    return Promise.reject(error)
  }
}

export const getTopTenHotelList = async (pageNumber: number, pageSize: number,) => {
  const qs = {
    pageNumber,
    pageSize,
  }

  try {
    const result = await axios.get(url.hotel.HOTEL + '/popular', { params: qs })
    return result.data
  } catch (error) {
    return Promise.reject(error)
  }
}

export const updateHotelPopular = async (id: string, request: object) => {
  try {
    const result = await axios.put(`${url.hotel.HOTEL}/popular?id=${id}`, request)
    return result.data
  } catch (error) {
    return Promise.reject(error)
  }

}

export const getAllHotel = async (pageNumber: number, pageSize: number, search?: string) => {
  let urlString;
  if (search) {
    urlString = `${url.hotel.HOTEL}/all?pageNumber=${pageNumber}&pageSearch=${pageSize}&search=${search}`
  } else {
    urlString = `${url.hotel.HOTEL}/all?pageNumber=${pageNumber}&pageSearch=${pageSize}`;
  }
  try {
    const result = await axios.get(urlString)
    return result.data;
  } catch (err) {
    return Promise.reject(err)
  }
}

export const getAllFeature = async () => {
  try {
    const result = await axios.get(`${url.hotel.HOTEL}/feature`)
    return result.data
  } catch (error) {
    return Promise.reject(error)
  }
}


export const getAllFeatureRoom = async () => {
  try {
    const result = await axios.get(`${url.hotel.HOTEL}/feature-room`)
    return result.data
  } catch (error) {
    return Promise.reject(error)
  }
}



export const getAllTagHotel = async () => {
  try {
    const result = await axios.get(`${url.hotel.HOTEL}/tag-hotel`)
    return result.data
  } catch (error) {
    return Promise.reject(error)
  }
}

export const getAllTagImage = async () => {
  try {
    const result = await axios.get(`${url.hotel.HOTEL}/tag-image`)
    return result.data
  } catch (error) {
    return Promise.reject(error)
  }
}

export const getAllRoomTypes = async (page: number, size: number, search: string) => {
  try {
    let urlWeb: string;
    if (search) {
      urlWeb = `${url.hotel.HOTEL}/room-type?pageNumber=${page}&pageSize=${size}&search=${search}`
    } else {
      urlWeb = `${url.hotel.HOTEL}/room-type?pageNumber=${page}&pageSize=${size}`
    }
    const result = await axios.get(urlWeb)
    return result.data
  } catch (error) {
    return Promise.reject(error)
  }
}

export const getAllMealPlans = async (search: string) => {
  try {
    let urlWeb: string;
    if (search) {
      urlWeb = `${url.mealPlan.MEALPLAN}?search=${search}`
    } else {
      urlWeb = `${url.mealPlan.MEALPLAN}`
    }
    const result = await axios.get(urlWeb)
    return result.data
  } catch (error) {
    return Promise.reject(error)
  }
}


