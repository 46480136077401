

































































/* eslint-disable */
import { Component, Emit, Prop, Vue, Watch } from "vue-property-decorator";

@Component
export default class ChildPolicyFormComponent extends Vue {
  @Prop({required: true}) readonly dialogState!: boolean
  @Prop({required: true}) readonly editedChildPolicy!: Array<any>

  private childPolicies: any = []

  @Watch("dialogState")
  private dialogStateChanged(newVal: boolean) {
    if (newVal) {
      this.childPolicies = [...this.editedChildPolicy]
    } else {
      this.childPolicies = []
    }
  }

  private saveChildPolicy() {
    console.log(this.childPolicies)
  }

  private addChildPolicy() {
    this.childPolicies.push({
      minAge: "",
      maxAge: ""
    })
  }

  private deleteChildPolicy(index: number) {
    console.log(index)
    this.childPolicies.splice(index, 1)
  }
}
