






















import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class Summary extends Vue {
  @Prop({required: true, type: Number}) readonly totalCustomers!: number
}
