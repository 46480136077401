



























































import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class SummaryComponent extends Vue {
  @Prop({ required: true, type: String }) readonly totalTransaction!: string;
  @Prop({ required: true, type: String }) readonly totalFinalPrice!: string;
  @Prop({ required: true, type: String }) readonly totalCoupon!: string;
  @Prop({ required: true, type: String }) readonly totalDiscount!: string;

  public generateThosandSeperator(price: string): string {
    const numParts = price.split(".");
    numParts[0] = numParts[0]
      .replace(/\D/g, "")
      .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    const joinNumber = numParts.join(".");
    return joinNumber;
  }
}
