import axios from "axios"
import url from './Url'

/**
 * Get Booking History List By Product Type
 * @param pageNumber number
 * @param pageSize number
 * @param productType string
 */
export const getBookingHistoryListByProductType = async (pageNumber: number, pageSize: number, productType: string) => {
    const qs = {
        pageNumber: pageNumber,
        pageSize: pageSize,
        productType: productType
    }

    try {
        const result = await axios.get(url.booking.GET_BOOKING_HISTORY_LIST, { params: qs })
        return result.data
    } catch(error) {
        return Promise.reject(error)
    } 
}


/**
 * Get Booking History List By Product Type and Additional Params
 * @param pageNumber number
 * @param pageSize number
 * @param productType string
 * @param additionalParams object
    * @param startBookingDate string
    * @param endBookingDate string
    * @param bookingCode string
    * @param statusTransaction string
    * @param statusInvoice string
 */
export const getBookingHistoryListByProductTypeAndParams = async (pageNumber: number, pageSize: number, productType: string, additionalParams: object) => {
    const qs = {
        pageNumber: pageNumber,
        pageSize: pageSize,
        productType: productType
    }

    const combinedQueryStrings = { ...qs, ...additionalParams}

    try {
        const result = await axios.get(url.booking.GET_BOOKING_HISTORY_LIST, { params: combinedQueryStrings })
        return result.data
    } catch(error) {
        return Promise.reject(error)
    } 
}

export const getBookingHistoryListByProductTypeAndParamsExcel = async (pageNumber: number, pageSize: number, productType: string, additionalParams: object) => {
    const qs = {
        pageNumber: pageNumber,
        pageSize: pageSize,
        productType: productType
    }

    const combinedQueryStrings = { ...qs, ...additionalParams}

    try {
        const result = await axios.get(url.booking.GET_BOOKING_HISTORY_LIST, { params: combinedQueryStrings })
        return result.data
    } catch(error) {
        return Promise.reject(error)
    } 
}

/**
 * Get Booking History Detail
 * @param bookingCode string
 */
export const getBookingHistoryDetail = async (bookingCode: string) => {
    const qs = {
        bookingCode: bookingCode
    }

    try {
        const result = await axios.get(url.booking.GET_BOOKING_HISTORY_DETAIL, { params: qs })
        return result.data
    } catch(error) {
        return Promise.reject(error)
    } 
}


export const getSummaryBooking = async (type: string) => {
    const qs = {
        type: type
    }

    try {
        const result = await axios.get(`${url.booking.GET_BOOKING_HISTORY_LIST}/summary`, {params: qs})
        return result.data
    } catch(error) {
        return Promise.reject(error)
    }
}

/**
 * Cancel Booking
 * @param bookingCode string
 * @param request object
 */
export const cancelBooking = async (bookingCode: string, request: object) => {
    const qs = {
        bookingCode: bookingCode
    }

    try {
        const result = await axios.put(url.booking.UPDATE_CANCEL_BOOKING, request, { params: qs })
        return result.data
    } catch(error) {
        return Promise.reject(error)
    }
}

/**
 * Update Status Booking
 * @param bookingId string
 * @param item object
    * @param statusTransaction string
    * @param statusInvoice string
 */
export const updateStatusBooking = async (bookingId: string, item: object) => {
    const qs = {
        id: bookingId
    }
    
    try {
        const result = await axios.put(url.booking.UPDATE_STATUS_BOOKING, item, { params: qs })
        return result.data
    } catch(error) {
        return Promise.reject(error)
    }
}

/**
 * Update Status Booking
 * @param request object
    * @param invoiceId string
    * @param status string
 */
 export const updateStatusRefund = async (request: object) => {
    
    try {
        const result = await axios.put(url.booking.UPDATE_STATUS_REFUND, request)
        return result.data
    } catch(error) {
        return Promise.reject(error)
    }
}

/**
 * Resend Eticket Booking
 * @param transactionId string
 */
 export const resendEticket = async (transactionId: string) => {
    try {
        const result = await axios.post(`${url.resend.RESEND}?transactionId=${transactionId}`)
        return result.data
    } catch(error) {
        return Promise.reject(error)
    }
}