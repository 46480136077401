import axios from "axios"
import url from './Url'

/**
 * Get Customer Support
 */
export const getCustomerSupport = async () => {
    try {
        const result = await axios.get(url.customerSupport)
        return result.data
    } catch(err) {
        return err
    }
}

/**
 * Put Customer Support
 */
export const putCustomerSupport = async (id: number, email: string) => {
  const data = {
    id,
    email,
  }

  try {
      const result = await axios.put(url.customerSupport, data)
      return result.data
  } catch(err) {
      return err
  }
}