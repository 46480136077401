
























































/* eslint-disable */

import { Component, Vue, Watch } from "vue-property-decorator";
import TableComponent from "@/components/Hotel/HotelManagement/Table.vue"; // @ is an alias to /src
import FilterComponent from "@/components/Hotel/HotelManagement/Filter.vue"; // @ is an alias to /src
import FormComponent from "@/components/Hotel/HotelManagement/FormCreate.vue"; // @ is an alias to /src
import FormEditComponent from "@/components/Hotel/HotelManagement/Hotel/FormEdit.vue"; // @ is an alias to /src
import FormEditRoomComponent from "@/components/Hotel/HotelManagement/HotelRoom/FormEditRoom.vue"; // @ is an alias to /src
// import PermissionType from "@/constants/PermissionType.ts";

@Component({
  components: {
    TableComponent,
    FilterComponent,
    FormComponent,
    FormEditComponent,
    FormEditRoomComponent,
  },
})
export default class HotelManagement extends Vue {
  private isCreate = false;
  private isEditHotel = false;
  private isEditRoom = false;
  private loading: boolean = false;
  private isDelete: boolean = false;
  private optionsTable: any = {
    page: 1,
    itemsPerPage: 5,
  };
  private totalHotel: number = 0;
  private hotels: any = [];
  private headers: Array<object> = [
    { text: "Name", value: "name", align: "start", sortable: false },
    { text: "Action", value: "action", sortable: false },
  ];
  private editedHotelRoom: any = null;
  private editedHotel: any = null;
  private filter: any = "";
  private featureList: any[] = [];
  private featureListRoom: any[] = [];
  private tagHotelList: any[] = [];
  private tagHotelImageList: any[] = [];
  private roomTypesList: any[] = [];
  private mealPlanList: any[] = [];

  created() {
    // this.hotels = this.mockHotel.hotels;
    // this.totalHotel = 1;
    this.fetchHotelList(this.optionsTable, this.filter);
    this.fetchFeatureList();
    this.fetchFeatureRoomList();
    this.fetchTagHotel();
    this.fetchTagImage();
    this.getAllRoomTypes();
    this.getAllMealPlan();
  }
  
  public getItemPerPage(item: any) {
    this.optionsTable.itemsPerPage = item;
    this.fetchHotelList(this.optionsTable, this.filter);
  }

  public getPagination(page: any) {
    this.optionsTable.page = page;
    this.fetchHotelList(this.optionsTable, this.filter);
  }

  private deleteHotel(item: any) {
    setTimeout(() => {
      this.fetchDeleteHotel(item.id);
      this.isDelete = false;
    }, 500);
  }

  private fetchDeleteHotel(id: string): void {
    console.log("id", id);
    this.$api.hotel
      .deleteHotel(id)
      .then((res: any) => {
        this.$toast.success(res.statusMessage);
        this.fetchHotelList(this.optionsTable, this.filter);
        console.log("Success Delete Meal Plan: ", res);
      })
      .catch((err: any) => {
        console.error("error: ", err);
        this.$toast.error(err.statusMessage.toString());
      });
  }

  private fetchFeatureList() {
    this.loading = true;
    this.$api.hotel
      .getAllFeature()
      .then((res: any) => {
        console.log("data", res.feature);
        let data = res.feature.map((x: any) => {
          let item = {
            value: x.id,
            text: x.name,
          };
          return item;
        });
        const filteredArr = data.reduce((acc: any, current: any) => {
          const x = acc.find((item: any) => item.text === current.text);
          if (!x) {
            return acc.concat([current]);
          } else {
            return acc;
          }
        }, []);
        this.featureList = filteredArr;
      })
      .catch((err: any) => {
        console.error("error: ", err);
        this.$toast.error(err.statusMessage.toString());
      });
  }

  private fetchFeatureRoomList() {
    this.loading = true;
    this.$api.hotel
      .getAllFeatureRoom()
      .then((res: any) => {
        console.log("data", res.feature);
        let data = res.feature.map((x: any) => {
          let item = {
            value: x.id,
            text: x.name,
          };
          return item;
        });
        this.featureListRoom = data;
      })
      .catch((err: any) => {
        console.error("error: ", err);
        this.$toast.error(err.statusMessage.toString());
      });
  }

  private fetchTagHotel() {
    this.loading = true;
    this.$api.hotel
      .getAllTagHotel()
      .then((res: any) => {
        console.log("data", res.hotelTag);
        let filterTagHotel = res.hotelTag.filter((el: any) =>{
          let filter = (el.deletedAt === null);
          return filter;
        });
        
        let data = filterTagHotel.map((x: any) => {
          let item = {
            value: x.id,
            text: x.tagName,
          };
          return item;
        });
        this.tagHotelList = data;
      })
      .catch((err: any) => {
        console.error("error: ", err);
        this.$toast.error(err.statusMessage.toString());
      });
  }

  private fetchTagImage() {
    this.loading = true;
    this.$api.hotel
      .getAllTagImage()
      .then((res: any) => {
        console.log("data", res.hotelImage);
        let filterImage = res.hotelImage.filter((el: any) =>{
            let filter = (el.deletedAt === null);
            return filter;
        });

        let data = filterImage.map((x: any) => {
            let item = {
            value: x.id,
            text: x.tagName,
            };
            return item;
        });
        this.tagHotelImageList = data;
      })
      .catch((err: any) => {
        console.error("error: ", err);
        this.$toast.error(err.statusMessage.toString());
      });
  }

  private getAllRoomTypes() {
    this.loading = true;
    this.$api.hotel
      .getAllRoomTypes(0, 20, "")
      .then((res: any) => {
        console.log("data", res.data);
        let data = res.data.map((x: any) => {
          let item = {
            value: x.id,
            text: `${x.name} ${x.size}`
          };
          return item;
        });
        this.roomTypesList = data;
      })
      .catch((err: any) => {
        console.error("error: ", err);
        this.$toast.error(err.statusMessage.toString());  
      });
  }

  private getRoomList(value: any) {
    console.log("get room list", value);
    this.loading = true;
    this.$api.hotel
      .getAllRoomTypes(value)
      .then((res: any) => {
        console.log("data", res.roomTypes.data);
        let data = res.roomTypes.data.map((x: any) => {
          let item = {
            value: x.id,
            text: x.name,
          };
          return item;
        });
        this.roomTypesList = data;
      })
      .catch((err: any) => {
        console.error("error: ", err);
        this.$toast.error(err.statusMessage.toString());
      });
  }

  private getMealList(value: any) {
    console.log("get meal list", value);
    this.loading = true;
    this.$api.hotel
      .getAllMealPlans(value)
      .then((res: any) => {
        console.log("data", res.mealPlan.data);
        let data = res.mealPlan.data.map((x: any) => {
          let item = {
            value: x.id,
            text: x.mealPlanName,
          };
          return item;
        });
        this.mealPlanList = data;
      })
      .catch((err: any) => {
        console.error("error: ", err);
        this.$toast.error(err.statusMessage.toString());
      });
  }

  private getAllMealPlan() {
    this.loading = true;
    this.$api.hotel
      .getAllMealPlans()
      .then((res: any) => {
        console.log("data", res.data);
        let data = res.data.map((x: any) => {
          let item = {
            value: x.id,
            text: x.mealPlanName,
          };
          return item;
        });
        this.mealPlanList = data;
      })
      .catch((err: any) => {
        console.error("error: ", err);
        this.$toast.error(err.statusMessage.toString());
      });
  }

  private editHotelRoom(item: any) {
    let hotel: any = {};
    let roomType: any[] = [];
    hotel["hotelId"] = item.id;
    if (item.hotelRooms.length > 0) {
      item.hotelRooms.forEach((x: any) => {
        let feature: any[] = [];
        let roomPrice: any[] = [];
        let hotelAvabilityRooms: any[] = [];
        x.roomFeatures.forEach((ft: any) => {
          feature.push(ft.featureId);
        });
        if (x.hotelAvailabilityRooms.length > 0) {
          x.hotelAvailabilityRooms.forEach((room: any) => {
            let item = {
              id: room.id,
              hotelRoomId: room.hotelRoomId,
              startDate: room.startDate,
              endDate: room.endDate,
              totalRoomAvailable: room.totalRoomAvailable,
              isDelete: false,
              deletedBy: "delete",
            };
            hotelAvabilityRooms.push(item);
          });
        } else {
          let item = {
            hotelRoomId: x.id,
            startDate: "",
            endDate: "",
            totalRoomAvailable: "",
            isDelete: false,
            deletedBy: "delete",
          };
          hotelAvabilityRooms.push(item);
        }
        let cancelationPolicyTemp: any[] = [];
        if (x.roomPrices.length > 0) {
          x.roomPrices.forEach((pr: any) => {
            pr.cancelationPolicies.forEach((cp: any) => {
              let item = {
                id: cp.id,
                roomPriceId: cp.roomPriceId,
                fromDate: cp.fromDate,
                toDate: cp.toDate,
                amount: cp.amount,
                b2bMarkup: cp.b2bMarkup,
                grossAmount: cp.grossAmount,
                night: cp.night,
                percent: cp.percent,
                noShow: cp.noShow,
                RoomPriceId: cp.RoomPriceId,
                isDelete: false,
                deletedBy: "delete",
              };
              cancelationPolicyTemp.push(item);
            });
            let priceRoom = {
              id: pr.id,
              hotelRoomId: x.id,
              price: pr.price.replace(".00", "").replace(/\B(?=(\d{3})+(?!\d))/g, "."),
              mealPlanListId: pr.mealPlanListId,
              packageRate: pr.packageRate,
              availFlag: pr.availFlag,
              canAmend: pr.canAmend,
              cancelationPolicyType: pr.cancelationPolicyType,
              canHold: pr.canHold,
              cancelationPolicy: cancelationPolicyTemp,
              isDelete: false,
              deletedBy: "delete",
            };
            roomPrice.push(priceRoom);
          });
        } else {
           let item = {
                fromDate: "",
                toDate: "",
                amount: "",
                b2bMarkup: "",
                grossAmount: "" ,
                night: "",
                percent:"",
                noShow: "",
                RoomPriceId:"",
                isDelete: false,
                deletedBy: "delete",
              };
              cancelationPolicyTemp.push(item);
            
            let priceRoom = {
              hotelRoomId: x.id,
              price:"",
              mealPlanListId: "",
              packageRate: "",
              availFlag: "",
              canAmend:"",
              cancelationPolicyType: "",
              canHold: "",
              cancelationPolicy: cancelationPolicyTemp,
              isDelete: false,
              deletedBy: "delete",
            };
            roomPrice.push(priceRoom);

        }

        let room = {
          id: x.id,
          roomTypeId: x.roomTypeId,
          typeName: x.roomType.name,
          feature: feature,
          hotelAvabilityRoom: hotelAvabilityRooms,
          roomPrice: roomPrice,
          isDelete: false,
          deletedBy: "delete",
        };
        roomType.push(room);
      });
    } else {
      let room = {
        id: null,
        rommTypeId: null,
        typeName: "",
        feature: [],
        hotelAvabilityRoom: [
          {
            startDate: "",
            endDate: "",
            totalRoomAvailable: "",
            isDelete: false,
            deletedBy: "delete",
          },
        ],
        roomPrice: [
          {
            id: null,
            hotelRoomId: null,
            price: "",
            mealPlanListId: "",
            packageRate: "",
            availFlag: "",
            canAmend: "",
            cancelationPolicyType: "",
            canHold: "",
            cancelationPolicy: [
              {
                id: null,
                roomPriceId: null,
                fromDate: "",
                toDate: "",
                amount: "",
                b2bMarkup: "",
                grossAmount: "",
                night: "",
                percent: "",
                noShow: "",
                RoomPriceId: "",
                isDelete: false,
                deletedBy: "delete",
              },
            ],
            isDelete: false,
            deletedBy: "delete",
          },
        ],
        isDelete: false,
        deletedBy: "delete",
      };

      roomType.push(room);
    }

    hotel["roomTypes"] = roomType;
    console.log("hotel room type", hotel);
    this.editedHotelRoom = hotel;
    this.isEditRoom = true;
  }

  private editHotel(item: any) {
    console.log("item", item);
    let hotel: any = {
      hotelId: item.id,
      name: item.name,
      address: item.address,
      city: item.city,
      headline: item.headline,
      description: item.description,
      star: item.star,
      phone: item.phone,
      longitude: item.longitude,
      latitude: item.latitude,
      sourceHotel: "antavaya",
      isRecommended: item.isRecommended,
      isBestBuy: item.isBestBuy,
      isSuperDeal: item.isSuperDeal,
      country: item.country,
    };

    let features: any[] = [];
    item.hotelFeatures.forEach((x: any) => {
      features.push(x.featureId);
    });
    hotel["feature"] = features;
    hotel["featureTemp"] = features;

    let hotelTags: any[] = [];
    item.hotelTags.forEach((x: any) => {
      hotelTags.push(x.tagHotelId);
    });
    hotel["tagHotel"] = hotelTags;
    hotel["tagHotelTemp"] = hotelTags;

    let areaAroundHotels: any[] = [];
    item.areaAroundHotels.forEach((x: any) => {
      let item = {
        id: x.id,
        description: x.description,
        distanceInMeters: x.distanceInMeters,
        placeName: x.placeName,
        isDelete: false,
      };
      areaAroundHotels.push(item);
    });
    hotel["areaAroundHotel"] = areaAroundHotels;

    let childPolicies: any[] = [];
    item.childPolicies.forEach((x: any) => {
      let item = {
        id: x.id,
        minAge: x.minAge,
        maxAge: x.maxAge,
        isDelete: false,
      };
      childPolicies.push(item);
    });
    hotel["childPolicy"] = childPolicies;

    let tagHotelmage: any[] = [];
    let hotelImage: any[] = [];
    item.hotelImages.forEach((x: any) => {
      let image = {
        id: x.id,
        url: x.url,
        description: x.description,
        isMain: x.isMain,
        tagId: x.tagId,
        isDelete: false,
      };
      hotelImage.push(image);
    });
    item.hotelImages.forEach((x: any) => {
      let hotelImages = hotelImage.filter((img) => img.tagId === x.tagId);
      let item = {
        tagId: x.tagId,
        hotelImage: hotelImages,
        isDelete: false,
      };
      tagHotelmage.push(item);
    });
    tagHotelmage = this.removeDuplicate(tagHotelmage, "tagId");
    hotel["tagHotelImage"] = tagHotelmage;

    this.editedHotel = hotel;
    console.log("edited hotel", this.editedHotel);
    this.isEditHotel = true;
  }

  private removeDuplicate(data: any[], key: any) {
    var check = new Set();
    return data.filter(
      (obj: any) => !check.has(obj[key]) && check.add(obj[key])
    );
  }

  private saveHotel(item: any) {
    console.log("save hotel", item);
    this.fetchUpdateHotel(item.hotelId, item);
    this.isEditHotel = false;
  }

  private saveRoom(item: any) {
    console.log("save room", item);
    this.fetchUpdateRoom(item.hotelId, item.roomTypes);
    this.isEditRoom = false;
  }

  private mockHotel: any = {
    statusCode: 200,
    statusMessage: "OK",
    hotels: [
      {
        id: "nacsdjdnsjd",
        hotelFitruumsId: 152905,
        destinationId: 11726,
        resortId: 16034,
        transfer: 0,
        notes: null,
        types: "hotel",
        name: "Bagues",
        address: "La Rambla, 105 08002 Barcelona Spain",
        headline: "This boutique hotel is bcscbsd",
        description:
          "Majestic is a 5 star hotel located on Paseo de Gracia in the centre of Barcelona, within walking distance from Plaza Catalunya and Las Ramblas. Hotel facilities include reception 24h, 2 restaurants, bar, pool bar, outdoor  pool, sauna, steam bath, massage, gym, business center and parking. The guest rooms have bathroom/shower, hair dryer, mini bar, safe, telephone, internet, cable/satellite TV, work desk and air conditioning.",
        Address: "Paseo De Gracia 68, 08007 Barcelona, Spain",
        phone: "6217227",
        resort: "Barcelona",
        destination: "Barcelona",
        city: "Barcelona",
        country: "Spain",
        location: "Barcelona",
        star: "5",
        isBestBuy: false,
        isSuperDeal: false,
        longitude: 106.09227,
        latitude: 3833638,
        sourceHotel: "antavaya",
        isRecommended: true,
        tagHotel: ["Clean"],
        hotelImages: [
          {
            id: "04951b0e-50ab-4ad0-a513-296db475319e",
            fitruumsImageId: null,
            hotelId: "02250d3a-c232-4841-bc90-7a77eab41ce2",
            fullSizeImageUrl: "asdasdasd",
            smallSizeImageUrl: "asdhafushdf",
            description: "Desasikdjgasvjkuhdfahsgdfv",
            createdAt: "2021-06-17T21:41:32.000Z",
            createdBy: "1bc0abfd-4c0e-48e6-a68c-b28352f9c9d0",
            updatedAt: "2021-06-17T21:41:32.000Z",
            updatedBy: null,
            deletedAt: null,
            deletedBy: null,
            url: "ahjksdfghjasfdhjasdasdasdagsfdhkagsd",
            isMain: false,
            HotelId: "02250d3a-c232-4841-bc90-7a77eab41ce2",
            tagHotelImage: {
              id: "123",
              tagName: "Exterior",
              createdAt: null,
              createdBy: null,
              updatedAt: null,
              updatedBy: null,
              deletedAt: null,
              deletedBy: null,
            },
          },
        ],
        areaAroundHotels: [
          {
            id: "eb82c100-6910-4c4e-b57c-8958456da2a7",
            hotelId: "02250d3a-c232-4841-bc90-7a77eab41ce2",
            description: "AA3",
            distanceInMeters: "20",
            placeName: "KAasdasA",
            createdAt: "2021-06-17T21:41:32.000Z",
            createdBy: "1bc0abfd-4c0e-48e6-a68c-b28352f9c9d0",
            updatedAt: "2021-06-17T21:41:32.000Z",
            updatedBy: null,
            deletedAt: null,
            deletedBy: null,
            HotelId: "02250d3a-c232-4841-bc90-7a77eab41ce2",
          },
        ],
        childPolicies: [
          {
            id: "c07b6f71-481f-4fe1-af4e-331d1c927448",
            hotelId: "02250d3a-c232-4841-bc90-7a77eab41ce2",
            minAge: 20,
            maxAge: 60,
            createdAt: "2021-06-17T21:41:32.000Z",
            createdBy: "1bc0abfd-4c0e-48e6-a68c-b28352f9c9d0",
            updatedAt: "2021-06-17T21:41:32.000Z",
            updatedBy: null,
            deletedAt: null,
            deletedBy: null,
            HotelId: "02250d3a-c232-4841-bc90-7a77eab41ce2",
          },
        ],
        hotelRooms: [
          {
            id: "8d6c3922-13da-4dd1-87d7-020eb949b3c6",
            hotelId: "02250d3a-c232-4841-bc90-7a77eab41ce2",
            roomTypeId: "0007bd2d-273d-481c-a56a-7b61d50edf78",
            createdAt: "2021-06-17T21:41:32.000Z",
            createdBy: "1bc0abfd-4c0e-48e6-a68c-b28352f9c9d0",
            updatedAt: "2021-06-17T21:41:32.000Z",
            updatedBy: null,
            deletedAt: null,
            deletedBy: null,
            HotelId: "02250d3a-c232-4841-bc90-7a77eab41ce2",
            RoomTypeId: "0007bd2d-273d-481c-a56a-7b61d50edf78",
            roomType: {
              id: "0007bd2d-273d-481c-a56a-7b61d50edf78",
              name: "EXECUTIVE",
              vendorId: "RM_236_0",
              source: "Mg Jarvis",
              size: "24.00 Sq.m.",
              maxOccupancy: 1,
              maxAdults: 1,
              maxChild: 0,
              isSmookingAllowed: false,
              roomDescription:
                "Suitable for a 1-person occupancy, this 28-sqm room is air-conditioned and is fitted with a comfortable single bed. Featuring a cable TV and a minibar, the room also comes with a seating area. Guest have access to the private bathroom that comes with a shower and free toiletries. Free WiFi is available.",
              createdAt: "2021-04-13T08:36:07.000Z",
              createdBy: null,
              updatedAt: null,
              updatedBy: null,
              deletedAt: null,
              deletedBy: null,
            },
            Features: [
              {
                id: "0063f51b-20fe-4832-91e3-bf5553086e1b",
                name: "Has 24 Hour Front Desk",
                fitruumsFeatureId: null,
                createdAt: "2021-04-13T04:55:39.000Z",
                createdBy: null,
                updatedAt: null,
                updatedBy: null,
                deletedAt: null,
                deletedBy: null,
                imageUrl:
                  "https://storage.googleapis.com/antavaya-images/24-hours.png",
              },
              {
                id: "006788ed-a166-48a7-9b04-8052e67a3796",
                name: "Gluten Free Meals",
                fitruumsFeatureId: null,
                createdAt: "2021-04-13T04:55:40.000Z",
                createdBy: null,
                updatedAt: null,
                updatedBy: null,
                deletedAt: null,
                deletedBy: null,
                imageUrl: null,
              },
            ],
            roomPrices: [
              {
                id: "db610c8a-64c5-4f96-a3e8-96a43804c0f3",
                price: "1000000.00",
                hotelRoomId: "8d6c3922-13da-4dd1-87d7-020eb949b3c6",
                mealPlanListId: "1",
                packageRate: false,
                cancelationPolicyType: "bla bla",
                availFlag: true,
                canAmend: true,
                canHold: true,
                createdAt: "2021-06-17T21:41:32.000Z",
                createdBy: "1bc0abfd-4c0e-48e6-a68c-b28352f9c9d0",
                updatedAt: "2021-06-17T21:41:32.000Z",
                updatedBy: null,
                deletedAt: null,
                deletedBy: null,
                HotelRoomId: "8d6c3922-13da-4dd1-87d7-020eb949b3c6",
                mealPlan: {
                  id: "3094c697-ad09-4758-ab08-db75d0da22b6",
                  mealPlanCode: "ALAC",
                  mealPlanName: "A La Carte",
                  source: "Mg Jarvis",
                  createdAt: "2021-03-25T07:54:44.000Z",
                  createdBy: null,
                  updatedAt: "2021-03-25T07:54:44.000Z",
                  updatedBy: null,
                  deletedAt: null,
                  deletedBy: null,
                },
                cancelationPolicies: [
                  {
                    id: "43ae50ec-7dbe-4428-abf3-5c2f80b2653c",
                    createdAt: "2021-06-17T21:41:32.000Z",
                    createdBy: "1bc0abfd-4c0e-48e6-a68c-b28352f9c9d0",
                    updatedAt: "2021-06-17T21:41:32.000Z",
                    updatedBy: null,
                    deletedAt: null,
                    deletedBy: null,
                    roomPriceId: "db610c8a-64c5-4f96-a3e8-96a43804c0f3",
                    fromDate: "asdhnvajshdvajsd",
                    toDate: "akmsbdnvahbsvmnbvxzmbcv",
                    amount: "asdhnvajshdvajsd",
                    b2bMarkup: "akmsbdnvahbsvmnbvxzmbcv",
                    grossAmount: "asdhnvajshdvajsd",
                    night: "akmsbdnvahbsvmnbvxzmbcv",
                    percent: "asdhnvajshdvajsd",
                    noShow: false,
                    RoomPriceId: "db610c8a-64c5-4f96-a3e8-96a43804c0f3",
                  },
                ],
              },
            ],
          },
        ],
        feature: [
          {
            id: "0063f51b-20fe-4832-91e3-bf5553086e1b",
            name: "Has 24 Hour Front Desk",
            fitruumsFeatureId: null,
            createdAt: "2021-04-13T04:55:39.000Z",
            createdBy: null,
            updatedAt: null,
            updatedBy: null,
            deletedAt: null,
            deletedBy: null,
            imageUrl:
              "https://storage.googleapis.com/antavaya-images/24-hours.png",
          },
          {
            id: "006788ed-a166-48a7-9b04-8052e67a3796",
            name: "Gluten Free Meals",
            fitruumsFeatureId: null,
            createdAt: "2021-04-13T04:55:40.000Z",
            createdBy: null,
            updatedAt: null,
            updatedBy: null,
            deletedAt: null,
            deletedBy: null,
            imageUrl: null,
          },
        ],
      },
    ],
  };

  public fetchHotelList(options: any, searchh: string) {
    this.optionsTable = options;
    this.hotels = [];
    this.loading = true;
    console.log("Option => ", options);
    const { page, search, itemsPerPage } = options;

    this.$api.hotel
      .getHotelList(page - 1, itemsPerPage, searchh)
      .then((res: any) => {
        console.log("data", res.data);
        this.totalHotel = res.totalItems;
        this.hotels = res.data;
        this.loading = false;
        console.log("Success get Hotels => ", res);
      })
      .catch((err: any) => {
        console.error("error: ", err);
        this.$toast.error(err.statusMessage.toString());
      });
  }

  public applyFilter(filter: object): void {
    // call api from backend to apply filter
    this.filter = filter;
    console.log(this.filter);
    this.optionsTable.page = 1;
    this.fetchHotelList(this.optionsTable, this.filter);
  }

  public saveHotelAndRoom(item: any) {
    console.log("create item", item);
    this.fetchCreateHotel(item);
  }

  public fetchUpdateHotel(id: string, hotel: any): void {
    console.log("id", id);
    this.$api.hotel
      .updateHotel(id, hotel)
      .then((res: any) => {
        this.$toast.success(res.statusMessage);
        this.isEditHotel = false;
        this.fetchHotelList(this.optionsTable, this.filter);
        console.log("Success updated Hotel: ", res);
      })
      .catch((err: any) => {
        console.error("error: ", err);
        this.$toast.error(err.statusMessage.toString());
      });
  }

  public fetchUpdateRoom(id: string, hotelRooms: any): void {
    console.log("id", id);
    this.$api.hotel
      .updateRoom(id, hotelRooms)
      .then((res: any) => {
        this.$toast.success(res.statusMessage || res.message);
        this.fetchHotelList(this.optionsTable, this.filter);
        console.log("Success updated Hotel: ", res);
      })
      .catch((err: any) => {
        console.error("error: ", err);
        this.$toast.error(err.statusMessage.toString());
      });
  }

  public fetchCreateHotel(hotel: any): void {
    console.log("hotel req", JSON.stringify(hotel));
    this.$api.hotel
      .createHotel(hotel)
      .then((res: any) => {
        this.$toast.success(res.statusMessage || res.message);
        this.isCreate = false;
        this.fetchHotelList(this.optionsTable, this.filter);
        console.log("Success Create Hotel: ", res);
      })
      .catch((err: any) => {
        console.error("error: ", err);
        this.$toast.error(err.statusMessage.toString());
      });
  }

  public showFormAdd() {
    this.isCreate = true;

    // Re-GET API data when hit button "Add Hotel"
    this.fetchFeatureList();
    this.fetchFeatureRoomList();
    this.fetchTagHotel();
    this.fetchTagImage();
    this.getAllRoomTypes();
    this.getAllMealPlan();
    this.loading = false;
  }
}
