






























































































































































/* eslint-disable */

import { Component, Emit, Prop, Vue, Watch } from "vue-property-decorator";

@Component
export default class Dialog extends Vue {
  @Prop({ required: true }) readonly isAddEditAvability!: boolean;
  @Prop({ required: true }) readonly editedMealPlan!: any;

  private avabilityRoom: any = {
    hotelId: "",
    hotelRoom: [
      {
        hotelRoomId: "",
        startDate: "",
        endDate: "",
        totalAvabilityRoom: "",
      },
    ],
  };

  private isValid = false;

  private hotelList = [];
  private hotelRoomList = [];

  @Watch("isAddEdit")
  isAddtagChanged(newVal: boolean) {
    this.avabilityRoom = { ...this.editedMealPlan };
    if (!this.editedMealPlan.id) {
      this.reset();
    }
  }

  @Emit("save-mealPlan")
  saveAddedMealPlan() {
    return this.avabilityRoom;
  }

  private addHotelRoom(index: string) {
    this.avabilityRoom.hotelRoom.push({
      hotelRoomId: "",
      startDate: "",
      endDate: "",
      totalAvabilityRoom: "",
    });
  }

  private deleteHotelRoom(index: string) {
    this.avabilityRoom.hotelRoom.splice(index, 1);
  }

  private requiredRule(message: string): any {
    return [(v: any) => !!v || `${message} is required`];
  }

  private validate() {
    (this.$refs.form as Vue & { validate: () => boolean }).validate();
  }

  private reset() {
    this.$nextTick(() => {
      (this.$refs.form as Vue & { reset: () => boolean }).reset();
    });
  }

  private startDateCondition() {
    let date = new Date();
    return date.toISOString().slice(0, 10);
  }
  private resetEndDate(i: any) {
    this.avabilityRoom.hotelRoom[i].endDate = "";
  }
  
}
