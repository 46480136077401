
















































/* eslint-disable */
import { Component, Vue } from "vue-property-decorator";
import SearchComponent from "@/components/Customer/Search.vue"; // @ is an alias to /src
import TableComponent from "@/components/Customer/Table.vue"; // @ is an alias to /src
import SummaryComponent from "@/components/Customer/Summary.vue"; // @ is an alias to /src
import PermissionType from "@/constants/PermissionType.ts";


@Component({
  components: {
    SearchComponent,
    SummaryComponent,
    TableComponent
  },
})

// @Component
export default class Customer extends Vue {
  private itemsBreadcrumbs: Array<object> = [
    {
      text: 'Home',
      disabled: false,
      href: '/',
    },
    {
      text: 'Customer Management',
      disabled: true,
    },
  ]
  private totalSummaryCustomers = 0
  private isLoading = false
  private customerList: Array<object> = []
  private totalCustomers = 0
  private optionsTable: any = {
    page: 1,
    itemsPerPage: 10,
  };
  private searchFilter = {
    name: null,
    email: null
  }
  private headers = [
    { text: 'Id', value: 'id', align: 'start', sortable: false },
    { text: 'Email', value: 'email', sortable: false },
    { text: 'Phone Number', value: 'phoneNumber', sortable: false },
    { text: 'Title', value: 'title', sortable: false },
    { text: 'Identity Card', value: 'identityCard', sortable: false },
    { text: 'First Name', value: 'firstName', sortable: false },
    { text: 'Last Name', value: 'lastName', sortable: false },
    { text: 'Address', value: 'address', sortable: false },
    { text: 'Date of Birth', value: 'dob', sortable: false },
    { text: 'Age', value: 'age', sortable: false },
    { text: 'Created At', value: 'createdAt', sortable: false },
    { text: 'Action', value: 'action', sortable: false },
  ]
  private tableRowItems = [
    { id: 111, email: 'yusup@code.id', phoneNumber: '082123456789', title: 'Mr', identityCard: '20994209384', firstName: 'yusup', lastName: 'code', address: 'jln bungur 2', dob: '2020-11-25', age: 20, createdAt: '2020-11-17' },
    { id: 112, email: 'yusup@code.id', phoneNumber: '082123456789', title: 'Mr', identityCard: '20994209384', firstName: 'yusup', lastName: 'code', address: 'jln bungur 2', dob: '2020-11-25', age: 20, createdAt: '2020-11-17' },
    { id: 113, email: 'yusup@code.id', phoneNumber: '082123456789', title: 'Mr', identityCard: '20994209384', firstName: 'yusup', lastName: 'code', address: 'jln bungur 2', dob: '2020-11-25', age: 20, createdAt: '2020-11-17' },
    { id: 114, email: 'yusup@code.id', phoneNumber: '082123456789', title: 'Mr', identityCard: '20994209384', firstName: 'yusup', lastName: 'code', address: 'jln bungur 2', dob: '2020-11-25', age: 20, createdAt: '2020-11-17' },
    { id: 115, email: 'yusup@code.id', phoneNumber: '082123456789', title: 'Mr', identityCard: '20994209384', firstName: 'yusup', lastName: 'code', address: 'jln bungur 2', dob: '2020-11-25', age: 20, createdAt: '2020-11-17' },
    { id: 116, email: 'yusup@code.id', phoneNumber: '082123456789', title: 'Mr', identityCard: '20994209384', firstName: 'yusup', lastName: 'code', address: 'jln bungur 2', dob: '2020-11-25', age: 20, createdAt: '2020-11-17' },
    { id: 117, email: 'yusup@code.id', phoneNumber: '082123456789', title: 'Mr', identityCard: '20994209384', firstName: 'yusup', lastName: 'code', address: 'jln bungur 2', dob: '2020-11-25', age: 20, createdAt: '2020-11-17' },
    { id: 118, email: 'yusup@code.id', phoneNumber: '082123456789', title: 'Mr', identityCard: '20994209384', firstName: 'yusup', lastName: 'code', address: 'jln bungur 2', dob: '2020-11-25', age: 20, createdAt: '2020-11-17' },
    { id: 119, email: 'yusup@code.id', phoneNumber: '082123456789', title: 'Mr', identityCard: '20994209384', firstName: 'yusup', lastName: 'code', address: 'jln bungur 2', dob: '2020-11-25', age: 20, createdAt: '2020-11-17' },
    { id: 120, email: 'yusup@code.id', phoneNumber: '082123456789', title: 'Mr', identityCard: '20994209384', firstName: 'yusup', lastName: 'code', address: 'jln bungur 2', dob: '2020-11-25', age: 20, createdAt: '2020-11-17' },
    { id: 121, email: 'yusup@code.id', phoneNumber: '082123456789', title: 'Mr', identityCard: '20994209384', firstName: 'yusup', lastName: 'code', address: 'jln bungur 2', dob: '2020-11-25', age: 20, createdAt: '2020-11-17' },
    { id: 122, email: 'yusup@code.id', phoneNumber: '082123456789', title: 'Mr', identityCard: '20994209384', firstName: 'yusup', lastName: 'code', address: 'jln bungur 2', dob: '2020-11-25', age: 20, createdAt: '2020-11-17' },
    { id: 123, email: 'yusup@code.id', phoneNumber: '082123456789', title: 'Mr', identityCard: '20994209384', firstName: 'yusup', lastName: 'code', address: 'jln bungur 2', dob: '2020-11-25', age: 20, createdAt: '2020-11-17' },
    { id: 124, email: 'yusup@code.id', phoneNumber: '082123456789', title: 'Mr', identityCard: '20994209384', firstName: 'yusup', lastName: 'code', address: 'jln bungur 2', dob: '2020-11-25', age: 20, createdAt: '2020-11-17' },
    { id: 125, email: 'yusup@code.id', phoneNumber: '082123456789', title: 'Mr', identityCard: '20994209384', firstName: 'yusup', lastName: 'code', address: 'jln bungur 2', dob: '2020-11-25', age: 20, createdAt: '2020-11-17' },
    { id: 126, email: 'yusup@code.id', phoneNumber: '082123456789', title: 'Mr', identityCard: '20994209384', firstName: 'yusup', lastName: 'code', address: 'jln bungur 2', dob: '2020-11-25', age: 20, createdAt: '2020-11-17' },
    { id: 127, email: 'yusup@code.id', phoneNumber: '082123456789', title: 'Mr', identityCard: '20994209384', firstName: 'yusup', lastName: 'code', address: 'jln bungur 2', dob: '2020-11-25', age: 20, createdAt: '2020-11-17' },
    { id: 128, email: 'yusup@code.id', phoneNumber: '082123456789', title: 'Mr', identityCard: '20994209384', firstName: 'yusup', lastName: 'code', address: 'jln bungur 2', dob: '2020-11-25', age: 20, createdAt: '2020-11-17' },
    { id: 129, email: 'yusup@code.id', phoneNumber: '082123456789', title: 'Mr', identityCard: '20994209384', firstName: 'yusup', lastName: 'code', address: 'jln bungur 2', dob: '2020-11-25', age: 20, createdAt: '2020-11-17' },
    { id: 130, email: 'yusup@code.id', phoneNumber: '082123456789', title: 'Mr', identityCard: '20994209384', firstName: 'yusup', lastName: 'code', address: 'jln bungur 2', dob: '2020-11-25', age: 20, createdAt: '2020-11-17' },
  ]

  private permissions: Array<string> = [];
  private dialog: boolean = true;

  created() {
    const permissions = this.$store.state['modules/auth'].user['permissions']
    this.permissions = [...permissions]
  }

  public get canViewCustomer() {
    return this.permissions.includes(PermissionType.VIEW_CUSTOMER)
  }

  public get canUpdateCustomer() {
    return this.permissions.includes(PermissionType.UPDATE_CUSTOMER)
  }

  public fetchCustomerList(options: any) {
    this.optionsTable = options
    this.customerList = []
    this.isLoading = true
    const { sortBy, sortDesc, page, itemsPerPage } = options;

    this.$api.customer.getCustomerList(page - 1, itemsPerPage, this.searchFilter.name, this.searchFilter.email)
      .then((res: any) => {
        this.totalCustomers = res.totalItems
        this.customerList = res.data
        if (this.totalSummaryCustomers === 0) this.totalSummaryCustomers = res.totalItems
        this.isLoading = false
        console.log('Success get customers => ', res)
      })
      .catch((err: any) => {
        console.error('error: ', err)
      })
  }

  public applySearch(param: any) {
    console.log('parent: ', param)
    this.searchFilter = param
    this.fetchCustomerList(this.optionsTable);
  }

  public updateCustomer(param: any) {
    console.log('parent: ', param)
    this.$api.customer.updateCustomer(param.id, param)
      .then((res: any) => {
        // console.log('Success update Customer => ', res)
        // this.$toast.success(res.message.toString())
        // this.fetchCustomerList(this.optionsTable)
        if(res.statusCode == 200)
        {
          this.$toast.success(res.message.toString())
          this.fetchCustomerList(this.optionsTable)
          this.dialog = false;
          console.log(this.dialog)
        }
        else{
          this.$toast.error(res.message.toString())
        }
      })
      .catch((err: any) => {
        console.error('error: ', err)
      })
  }

  public updateDialog(dialog: boolean)
  {
    this.dialog = dialog
  }
}
