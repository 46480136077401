var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',{staticClass:"summary-title",attrs:{"no-gutters":""}},[_c('h2',[_vm._v("User")]),_c('v-spacer'),(_vm.permission)?_c('v-btn',{staticClass:"blue accent-4 white--text text-capitalize",staticStyle:{"font-size":"12px"},on:{"click":function($event){return _vm.actionUser('create')}}},[_vm._v(" Create User ")]):_vm._e()],1),_c('v-data-table',{staticClass:"table-footer",attrs:{"headers":_vm.headers,"items":_vm.data,"options":_vm.options,"items-per-page":_vm.optionsTable.itemsPerPage,"footer-props":{'items-per-page-options' : [5 ,10, 15, 25, 50, 100]},"server-items-length":_vm.totalUser,"loading":_vm.loading},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.roleName",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$strings.user.role.find(function (el) { return el.value === item.roleName; }).text)+" ")]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticStyle:{"margin-right":"1%"},attrs:{"medium":""},on:{"click":function($event){return _vm.actionUser(item)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-pencil ")])]}}],null,true)},[_c('span',{staticStyle:{"font-size":"12px"}},[_vm._v(" Detail User ")])])]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }