import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import Dashboard from '../views/Dashboard/Dashboard.vue'
import Login from '../views/Login/Login.vue'
import BookingManagementOrder from '../views/BookingManagement/Order.vue'
import BookingManagementSales from '../views/BookingManagement/Sales.vue'
import OrderDetail from '../views/BookingOrderDetail/OrderDetail.vue'
import HotelManagement from '../views/HotelManagement/Hotel.vue'
import Customer from '../views/CustomerManagement/Customer.vue'
import Promo from '../views/Promo/Promo.vue'
import PromoBanner from '../views/PromoBanner/PromoBanner.vue'
import BankManagement from '../views/BankManagement/Bank.vue'
import UserManagement from '../views/UserManagement/User.vue'
import AuditTrail from '../views/AuditTrail/AuditTrail.vue'
import CustomerSupport from '../views/CustomerSupport/CustomerSupport.vue'
import { authGuard } from './guards/AuthGuard'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/',
    name: 'Dashboard',
    beforeEnter: authGuard,
    component: Dashboard
  },
  {
    path: '/booking-management-order',
    name: 'BookingManagementOrder',
    beforeEnter: authGuard,
    component: BookingManagementOrder
  },
  {
    path: '/booking-management-sales',
    name: 'BookingManagementSales',
    beforeEnter: authGuard,
    component: BookingManagementSales
  },
  {
    path: '/booking-order-detail',
    name: 'OrderDetail',
    beforeEnter: authGuard,
    component: OrderDetail
  },
  {
    path: '/customer-management',
    name: 'CustomerManagement',
    beforeEnter: authGuard,
    component: Customer
  },
  {
    path: '/voucher-discount',
    name: 'Promo',
    beforeEnter: authGuard,
    component: Promo
  },
  {
    // path: '/landing-page-promo',
    path: '/banner',
    name: 'PromoBanner',
    beforeEnter: authGuard,
    component: PromoBanner
  },
  {
    path: '/bank-management',
    name: 'BankManagement',
    beforeEnter: authGuard,
    component: BankManagement
  },
  {
    path: '/user-management',
    name: 'UserManagement',
    beforeEnter: authGuard,
    component: UserManagement
  },
  {
    path: '/audit-trail',
    name: 'AuditTrail',
    beforeEnter: authGuard,
    component: AuditTrail
  },
  {
    path: '/hotel-management',
    name: 'HotelManagement',
    beforeEnter: authGuard,
    component: HotelManagement
  },
  {
    path: '/customer-support',
    name: "CustomerSupport",
    beforeEnter: authGuard,
    component: CustomerSupport
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
