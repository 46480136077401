





















































import {
    Component,
    Vue
  } from "vue-property-decorator";
@Component({})
export default class ProductPurchase extends Vue {
    get productType() {
        return this.$store.state['modules/booking'].productType;
    }

    get bookingDetail() {
        return this.$store.state['modules/booking'].bookingOrder;
    }

    getTitle(str: string) {
        const name = str.split(' ');
        return name[0].toUpperCase();
    }

    getPieces(condition: string, str: string) {
        const name = str.split(' ');
        if (condition === 'first') {
            if (name[0].toUpperCase() === 'MR' || name[0].toUpperCase() === 'MS' || name[0].toUpperCase() === 'MRS') {
                return name[1];
            } else {
                return name[0];
            }
        } else {
            return name[name.length - 1];
        }
    }
}
