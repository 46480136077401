
























































































































/* eslint-disable */

import { Component, Emit, Prop, Vue, Watch } from "vue-property-decorator";

@Component
export default class Dialog extends Vue {
  @Prop({required: true}) readonly isAddEdit!: boolean
  @Prop({required: true}) readonly editedFeature!: any

  private feature: any = {}
  private categoryFeatureSearch: string = ""
  private loading: boolean = false
  private debounce: any = null
  private categoryFeatures: any = [
    {
      id: "1",
      categoryName: "Category Name 1"
    },
    {
      id: "2",
      categoryName: "Category Name 2"
    },
    {
      id: "3",
      categoryName: "Category Name 3"
    }
  ]

  private isValid = false;

  @Watch('isAddEdit')
  isAddtagChanged(newVal: boolean) {
    this.feature = {...this.editedFeature}
    // this.categoryFeatureSearch = this.feature.categoryFeature ? this.feature.categoryFeature.name : ""
    this.categoryFeatureSearch = this.feature.categoryName ? this.feature.categoryName : ""

    const categoryFeature = {
      id: this.feature.categoryFeatureId,
      categoryName: this.feature.categoryName
    }

    this.feature.categoryFeature = categoryFeature;
    this.fetchCategory(this.categoryFeatureSearch);

    if (!this.editedFeature.id)
    {
      this.reset()
    }
  }

  @Watch('categoryFeatureSearch')
  categoryFeatureSearchChanged(newVal: string) {
    if (!this.loading) { // && newVal
      this.loading = true;
      clearTimeout(this.debounce);
      this.debounce = setTimeout(() => {
        this.fetchCategory(
          this.categoryFeatureSearch
        );
      }, 500);
    }
  }

  @Emit('save-feature')
  saveAddedFeature() {
    return this.feature
  }

  fetchCategory(search: string) {
    setTimeout(async () => {
      await this.$api.feature.getCategoryFeatureList(search)
        .then((res: any) => {
          this.categoryFeatures = res.data
          this.loading = false
        })
        .catch((err: any) => {
          console.error('error: ', err)
          this.$toast.error(err.statusMessage.toString())
        })
      this.loading = false
    }, 500);
  }

  private requiredRule (message: string): any
  {
    return [(v: any) => !!v || `${message} is required`]
  }

  private validate () {
    (this.$refs.form as Vue & { validate: () => boolean }).validate()
  }

  private reset () {
    this.$nextTick(() => {
      (this.$refs.form as Vue & { reset: () => boolean }).reset();
    })
  }
}
