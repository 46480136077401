























































// /* eslint-disable */

import { Component, Vue } from "vue-property-decorator";
import TopHotel from "./TopHotel.vue"
import TagHotel from "./TagHotel.vue"
import TagImage from "./TagImage.vue"
import Discount from "./Discount.vue"
import RoomType from "./RoomType.vue"
import Feature from "./Feature.vue"
import MealPlan from "./MealPlan.vue"
import HotelManagement from "./HotelManagement.vue"
import HotelAvailaibiltyRoom from "./HotelAvailaibiltyRoom.vue"
import PermissionType from "@/constants/PermissionType.ts";

@Component({
  components: {
    TopHotel,
    TagHotel,
    TagImage,
    Discount,
    RoomType,
    Feature,
    MealPlan,
    HotelManagement,
    HotelAvailaibiltyRoom
  }
})

export default class Hotel extends Vue {
  private itemsBreadcrumbs: Array<object> = [
    {
      text: "Home",
      disabled: false,
      href: "/",
    },
    {
      text: "Hotel Management",
      disabled: true,
    },
  ];
  private tabItems: Array<string> = ["Hotel", "Top 10 Hotel", "Discount Hotel", "Tags Hotel", "Tags Image", "Room Type", "Feature", "Meal Plan"]
  private tab = 0
  private permissions: Array<string> = [];
  private permission = true;

  public get canViewHotel() {
    const permissionsNew = this.$store.state["modules/auth"].user["permissions"];
    console.log("permissions: ", permissionsNew);
    this.permissions = [...permissionsNew];
    return this.permissions.includes(PermissionType.VIEW_HOTEL)
  }

}
