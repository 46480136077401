/* eslint-disable */
const bookingAuditTrailUrl = process.env.VUE_APP_BOOKING_AUDIT_TRAIL_API_URL
const promoAuditTrailUrl = process.env.VUE_APP_PROMO_AUDIT_TRAIL_API_URL
const identityAuditTrailUrl = process.env.VUE_APP_IDENTITY_AUDIT_TRAIL_API_URL
const authCMSUrl = process.env.VUE_APP_CMS_AUTH_API_URL
const promoBannerUrl = process.env.VUE_APP_PROMO_BANNER_API_URL
const baseUrl = process.env.VUE_APP_URL
const bookingUrl = process.env.VUE_APP_BOOKING_API_URL
const userUrl = process.env.VUE_APP_USER_API_URL
const customerUrl = process.env.VUE_APP_CUSTOMER_API_URL
const promoUrl = process.env.VUE_APP_PROMO_API_URL
const imageUrl = process.env.VUE_APP_IMAGE_API_URL
const customerSupportUrl = process.env.VUE_APP_CUSTOMER_SUPPORT_API_URL
const mealPlanUrl = process.env.VUE_APP_MEALPLAN_API_URL
const roomTypeUrl = process.env.VUE_APP_ROOMTYPE_API_URL
const featureUrl = process.env.VUE_APP_FEATURE_API_URL
const discountConfigUrl = process.env.VUE_APP_DISCOUNT_CONFIG_API_URL
const cityUrl = process.env.VUE_APP_CITY_API_URL
const hotelUrl = process.env.VUE_APP_HOTEL_API_URL
const discountRoomTypeUrl = process.env.VUE_APP_DISOCUNT_ROOMTYPE_API_URL
const hotelRoomForDiscountUrl = process.env.VUE_APP_HOTEL_ROOM_FOR_DISCOUNT_API_URL
const bankUrl = process.env.VUE_APP_BANK_API_URL
const resendUrl = process.env.VUE_APP_RESEND_ETICKET_API_URL

export default {
    auditTrail: {
        GET_BOOKING_AUDIT_TRAIL_LIST: bookingAuditTrailUrl + "/booking/audits",
        GET_PROMO_AUDIT_TRAIL_LIST: promoAuditTrailUrl + "/promo/audits",
        GET_IDENTITY_AUDIT_TRAIL_LIST: identityAuditTrailUrl + "/identity/audits",
    },
    promoBanner: {
        GET_PROMO_BANNER_LIST: promoBannerUrl + "/admin",
        POST_PROMO_BANNER_CREATE: promoBannerUrl + "/admin",
        PUT_PROMO_BANNER_UPDATE: promoBannerUrl + "/admin",
        DELETE_PROMO_BANNER: promoBannerUrl + "/delete/admin",
        GET_PROMO_BANNER_DETAIL: promoBannerUrl + "/detail"
    },
    booking: {
        GET_BOOKING_HISTORY_LIST: bookingUrl + "/bookings",
        UPDATE_STATUS_BOOKING: bookingUrl + "/bookings",
        GET_BOOKING_HISTORY_DETAIL: bookingUrl + "/history/detail",
        UPDATE_CANCEL_BOOKING: bookingUrl + "/canceled",
        UPDATE_STATUS_REFUND: bookingUrl + "/booking/refund"
    },
    user: {
        USER: userUrl + "/admin",
        USER_RESET_PASSWORD: userUrl + "/admin/reset"
    },
    customer: {
        GET_CUSTOMER_LIST: customerUrl + "/admin",
        UPDATE_CUSTOMER: customerUrl + "/admin"
    },
    auth: {
        POST_LOGIN: authCMSUrl + "/login/admin"
    },
    promo: {
        PROMO: promoUrl + "/admin",
        PROMO_ASC: promoUrl + "/admin/asc",
        DELETE_PROMO: promoUrl + "/delete/admin"
    },
    image: {
        UPLOAD_IMAGE: imageUrl + "/upload" 
    },
    mealPlan: {
        MEALPLAN: mealPlanUrl + "/mealPlan"
    },
    tagHotel: {
        TAG_HOTEL: mealPlanUrl + "/tag-hotel"
    },
    roomType: {
        ROOM_TYPE: roomTypeUrl + "/roomType"
    },
    feature: {
        FEATURE: featureUrl + "/feature",
        CATEGORY_FEATURE: featureUrl + "/feature/category"
    },
    tagImage: {
        TAG_IMAGE: bookingUrl + "/tag/hotel/image"
    },
    hotel: {
        HOTEL: hotelUrl + "/hotel"
    },
    customerSupport: customerSupportUrl + "/admin",
    discountConfig: {
        DISCOUNT_CONFIG: discountConfigUrl + "/hotel/apply"
    },
    city: {
        CITY: cityUrl + "/city"
    },
    discountRoomType: {
        DISCOUNT_ROOMTYPE: discountRoomTypeUrl + "/roomtype"
    },
    hotelRoomForDiscount: {
        HOTEL_ROOM_FOR_DISCOUNT: hotelRoomForDiscountUrl + "/hotelRoomForDiscount"
    },
    bank: {
        BANK: bankUrl + "/bank"
    },
    resend: {
      RESEND: resendUrl + "/resend/pdf"
    },
}