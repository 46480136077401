/* eslint-disable */
import { permission } from '@/assets/strings'
import { axios } from '@/service'
import { VuexModule, Module, Mutation, Action } from 'vuex-module-decorators'
import url from '../../service/Url'

@Module({ namespaced: true })
class Booking extends VuexModule {
  public isLogin = false
  public token = ''
  public user = {}
  public promo = {}

  @Mutation
  public setIsLogin(isLogin: boolean): void {
    this.isLogin = isLogin
  }

  @Mutation
  public setToken(token: string): void {
    this.token = token
  }

  @Mutation
  public setUser(user: object): void {
    this.user = user
  }

  @Action
  public clearState() {
    this.context.commit('setIsLogin', false)
    this.context.commit('setToken', null)
    this.context.commit('setUser', null)
  }

  @Action
  public doLogin(payload: any) {
    return new Promise((resolve,reject) => {
      if ((payload.email) && (payload.password)) {
        let role = null
        let name = null
        let permissions: Array<string> = []

        axios.post(url.auth.POST_LOGIN, payload)
          .then((res) => {
            console.log("success login", res)
            const tempRole = 'superadmin'
            switch (res.data.role.toUpperCase()) {
            // switch (tempRole.toUpperCase()) {
              case 'SUPERADMIN':
                role = 'SUPER ADMIN'
                name = 'Super Admin'
                permissions = [...permission['SUPER_ADMIN_PERMISSION']]
                break;
              case 'ADMIN':
                role = 'ADMIN'
                name = 'Admin'
                permissions = [...permission['ADMIN_PERMISSION']]
                break;
              case 'USER':
                role = 'USER'
                name = 'User'
                permissions = [...permission['USER_PERMISSION']]
                break;
              default:
                throw new Error(`Unknown role for user: ${payload.username}`)
                break;
            }
            this.context.commit('setIsLogin', true)
            this.context.commit('setToken', res.data.token)
            this.context.commit('setUser', { name, role, permissions })
            resolve(res.data)
            // resolve('asdasd')
          }).catch((err) => {
            console.log("error login", err)
            reject(err)
          })
        }
    })
  }

  get isLoggedIn() {
    return this.isLogin
  }
}
export default Booking