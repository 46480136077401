import axios from "axios"
import url from './Url'

/**
 * Get Bank List
 * @param pageNumber number
 * @param pageSize number
 * @param search string
 */
export const getBankList = async (pageNumber: number, pageSize: number, search?: string) => {
  const qs = {
    pageNumber,
    pageSize,
    search
  }

  try {
    console.log("Bank Url => ", url.bank.BANK)
    const result = await axios.get(url.bank.BANK, { params: qs})
    return result.data
  } catch (error) {
    return Promise.reject(error)
  }
}

/**
 * Create Bank
 * @param request object
    * @param logo string
    * @param name string 
    * @param source string
 */
export const createBank = async (request: object) => {
  
  try {
    const result = await axios.post(url.bank.BANK, request)
    return result.data
  } catch (error) {
    return Promise.reject(error)
  }
}

/**
 * Update Bank
 * @param id: string
 * @param data: object
 */

export const updateBank = async (id: string, data: object) => {
  const bank = {
      id: id,
      data: data
    }

  try {
    const result = await axios.put(url.bank.BANK, bank)
    return result.data
  } catch (error) {
    return Promise.reject(error)
  }
}

/**
 * Delete Bank
 * @param id: string
 */

 export const deleteBank = async (id: string) => {
  try {
    const urlDelete = `${url.bank.BANK}?id=${id}`
    const result = await axios.delete(urlDelete)
    return result.data
  } catch (error) {
    return Promise.reject(error)
  }

 }