
































































































































































































































































































































































































































































































































































































































































































































/* eslint-disable */
import { Component, Emit, Prop, Vue, Watch } from "vue-property-decorator";
import AutoCompleteRoomType from "./AutoCompleteRoomType.vue";
import FormEditRoomPrice from "./FormEditRoomPrice.vue";

@Component({
  components: {
    AutoCompleteRoomType,
    FormEditRoomPrice,
    // TagHotelImageFormComponent
  },
})
export default class FormEditRoomComponent extends Vue {
  @Prop({ required: true }) readonly isEdit!: boolean;
  @Prop({ required: true }) readonly editedItem!: any;
  @Prop({ required: true }) readonly featureListRoom!: Array<any>;
  @Prop({ required: true }) mealPlanList!: Array<any>;
  @Prop({ required: true }) roomTypeList!: Array<any>;

  private hotel: any = [];
  private isDetailRoom: any = false;
  private loading: boolean = false;
  private roomPriceItem: any = [];
  private debounce: any = null;
  private debounceMealPlan: any = null;
  private roomTypeSearch: any = null;
  private mealPlanSearch: any = null;
  private editable: boolean = false;
  private page: any = 0;

  created() {
    // this.getAllRoomTypes();
    // this.getAllMealPlan();
  }

  @Watch("isEdit")
  isEditChanged(newVal: boolean) {
    console.log("editedItem", this.editedItem);
    if (newVal) {
      this.hotel = this.editedItem;
    } else {
      this.hotel = {};
    }
    console.log("hotell", this.hotel);
  }

  // @Watch("roomTypeSearch")
  // roomTypeSearchChange(newVal: string) {
  //   // console.log("new val", newVal)
  //   // this.getRoomList(newVal);

  //   if (!this.loading  && newVal) {
  //     clearTimeout(this.debounce);
  //     this.debounce = setTimeout(() => {
  //       this.getRoomList(newVal);
  //     }, 500);
  //   }
  // }

  // @Watch("mealPlanSearch")
  // mealPlanSearchChange(newVal: string) {
  //     // this.getMealList(newVal);
  //   // this.editable = true;
  //   if (!this.loading && newVal) {
  //     clearTimeout(this.debounceMealPlan);
  //     this.debounceMealPlan = setTimeout(() => {
  //       this.getMealList(newVal);
  //     }, 500);
  //   }
  // }

  @Emit("save-room")
  private saveRoom() {
    for (let i = 0; i < this.hotel.roomTypes.length; i++) {
      for (let j = 0; j < this.hotel.roomTypes[i].roomPrice.length; j++) {
        if(this.hotel.roomTypes[i].roomPrice[j].packageRate === ''){
          this.hotel.roomTypes[i].roomPrice[j].packageRate = false
        }

        if(this.hotel.roomTypes[i].roomPrice[j].availFlag === ''){
          this.hotel.roomTypes[i].roomPrice[j].availFlag = false
        }

        if(this.hotel.roomTypes[i].roomPrice[j].canAmend === ''){
          this.hotel.roomTypes[i].roomPrice[j].canAmend = false
        }

        if(this.hotel.roomTypes[i].roomPrice[j].canHold === ''){
          this.hotel.roomTypes[i].roomPrice[j].canHold = false
        }
        
        this.hotel.roomTypes[i].roomPrice[j].price = this.hotel.roomTypes[i].roomPrice[j].price.split('.').join("");

        for (let k = 0; k < this.hotel.roomTypes[i].roomPrice[j].cancelationPolicy.length; k++) {
          if(this.hotel.roomTypes[i].roomPrice[j].cancelationPolicy[k].noShow === '') {
            this.hotel.roomTypes[i].roomPrice[j].cancelationPolicy[k].noShow = false
          }

          this.hotel.roomTypes[i].roomPrice[j].cancelationPolicy[k].amount.split('.').join("");
          this.hotel.roomTypes[i].roomPrice[j].cancelationPolicy[k].b2bMarkup.split('.').join("");
          this.hotel.roomTypes[i].roomPrice[j].cancelationPolicy[k].grossAmount.split('.').join("");
        }
      }
    }

    return this.hotel;
  }

  hasPrevPageLocation () {
    if (this.page >= 1) {
      this.page -= 1;
      this.getAllRoomTypes(this.roomTypeSearch);
    }
  }

  hasNextPageLocation () {
    this.page += 1;
    this.getAllRoomTypes(this.roomTypeSearch);
  }

  private getAllRoomTypes(search: any) {
    this.loading = true;
    this.editable = false;
    this.roomTypeList = [];
    this.$api.hotel
      .getAllRoomTypes(this.page, 20, search)
      .then((res: any) => {
        this.loading = false;

        console.log("data", res.data);
        let data = res.data.map((x: any) => {
          let item = {
            value: x.id,
            text: x.name,
          };
          return item;
        });
        this.roomTypeList = data;
      })
      .catch((err: any) => {
        this.loading = false;
        console.error("error: ", err);
        this.$toast.error(err.statusMessage.toString());
      });
  }

  private getRoomList(value: any) {
    console.log("get room list", value);
    this.loading = true;
    this.editable = false;
    this.roomTypeList = [];
    this.$api.hotel
      .getAllRoomTypes(value)
      .then((res: any) => {
        this.loading = false;
        console.log("data", res.roomTypes.data);
        let data = res.roomTypes.data.map((x: any) => {
          let item = {
            value: x.id,
            text: x.name,
          };
          return item;
        });
        this.roomTypeList = data;
      })
      .catch((err: any) => {
        this.loading = false;
        console.error("error: ", err);
        this.$toast.error(err.statusMessage.toString());
      });
  }

  private getMealList(value: any) {
    console.log("get meal list", value);
    this.loading = true;
    this.editable = false;
    this.mealPlanList = [];
    this.$api.hotel
      .getAllMealPlans(value)
      .then((res: any) => {
        this.loading = false;
        console.log("data", res.mealPlan.data);
        let data = res.data.map((x: any) => {
          let item = {
            value: x.id,
            text: x.mealPlanName,
          };
          return item;
        });
        this.mealPlanList = data;
      })
      .catch((err: any) => {
        this.loading = false;
        console.error("error: ", err);
        this.$toast.error(err.statusMessage.toString());
      });
  }

  private getAllMealPlan() {
    this.loading = true;
    this.editable = false;
    this.mealPlanList = [];
    this.$api.hotel
      .getAllMealPlans()
      .then((res: any) => {
        this.loading = false;
        console.log("data", res.mealPlan.data);
        let data = res.data.map((x: any) => {
          let item = {
            value: x.id,
            text: x.mealPlanName,
          };
          return item;
        });

        this.mealPlanList = data;
      })
      .catch((err: any) => {
        this.loading = false;
        console.error("error: ", err);
        this.$toast.error(err.statusMessage.toString());
      });
  }

  private clickDetailRoom(index: number) {
    this.isDetailRoom = true;
    this.roomPriceItem = this.hotel[index].roomPrices;
  }

  private saveDetailRoom(item: number, index: any) {
    this.hotel[index].roomPrices = item;
    this.isDetailRoom = false;
  }

  private fillHotelRooms(item: any, index: any) {
    this.hotel[index] = item;
  }

  private checkRoomType() {
    console.log("check hotel room", this.hotel.roomTypes);
  }

  private addRoomTypeForm() {
    this.hotel.roomTypes.push({
      roomTypeId: "",
      typeName: "",
      feature: [],
      hotelAvabilityRoom: [
        {
          startDate: "",
          endDate: "",
          totalRoomAvailable: "",
        },
      ],
      roomPrice: [
        {
          price: "",
          mealPlanListId: "",
          packageRate: false,
          availFlag: false,
          canAmend: false,
          cancelationPolicyType: "",
          canHold: false,
          cancelationPolicy: [
            {
              fromDate: "",
              toDate: "",
              amount: "",
              b2bMarkup: "",
              grossAmount: "",
              night: "",
              percent: "",
              noShow: false,
              isDelete: false,
              deletedBy: "delete",
            },
          ],
          isDelete: false,
          deletedBy: "delete",
        },
      ],
      isDelete: false,
      deletedBy: "delete",
    });
    console.log("room type", this.hotel.roomTypes);
  }

  private deleteRoomTypeForm(index: string) {
    this.hotel.roomTypes[index]["deletedAt"] = new Date(Date.now());
    this.hotel.roomTypes[index]["deletedBy"] = "delete";
    this.hotel.roomTypes[index]["isDelete"] = true;
    console.log("room types", this.hotel.roomTypes);
    // this.hotel.roomTypes.splice(index, 1);
  }

  private addRoomForm(indexRoomType: string) {
    this.hotel.roomTypes[indexRoomType].roomPrice.push({
      price: "",
      mealPlanListId: "",
      packageRate: false,
      availFlag: false,
      canAmend: false,
      cancelationPolicyType: "",
      canHold: false,
      cancelationPolicy: [
        {
          fromDate: "",
          toDate: "",
          amount: "",
          b2bMarkup: "",
          grossAmount: "",
          night: "",
          percent: "",
          noShow: false,
          isDelete: false,
          deletedBy: "delete",
        },
      ],
    });
  }

  private deleteRoomForm(indexRoomType: string, indexRoom: string) {
    this.hotel.roomTypes[indexRoomType].roomPrice[indexRoom]["deletedAt"] =
      new Date(Date.now());
    this.hotel.roomTypes[indexRoomType].roomPrice[indexRoom]["deletedBy"] =
      "delete";
    this.hotel.roomTypes[indexRoomType].roomPrice[indexRoom]["isDelete"] = true;
    // this.hotel.roomTypes[indexRoomType].roomPrice.splice(indexRoom, 1);
  }

  private addCancelationPolicy(indexRoomType: string, indexRoomPrice: string) {
    this.hotel.roomTypes[indexRoomType].roomPrice[
      indexRoomPrice
    ].cancelationPolicy.push({
      fromDate: "",
      toDate: "",
      amount: "",
      b2bMarkup: "",
      grossAmount: "",
      night: "",
      percent: "",
      noShow: false,
      isDelete: false,
      deletedBy: "delete",
    });
  }

  private deleteCancelationPolicy(
    indexRoomType: string,
    indexRoomPrice: string,
    indexCancelation: string
  ) {
    this.hotel.roomTypes[indexRoomType].roomPrice[
      indexRoomPrice
    ].cancelationPolicy[indexCancelation]["deletedAt"] = new Date(Date.now());
    this.hotel.roomTypes[indexRoomType].roomPrice[
      indexRoomPrice
    ].cancelationPolicy[indexCancelation]["deletedBy"] = "delete";
    this.hotel.roomTypes[indexRoomType].roomPrice[
      indexRoomPrice
    ].cancelationPolicy[indexCancelation]["isDelete"] = true;
    console.log(
      "cancelation policy",
      this.hotel.roomTypes[indexRoomType].roomPrice[indexRoomPrice]
        .cancelationPolicy
    );

    console.log("hote room req", this.hotel);
    // this.hotel.roomTypes[indexRoomType].roomPrice[
    //   indexRoomPrice
    // ].cancelationPolicy.splice(indexCancelation, 1);
  }

  private addHotelRoomAvaible(indexRoomType: string) {
    this.hotel.roomTypes[indexRoomType].hotelAvabilityRoom.push({
      startDate: "",
      endDate: "",
      totalRoomAvailable: "",
    });
  }

  private deletehotelAvabilityRoom(
    indexRoomType: string,
    indexHotelAvaibleRoom: string
  ) {
    this.hotel.roomTypes[indexRoomType].hotelAvabilityRoom.splice(
      indexHotelAvaibleRoom,
      1
    );
  }

  private addCommasPrice(i: any, j: any) {
    this.hotel.roomTypes[i].roomPrice[j].price = this.hotel.roomTypes[i].roomPrice[j].price.replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ".");
  }
  private addCommasAmount(i: any, j: any, k: any) {
    this.hotel.roomTypes[i].roomPrice[j].cancelationPolicy[k].amount = this.hotel.roomTypes[i].roomPrice[j].cancelationPolicy[k].amount.replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ".");
  }
  private addCommasB2B(i: any, j: any, k: any) {
    this.hotel.roomTypes[i].roomPrice[j].cancelationPolicy[k].b2bMarkup = this.hotel.roomTypes[i].roomPrice[j].cancelationPolicy[k].b2bMarkup.replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ".");
  }
  private addCommasGross(i: any, j: any, k: any) {
    this.hotel.roomTypes[i].roomPrice[j].cancelationPolicy[k].grossAmount = this.hotel.roomTypes[i].roomPrice[j].cancelationPolicy[k].grossAmount.replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ".");
  }

  private startDateCondition() {
    let date = new Date();
    return date.toISOString().slice(0, 10);
  }
  private resetEndDate(i: any, j: any) {
    this.hotel.roomTypes[i].hotelAvabilityRoom[j].endDate = "";
  }

  private fromDateCondition() {
    let date = new Date();
    return date.toISOString().slice(0, 10);
  }
  private resetToDate(i: any, j: any, k: any) {
    this.hotel.roomTypes[i].roomPrice[j].cancelationPolicy[k].toDate = "";
  }

}
