































































































































































































































































































































































































































































/* eslint-disable */
import {
  Vue
} from "vue-property-decorator";
export default Vue.extend({
  name: "DialogComponent",
  data: function () {
    return {
      imageUrl: {
        details: null,
        home: null,
      },
      promo: {
        title: '',
        subtitle: '',
        content: '',
        detailsImageUrl: '',
        homeImageUrl: '',
        startDate: '',
        endDate: '',
        promoId: '',
        tnc: '',
      },
      isValid: false
    };
  },
  props: {
    isCreate: {
      type: Boolean,
      required: true
    },
    isCreatePwa: {
      type: Boolean,
      required: true
    },
    typeCreate: {
      type: String,
      required: true
    },
    isEdit: {
      type: Boolean,
      required: true
    },
    editPromo: {
      type: Object
    },
    permission: {
      type: Boolean
    },
    productType: {
      type: Array
    },
    promoCode: {
      type: Array
    },
    tab: {
      type: Number
    },
  },
  watch: {
    isEdit: function (newVal: any, oldVal: any) {
      if (newVal) {
        this.promo = {...this.editPromo}
        this.promo.startDate = this.promo.startDate.split('T')[0]
        this.promo.endDate = this.promo.endDate.split('T')[0]
        this.imageUrl = {
          details: null,
          home: null,
        }
      }
    },
    isCreate: function (newVal: boolean) {
      if (newVal) {
        this.imageUrl = {
          details: null,
          home: null,
        }
        this.promo = {
          title: '',
          content: '',
          subtitle: '',
          detailsImageUrl: '',
          homeImageUrl: '',
          startDate: '',
          endDate: '',
          promoId: '',
          tnc: ''
        }
        this.reset()
      }
    },
  },
  methods: {
    deleteImage: function (type: any) {
      if (type === "details") {
        this.imageUrl.details = null
        this.promo.detailsImageUrl = ""
      } else {
        this.imageUrl.home = null
        this.promo.homeImageUrl = ""
      }
    },
    fetchImage: function (type: string, image: any) {
      this.$api.image.uploadImage(image)
        .then((res: any) => {
          this.$toast.success('Success Upload Image')
          console.log('Success Upload Image: ', res)
          if (type === "details") {
            this.promo.detailsImageUrl = res.url
          } else {
            this.promo.homeImageUrl = res.url
          }
        })
        .catch((err: any) => {
          console.error('error: ', err)
          this.$toast.error(err.statusMessage.toString())
        })
    },
    addImageDetails() {
      if (this.imageUrl.details !== null && this.imageUrl.details !== undefined) {
        this.fetchImage("details", this.imageUrl.details)
      }
    },
    addImageHome() {
      if (this.imageUrl.home !== null && this.imageUrl.details !== undefined) {
        this.fetchImage("home", this.imageUrl.home)
      }
    },
    clickCancel(value: string) {
      if (value === 'create') {
        this.$emit('isCancel', {
          isCancelCreate: true
        })
      } else if (value === 'createPwa') {
        this.$emit('isCancel', {
          isCancelCreatePwa: true
        })
      } else {
        this.$emit('isCancel', {})
      }
    },
    savePromo(value: string) {
      // console.log(this.editPromo)
      if (value === 'create') {
        this.$emit('savePromo', 'create', this.promo)
      } else if (value === 'createPwa') {
        this.$emit('savePromo', 'createPwa', this.promo)
      } else {
        this.$emit('savePromo', 'edit', this.promo)
      }
    },
    requiredRule (message: string): any {
      return [(v: any) => !!v || `${message} is required`]
    },
    validate () {
      (this.$refs.form as Vue & { validate: () => boolean }).validate()
    },
    reset () {
      this.$nextTick(() => {
        (this.$refs.form as Vue & { reset: () => boolean }).reset();
      })
    },
    startDateCondition() {
      let date = new Date();
      return date.toISOString().slice(0, 10);
    },
    resetEndDate() {
      this.promo.endDate = "";
    }
  },
});
