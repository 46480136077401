

























import {
    Component,
    Vue
  } from "vue-property-decorator";
@Component({})
export default class Customer extends Vue {
    get bookingDetail() {
        return this.$store.state['modules/booking'].bookingOrder;
    }
}
