






















































/* eslint-disable */

import { Component, Emit, Prop, Vue, Watch } from "vue-property-decorator";

@Component
export default class Dialog extends Vue {
  @Prop({required: true}) readonly isAddEdit!: boolean
  @Prop({required: true}) readonly editedMealPlan!: any

  private mealPlan: any = {}

  private isValid = false;

  @Watch('isAddEdit')
  isAddtagChanged(newVal: boolean) {
    this.mealPlan = {...this.editedMealPlan}
    if (!this.editedMealPlan.id)
    {
      this.reset()
    }
  }

  @Emit('save-mealPlan')
  saveAddedMealPlan() {
    return this.mealPlan
  }

  private requiredRule (message: string): any
  {
    return [(v: any) => !!v || `${message} is required`]
  }

  private validate () {
    this.$api.mealPlan.getMealPlanList(0, 999)
      .then((res: any) => {
        if ((this.$refs.form as Vue & { validate: () => boolean }).validate()) {
          let validateMealPlanName = res.data.find((el: { mealPlanName: any; }) => el.mealPlanName === this.mealPlan.mealPlanName)
          let validateMealPlanCode = res.data.find((el: { mealPlanCode: any; }) => el.mealPlanCode === this.mealPlan.mealPlanCode)
          if (validateMealPlanName && validateMealPlanCode) {
            this.$toast.error('Meal Plan Name & Meal Plan Code Already Exist');
          } else if (validateMealPlanName) {
            this.$toast.error('Meal Plan Name Already Exist');
          } else if (validateMealPlanCode) {
            this.$toast.error('Meal Plan Code Already Exist');
          } else {
            this.saveAddedMealPlan()
          }
        }
      })
      .catch((err: any) => {
        console.error('error: ', err)
        this.$toast.error(err.statusMessage.toString())
      })
  }

  private reset () {
    this.$nextTick(() => {
      (this.$refs.form as Vue & { reset: () => boolean }).reset();
    })
  }
}

