
















































































































































/* eslint-disable */
import { Component, Emit, Prop, Vue, Watch } from "vue-property-decorator";

@Component
export default class Table extends Vue {
  @Prop({required: true, type: Array}) readonly items!: []
  @Prop({required: true}) readonly loading!: boolean
  @Prop({required: true}) readonly optionsTable!: object
  @Prop({required: true}) readonly totalCustomer!: number
  @Prop({required: true, type: Array}) readonly headers!: []
  @Prop({required: true, type: Boolean}) readonly permission!: boolean
  @Prop({required: true}) dialogProp!: boolean

  private options = {}
  private dialog = false
  private dialogItem:any = {}
  
  @Watch('options')
  optionsChanged() {
    this.fetchData()
  }

  @Watch('dialogProp')
  dialogPropChanged() {
    this.dialog = this.dialogProp
  }

  @Emit('fetchData')
  public fetchData() {
    return this.options
  }

  public selectedRow(item: object) {
    Object.assign(this.dialogItem, item)
    this.dialog = true

    this.dialogItem.createdAt = this.dialogItem.createdAt === null ? "" : this.dialogItem.createdAt.split("T")[0]
    this.updateDialog();
  }

  public closeDialog() {
    this.dialog = false
  }

  @Emit('updateCustomer')
  public saveCustomerUpdate() {
    console.log('child: ', this.dialogItem)
    // this.closeDialog()
    return this.dialogItem;
  }

  @Emit('updateDialog')
  public updateDialog() {
    return this.dialog;
  }

  public isPhoneNumber(event: any) {
    if (!isNaN(event.key) || event.key === "Delete" || event.key === "Backspace" || event.key.includes("Arrow")) {
      return true
    } else {
      event.preventDefault()
    }
  }

  public maxDateBirth() {
    let date = new Date();
    return date.toISOString().slice(0, 10);
  }
}
