














































/* eslint-disable */
import { Component, Emit, Prop, Vue, Watch } from "vue-property-decorator";

@Component
export default class Table extends Vue {
  @Prop({required: true, type: Array}) readonly data!: []
  @Prop({required: true}) readonly loading!: boolean
  @Prop({required: true}) readonly optionsTable!: object
  @Prop({required: true}) readonly totalUser!: string
  @Prop({required: true}) readonly permission!: boolean

  private options = {}
  private headers = [
    { text: "User Id", value: "id" },
    { text: "Email", value: "email" },
    { text: "Phone Number", value: "phoneNumber" },
    { text: "Role", value: "roleName"},
    { text: "Action", value: "action"}
  ]

  @Watch('options')
  optionsChanged() {
    this.fetchData()
  }

  @Emit('fetchData')
  fetchData() {
    return this.options
  }

  @Emit('actionUser')
  actionUser(item: any) {
    if (item === 'create') {
      return 'create'
    } else {
      return item
    }
  }
}
