export default {
  text: {
    email: 'Cust Email',
    name: 'Passanger Name',
    phone: 'Cust Phone',
    orderDate: 'Booking Date',
    orderId: 'Booking Id',
    statusTransaction: 'Transaction Status',
    statusInvoice: 'Invoice Status',
    startBookingDate: 'Date Booking Start',
    endBookingDate: 'Date Booking End',
    expired: 'Expired',
    origin: 'Origin',
    destination: 'Destination',
    bookingCode: 'Booking Code',
    price: 'Price',
    paymentMethod: 'Payment Method',
    paymentDate: 'Payment Date',
    flightDateStart: 'Flight Date Start',
    flightDateEnd: 'Flight Date End',
    flightNumber: 'Flight Number',
    airlines: 'Airlines',
    installmentTimes: 'Installment Times',
    installmentBank: 'Installment Bank',
    issuedBy: 'Issued By',
    rute: 'Route',
    promoCode: 'Promo Code'
  },
  statusTransaction: ["BOOKED", "COMPLETED", "CANCELLED", "EXPIRED", "TICKETED", "PAID", "REFUNDED", "NEED REVIEW", "UNPAID"],
  statusInvoice: ["PAID", "NOT PAID", "VALIDATED", "REQUESTED", "EXPIRED", "BOOKED", "PENDING", "UNPAID"],
  orderSource: ['Antavaya', 'MG Jarvis'],
  statusRefund: ["REFUNDED", "REQUESTED"],
  // paymentMethod: ["Credit Card", "Bank Transfer (MANDIRI, MEGA)", "CIMB Clicks", "Klik BCA", "Virtual Account (BCA, BNI, MANDIRI)", "Cicilan"],
  paymentMethod: ["AlloPay", "AlloPoint", "AlloPayLater", "Bank Mega", "Mega Credit", "Mega Card", "Mega VA", "MegaCC", "Mandiri VA", "BNIVA", "BRIVA", "CREDIT CARD / DEBIT CARD", "Other"],
  flightRoute: ["Domestic", "International"],
  promoType: ["Promo", "Non Promo"],
  expired: ["All", "30 Minutes", "60 Minutes"],
  issuedBy: ["All", "System", "Manual"],
  installmentBank: ["All", "Permata", "BCA", "Standard Chartered"],
  airlines: ["Garuda Indonesia", "Citilink", "Lion Air", "Wings Air", "Batik Air", "Sriwijaya Air", "Air Asia", "Nam Air", "JetStar"],
  ticketStatus: ["All", "New", "Not Paid", "Paid", "Ticketed", "Cancel", "Expired"],
  orderStatus: ["All", "Menunggu Pembayaran", "Pembayaran Selesai", "Konfirmasi Pembayaran Sedang Diproses", "Dibatalkan"],
  installmentTimes: ["All", "3", "6", "12"]
}