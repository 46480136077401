































import { Component, Vue } from 'vue-property-decorator';
import Navbar from '@/components/Commons/Navbar.vue';
import { namespace } from 'vuex-class'
const auth = namespace('modules/auth')

@Component({
  components: {
    Navbar
  },
})
export default class App extends Vue {
  
  @auth.Getter
  public isLoggedIn!: boolean

  @auth.Action
  public clearState!: () => void

  get username() {
    return this.$store.state['modules/auth'].user['name']
  }

  public handleLogout() {
    this.clearState()
    this.$router.push({ name: 'Login' })
  }
}
