






































/* eslint-disable */

import { Component, Vue } from "vue-property-decorator";
import TableComponent from "@/components/Hotel/Feature/Table.vue"; // @ is an alias to /src
import DialogComponent from "@/components/Hotel/Feature/Dialog.vue"; // @ is an alias to /src
import PermissionType from "@/constants/PermissionType.ts";
import FilterComponent from "@/components/Hotel/Feature/Filter.vue";

@Component({
  components: {
    TableComponent,
    DialogComponent,
    FilterComponent
  }
})
export default class Feature extends Vue {
  private loading: boolean = false;
  private isAddEdit: boolean = false;
  private isDelete: boolean = false;
  private editedFeature: any = {};
  private optionsTable: any = {
    page: 1,
    itemsPerPage: 5
  };
  private totalFeature: number = 0;
  private features: any = [];
  private permissions: Array<string> = [];
  private permission: boolean = true;
  private filter: any = "";
  private headers: Array<object> = [
    { text: "Name", value: "name", align: "start", sortable: false },
    { text: "Tag", value: "tag", align: "start", sortable: false },
    {
      text: "Category Feature",
      value: "categoryFeatureName",
      align: "start",
      sortable: false
    },
    { text: "Action", value: "action", sortable: false }
  ];
  private mockFeature: any = [
    {
      id: "akjsgdajhsgd",
      name: "Clean",
      tag: "gatau",
      categoryFeature: {
        id: "1",
        name: "Category Name 1"
      }
    },
    {
      id: "akjsgdajhdfgdfgdfgdfgsgd",
      tag: "gatau",
      name: "Wifi",
      categoryFeature: {
        id: "1",
        name: "Category Name 2"
      }
    },
    {
      id: "akjsgdajhasdasdasdsgd",
      tag: "gatau",
      name: "Keren",
      categoryFeature: {
        id: "1",
        name: "Category Name 3"
      }
    }
  ];

  created() {
    const permissions = this.$store.state["modules/auth"].user["permissions"];
    console.log("permissions: ", permissions);
    this.permissions = [...permissions];
    this.checkCanCreateUpdateDeleteFeature();
    console.log("view feature", this.canViewFeature)
    if (this.canViewFeature) {
      this.fetchFeatureList(this.optionsTable, this.filter);
    }
  }

  public get canViewFeature() {
    return this.permissions.includes(PermissionType.VIEW_FEATURE)
  }

  public fetchFeatureList(options: any, searchh: string) {
    this.optionsTable = options
    this.features = []
    this.loading = true
    console.log("Option => ", options);
    const { page, search, itemsPerPage } = options;

    this.$api.feature.getFeatureList(page - 1, itemsPerPage == -1 ? null : itemsPerPage, searchh)
      .then((res: any) => {
        console.log("data", res.data)
        this.totalFeature = res.totalItems
        this.features = res.data
        this.loading = false
        console.log('Success get feature list => ', res)
      })
      .catch((err: any) => {
        console.error('error: ', err)
        this.$toast.error(err.statusMessage.toString())
      })
  }

  public deleteFeature(item: any) {
    console.log("Delete Feature", item)
    setTimeout(() => {
      this.fetchDeleteFeature(item.id)
      this.isDelete = false;
    }, 500);
  }

  public detailFeature(item: any) {
    this.isAddEdit = true;
    this.editedFeature = item;
  }

  public closeAddEditDialog() {
    this.editedFeature = {};
    this.isAddEdit = false;
  }

  public fetchCreateFeature(feature: any) : void {
     this.$api.feature.createFeature(feature)
      .then((res: any) => {
        this.$toast.success(res.statusMessage)
        this.isAddEdit = false
        this.fetchFeatureList(this.optionsTable, this.filter)
        console.log('Success Create Feature: ', res)
      })
      .catch((err: any) => {
        console.error('error: ', err)
        this.$toast.error(err.statusMessage.toString())
      })
  }

  public fetchUpdateFeature(id: string, feature: any): void  {
    console.log("id", id)
      this.$api.feature.updateFeature(id, feature)
      .then((res: any) => {
        this.$toast.success(res.statusMessage)
        this.isAddEdit = false
        this.fetchFeatureList(this.optionsTable, this.filter)
        console.log('Success updated feature: ', res)
      })
      .catch((err: any) => {
        console.error('error: ', err)
        this.$toast.error(err.statusMessage.toString())
      })

  }

  public fetchDeleteFeature(id: string): void {
    console.log("id", id)
    this.$api.feature.deleteFeature(id)
      .then((res: any) => {
        this.$toast.success(res.statusMessage)
        this.isAddEdit = false
         this.fetchFeatureList(this.optionsTable, this.filter)
        console.log('Success Delete Meal Plan: ', res)
      })
      .catch((err: any) => {
        console.error('error: ', err)
        this.$toast.error(err.statusMessage.toString())
      })
  }

  



  public saveAddedFeature(item: any) {
    if (item.id) {
      console.log("update item", item);
      this.fetchUpdateFeature(item.id, item)
    } else {
      console.log("create item", item);
      this.fetchCreateFeature(item);
    }
    // setTimeout(() => {
    //   this.closeAddEditDialog();
    // }, 500);
  }

  public applyFilter(filter: object): void {
    // call api from backend to apply filter
    this.filter = filter;
    console.log("filter", this.filter);
    this.optionsTable.page = 1;
    this.fetchFeatureList(this.optionsTable, this.filter);
  }

  public checkCanCreateUpdateDeleteFeature(): void {
    this.permission = this.permissions.includes(PermissionType.CREATE_FEATURE) && 
                      this.permissions.includes(PermissionType.UPDATE_FEATURE) && 
                      this.permissions.includes(PermissionType.DELETE_FEATURE)
  }

  public getPagination(page: any) {
    this.optionsTable.page = page;
    this.fetchFeatureList(this.optionsTable, this.filter);
  }

  public getItemPerPage(item: any) {
    this.optionsTable.itemsPerPage = item;
    this.fetchFeatureList(this.optionsTable, this.filter);
  }

  public cancelAddEditDiscount() {
    this.isAddEdit = false;
    this.editedFeature = {};
  }

  // public fetchCreateFeature(feature: any): void {
  //   feature.categoryFeatureId = feature.categoryFeature.id
  //   this.$api.feature
  //     .createFeature(feature)
  //     .then((res: any) => {
  //       this.$toast.success(res.statusMessage);
  //       this.isAddEdit = false;
  //       this.editedFeature = {};
  //       this.fetchFeatureList(this.optionsTable, this.filter);
  //       console.log("Success Create Feature: ", res);
  //     })
  //     .catch((err: any) => {
  //       console.error("error: ", err);
  //       this.$toast.error(err.statusMessage.toString());
  //     });
  // }

  // public fetchUpdateFeature(feature: any): void {
  //   feature.categoryFeatureId = feature.categoryFeature.id
  //   this.$api.feature
  //     .updateFeature(feature.id, feature)
  //     .then((res: any) => {
  //       this.$toast.success(res.statusMessage);
  //       this.isAddEdit = false;
  //       this.editedFeature = {};
  //       this.fetchFeatureList(this.optionsTable, this.filter);
  //       console.log("Success updated Feature: ", res);
  //     })
  //     .catch((err: any) => {
  //       console.error("error: ", err);
  //       this.$toast.error(err.statusMessage.toString());
  //     });
  // }

  // public fetchDeleteFeature(id: string): void {
  //   console.log("id", id)
  //   this.$api.feature.deleteFeature(id)
  //     .then((res: any) => {
  //       this.$toast.success(res.statusMessage)
  //       this.isAddEdit = false
  //       this.fetchFeatureList(this.optionsTable, this.filter)
  //       console.log('Success Delete Feature: ', res)
  //     })
  //     .catch((err: any) => {
  //       console.error('error: ', err)
  //       this.$toast.error(err.statusMessage.toString())
  //     })
  // }
}
